import Select from "react-select";

export const CustomSelect = ({
  options,
  field,
  setFieldValue,
  placeholder,
  defaultValueInput,
  extraFuction,
  defaultValue,
  defaultInputValue,
  withFormik = true,
  isDisabled = false,
  className = "",
  styles,
}: any) => {
  return (
    <Select
      name={field}
      id={field}
      placeholder={`${placeholder}`}
      options={options}
      className={`flex flex-1 ${className}`}
      onChange={(selectedOption: any) => {
        console.log(
          "inside custom select onchange ==>",
          field,
          selectedOption.value
        );
        withFormik
          ? setFieldValue && setFieldValue(field, selectedOption.value)
          : setFieldValue(selectedOption.value);
        extraFuction && extraFuction(field, selectedOption.value);
      }}
      {...(defaultValue?.value && { defaultValue })}
      {...(defaultInputValue && { defaultInputValue })}
      isDisabled={isDisabled}
      styles={{
        control: (provided, state) => ({
          ...provided,
          padding: "2px",
          borderWidth: "2px",
          width: "100%",
          "&:hover": {
            borderColor: "#B9B9B9",
          },
          borderColor: state.isFocused ? "silver" : "#E9E9E9",
          boxShadow: state.isFocused ? "0 0 0 0 darkgray" : "none",
          backgroundColor: isDisabled ? "white" : "white",
          // color: "red", // Set text color to red
        }),

        valueContainer: (provided) => ({
          ...provided,
          // color: "red", // Set text color to red
        }),
        input: (provided) => ({
          ...provided,
          // color: "red", // Set text color to red
        }),
        placeholder: (provided) => ({
          ...provided,
          // color: "red", // Set text color to red
        }),
        singleValue: (provided) => ({
          ...provided,
          color: "black", // Set text color to red
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          color: isDisabled ? "transparent" : "gray", // Set text color to red
        }),
        // menu: (provided) => ({
        //   ...provided,
        //   zIndex: 9999, // Ensure the menu is displayed above other elements

        // }),
        // menuList: (provided) => ({
        //   ...provided,
        // }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? "lightslategray" : "white",
          color: "black",
          "&:hover": {
            backgroundColor: "lightgray",
          },
        }),
      }}
    />
  );
};
