import { ErrorMessage, Field } from "formik";

export const AddressComponent = ({
  user,
  addressFor,
  type,
  setFieldValue,
  handleBlur,
}: any) => {
  return (
    <div className=" mb-4 w-full space-y-3 md:col-span-2 ">
      <div>
        <label
          htmlFor="addressLine1"
          className="mb-2 block font-medium text-gray-800"
        >
          Address Line 1 *
        </label>
        <Field
          onBlur={(e: any) => {
            setFieldValue("addressLine1", e.target?.value?.trim());
            handleBlur(e);
          }}
          disabled={user?.[type]}
          as="textarea"
          name="addressLine1"
          id="addressLine1"
          rows={2} // Adjust the number of rows as needed
          placeholder={`Enter Address Line 1 `}
          className=" w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
        />
        <ErrorMessage
          name="addressLine1"
          component="div"
          className="text-red-600"
        />
      </div>
      <div>
        <label
          htmlFor="addressLine2"
          className="mb-2 block font-medium text-gray-800"
        >
          Address Line 2
        </label>
        <Field
          onBlur={(e: any) => {
            setFieldValue("addressLine2", e.target?.value?.trim());
            handleBlur(e);
          }}
          disabled={user?.[type]}
          as="textarea"
          name="addressLine2"
          id="addressLine2"
          rows={2} // Adjust the number of rows as needed
          placeholder={`Enter Address Line 2 `}
          className=" w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
        />
      </div>

      <div className="b flex space-x-4">
        <div className="flex flex-1 flex-col">
          <label
            htmlFor="city"
            className="mb-2 block font-medium text-gray-800"
          >
            City *
          </label>
          <Field
            onBlur={(e: any) => {
              setFieldValue("city", e.target?.value?.trim());
              handleBlur(e);
            }}
            disabled={user?.[type]}
            type="text"
            name="city"
            id="city"
            rows={2} // Adjust the number of rows as needed
            placeholder="Enter City Name"
            className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
          />
          <ErrorMessage name="city" component="div" className="text-red-600" />
        </div>
        <div className="flex flex-1 flex-col">
          <label
            htmlFor="state"
            className="mb-2 block font-medium text-gray-800"
          >
            State *
          </label>
          <Field
            onBlur={(e: any) => {
              setFieldValue("state", e.target?.value?.trim());
              handleBlur(e);
            }}
            disabled={user?.[type]}
            type="text"
            name="state"
            id="state"
            rows={2} // Adjust the number of rows as needed
            placeholder={`Enter State Name `}
            className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
          />
          <ErrorMessage name="state" component="div" className="text-red-600" />
        </div>
      </div>
    </div>
  );
};
