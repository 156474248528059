import { Button } from "@chakra-ui/react";
import { NoData } from "components/custom/NoData";
import {
  formatDateField,
  formatHeader,
  formatObject,
} from "helper/helperFunctions";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export const UserDetails = ({ rowData, setShowDetails }: any) => {
  const user = useSelector((store: any) => store.auth.user);
  const navigate = useNavigate();
  const location = useLocation();

  rowData = rowData || location.state;

  // Define keys to exclude
  // const keysToExclude = ["password", "ssn"]; // Example keys to exclude

  // Change to useState
  // const [formType, currentFormAction] = location.pathname.split("/").slice(-2);

  return (
    <>
      <NoData
        isVisible={Object.keys(rowData || {}).length == 0}
        label={"No Details found!"}
      />
      <div>
        {/* <h1 className="mb-4 text-2xl font-bold">User Details</h1> */}
        <div className="grid gap-4 md:grid-cols-2">
          {rowData &&
            Object.entries(rowData)
              .filter(
                ([key]) =>
                  ![
                    "_id",
                    "oldRecord",
                    "emailVerified",
                    "password",
                    "personnelInfo",
                    "mobVerified",
                    "updatedAt",
                    "organizationInfo",
                    "otherDoc",
                    "panCardImg",
                    "gstCertificateImg",
                    "aadharCardImg",
                    "idCardImg",
                  ].some((excludeKey) => excludeKey === key)
              )
              .map(([key, value]) => (
                <div key={key} className="rounded bg-gray-200 p-4">
                  <p className="font-semibold">{formatHeader(key)}</p>
                  <p>{formatObject(formatDateField(value))}</p>
                </div>
              ))}
        </div>
        <Button
          onClick={() => {
            setShowDetails ? setShowDetails(false) : navigate(-1);
          }}
          className="float-end  mt-4 min-w-32 rounded-xl bg-gray-600 py-2 text-base font-medium text-white transition duration-200 hover:bg-gray-700 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:py-2"
        >
          Back
        </Button>
      </div>
    </>
  );
};
