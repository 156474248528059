import React, {  useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { CustomSelect } from "components/custom/CustomSelect";
import { errorToast, infoToast, successToast } from "views/toastConfig";
import {
  createResourceCharges,
  getAllChargesHistory,
} from "services/appServices";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import Card from "components/card";
import { formatDateField } from "helper/helperFunctions";
import ReactPaginate from "react-paginate";
import { Role } from "views/customTypes/types";
import { useSelector } from "react-redux";

// Define initial values
const columnHelper = createColumnHelper<any>();
const columns = [
  columnHelper.accessor("buildingType", {
    id: "buildingType",
    header: () => (
      <p className="text-sm font-bold text-gray-600 dark:text-white">
        Buidingtype
      </p>
    ),
    cell: (info) => (
      <p className="text-sm font-bold text-navy-700 dark:text-white">
        {info.getValue()}
      </p>
    ),
  }),

  columnHelper.accessor("rent", {
    id: "rent",
    header: () => (
      <p className="text-sm font-bold text-gray-600 dark:text-white">Rent</p>
    ),
    cell: (info) => (
      <p className="text-sm font-bold text-navy-700 dark:text-white">
        {info.getValue()}
      </p>
    ),
  }),
  columnHelper.accessor("waterCharges", {
    id: "waterCharges",
    header: () => (
      <p className="text-sm font-bold text-gray-600 dark:text-white">
        Water Charges
      </p>
    ),
    cell: (info) => (
      <p className="text-sm font-bold text-navy-700 dark:text-white">
        {info.getValue()}
      </p>
    ),
  }),
  columnHelper.accessor("maintenance", {
    id: "Maintenance",
    header: () => (
      <p className="text-sm font-bold text-gray-600 dark:text-white">
        Maintenance
      </p>
    ),
    cell: (info) => (
      <p className="text-sm font-bold text-navy-700 dark:text-white">
        {info.getValue()}
      </p>
    ),
  }),
  columnHelper.accessor("updatedAt", {
    id: "updatedAt",
    header: () => (
      <p className="text-sm font-bold text-gray-600 dark:text-white">
        updatedAt
      </p>
    ),
    cell: (info) => (
      <p className="text-sm font-bold text-navy-700 dark:text-white">
        {formatDateField(info.getValue())}
      </p>
    ),
  }),
  columnHelper.accessor("chargesType", {
    id: "chargesType",
    header: () => (
      <p className="text-sm font-bold text-gray-600 dark:text-white">
        updatedAt
      </p>
    ),
    cell: (info) => (
      <p className="text-sm font-bold text-navy-700 dark:text-white">
        {formatDateField(info.getValue())}
      </p>
    ),
  }),
];
const validationSchema = Yup.object().shape({
  resourceType: Yup.string().required("Resource Type is required"),
  buildingType: Yup.string().when("resourceType", {
    is: (val: string) => val === "quarter",
    then: (schema) => schema.required("Building Type is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  rent: Yup.mixed().when("action", {
    is: "insert",
    then: (schema) =>
      schema
        .test(
          "is-number",
          "Rent must be a number",
          (value: any) => !isNaN(value)
        )
        .test(
          "min-value",
          "Rent must be at least 0",
          (value: any) => value >= 0
        ),
    otherwise: (schema) => schema.notRequired(),
  }),
  waterCharges: Yup.mixed().when("action", {
    is: "insert",
    then: (schema) =>
      schema
        .test(
          "is-number",
          "Water Charges must be a number",
          (value: any) => !isNaN(value)
        )
        .test(
          "min-value",
          "Water Charges must be at least 0",
          (value: any) => value >= 0
        ),
    otherwise: (schema) => schema.notRequired(),
  }),
  maintenance: Yup.mixed().when("action", {
    is: "insert",
    then: (schema) =>
      schema
        .test(
          "is-number",
          "Maintenance Charges must be a number",
          (value: any) => !isNaN(value)
        )
        .test(
          "min-value",
          "Maintenance Charges must be at least 0",
          (value: any) => value >= 0
        ),
    otherwise: (schema) => schema.notRequired(),
  }),
});

export const Charges = () => {
  const { user } = useSelector((store: any) => store.auth);
  const { userRole } = useSelector((store: any) => store.appSlice);

  const initalRender = useRef(true);
  //? as i want to use action outside of formik also that's why i have used useRef
  const action = useRef("fetch");
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [letestdata, setLatestData] = useState([]);
  const formikValuesRef = useRef(null);
  const [matchedTotalCount, setMatchedTotalCount] = useState(0);
  const [sorting, setSorting] = React.useState<SortingState>([{id:'buildingType',desc:false}]);

  const initialValues = useRef({
    resourceType: "",
    buildingType: "",
    rent: "",
    waterCharges: "",
    maintenance: "",
    chargesType: "government",
    // action: "fetch",
  });

  const table1 = useReactTable({
    data: letestdata,
    columns,
    state: {
      sorting,
    },
    
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const handleDropdownChange = (
    fieldName: string,
    value: any,
    setFieldValue: any
  ) => {
    console.log("handleDropdownChange called ==> ");
    setMatchedTotalCount(0);
    setData([]);
    //? Reset specified fields when resourceType or action changes
    if (fieldName === "resourceType" || fieldName === "action") {
      setFieldValue("rent", initialValues.current.rent);
      setFieldValue("waterCharges", initialValues.current.waterCharges);
      setFieldValue("maintenance", initialValues.current.maintenance);
    }
  };

  const getHistoryData = async (
    filter: any,
    fetchAllDoc: boolean = true,
    FetchedLatestDocOnly: boolean = false,
    sort?: any,
    limit?: number,
    currentPage?: number,
    project?: any
  ) => {
    try {
      const res = await getAllChargesHistory(
        filter,
        fetchAllDoc,
        FetchedLatestDocOnly,
        sort,
        limit,
        currentPage,
        project
      );
      const { payload, message, error, statusCode } = res?.data || {};
      if (statusCode == 200) {
        if (!payload) {
          infoToast("No Record found!");
          return;
        }
        console.log({ payload });
        payload.groupedData && setData(payload.groupedData[0].documents);
        setMatchedTotalCount(payload.matchedTotalCount);
        payload.latestDocument && setLatestData(payload.latestDocument);
      } else {
        console.log({ error });
        errorToast(message);
      }
    } catch (error: any) {
      console.log("Error getHistoryData ==> ", { error });
      errorToast(error.message || error.response?.data?.message);
    }
  };

  const handleSubmit = async (values: any, { setFieldValue }: any) => {
    try {
      console.log({ values, action: action.current });
      // return;

      if (action.current == "fetch") {
        getHistoryData(
          {
            "resourceDetails.resourceType": values.resourceType,
            "resourceDetails.buildingType": values.buildingType,
            chargesType: values.chargesType,
          },
          true,
          false
        );
      } else if (action.current == "insert") {
        const {
          rent,
          waterCharges,
          maintenance,
          chargesType,
          ...resourceDetails
        } = values;
        const res = await createResourceCharges({
          chargesType,
          rent,
          maintenance,
          waterCharges,
          resourceDetails,
        });
        const { payload, message, error, statusCode } = res?.data || {};
        if (statusCode == 201) {
          successToast("Updated SuccessFully!");
          action.current = "fetch";
          setFieldValue("action", "fetch");
          getHistoryData({}, false, true);
        } else {
          console.log({ error });
          errorToast(message);
        }
      }
    } catch (error: any) {
      console.log("Error while updating Personnel Info:", { error });
      errorToast(error.response?.data?.message || error.message);
    }
  };
  useEffect(() => {
    if (initalRender.current) {
      getHistoryData({}, false, true);
      initalRender.current = false;
    } else if (
      action.current == "fetch" &&
      formikValuesRef.current.buildingType &&
      formikValuesRef.current?.resourceType
    ) {
      console.log("first");
      getHistoryData({}, false, true, { createdAt: -1 }, 10, currentPage);
    }
  }, [currentPage]);
  return (
    <>
      {letestdata?.length > 0 && (
        <>
          <Card extra={"w-full h-full px-6 pb-6 overflow-x-auto mt-2 mb-10"}>
            <div className="mt-8 overflow-x-auto ">
              <table className="w-full">
                <thead>
                  {table1.getHeaderGroups().map((headerGroup: any) => (
                    <tr
                      key={headerGroup.id}
                      className="!border-px !border-gray-400"
                    >
                      {headerGroup.headers.map((header: any) => {
                        return (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            onClick={header.column.getToggleSortingHandler()}
                            className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                          >
                            <div className="items-center justify-between text-xs text-gray-200">
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {{
                                asc: "",
                                desc: "",
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table1
                    .getRowModel()
                    .rows.slice(0)
                    .map((row) => {
                      return (
                        <tr key={row.id}>
                          {row.getVisibleCells().map((cell) => {
                            return (
                              <td
                                key={cell.id}
                                className="min-w-[150px] border-white/0 py-3  pr-4"
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </Card>
        </>
      )}
      <Formik
        initialValues={initialValues.current}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        // enableReinitialize={true}
      >
        {({ setFieldValue, values, handleBlur, touched }) => {
          formikValuesRef.current = values;
          return (
            <Form>
              <div>
                <div className="grid gap-4 md:grid-cols-2">
                  <div className="">
                    <label
                      htmlFor="Select Action"
                      className="block font-medium text-gray-800"
                    >
                      Select Action
                    </label>
                    <CustomSelect
                      field="action"
                      placeholder="Select Action"
                      setFieldValue={setFieldValue}
                      defaultValue={{
                        value: "fetch",
                        label: "Fetch All charges",
                      }}
                      extraFuction={(field: string, value: any) => {
                        action.current = value;
                        handleDropdownChange(field, value, setFieldValue);
                      }}
                      isDisabled={false}
                      options={[
                        userRole != Role.HR_ADMIN
                          ? {
                              value: "insert",
                              label: "Insert Charges",
                            }
                          : {},
                        { value: "fetch", label: "Fetch All Charges" },
                      ]}
                    />
                    <ErrorMessage
                      name="action"
                      component="div"
                      className="text-red-600"
                    />
                  </div>

                  <div className="">
                    <label
                      htmlFor="Select Action"
                      className="block font-medium text-gray-800"
                    >
                      Select Charges Type
                    </label>
                    <CustomSelect
                      field="chargesType"
                      placeholder="Select Action"
                      setFieldValue={setFieldValue}
                      defaultValue={{
                        value: "government",
                        label: "For Government User",
                      }}
                      extraFuction={(field: string, value: any) => {
                        handleDropdownChange(field, value, setFieldValue);
                      }}
                      isDisabled={false}
                      options={[
                        userRole != Role.HR_ADMIN
                          ? {
                              label: "For Government User",
                              value: "government",
                            }
                          : {},
                        {
                          label: "For Private User",
                          value: "private",
                        },
                      ]}
                    />
                    <ErrorMessage
                      name="action"
                      component="div"
                      className="text-red-600"
                    />
                  </div>
                  <div className="">
                    <label
                      htmlFor="resourceType"
                      className="block font-medium text-gray-800"
                    >
                      Select Resource Type *
                    </label>
                    <CustomSelect
                      field="resourceType"
                      placeholder="Select Resource Type"
                      setFieldValue={setFieldValue}
                      defaultValue={"quarter"}
                      isDisabled={false}
                      extraFuction={(field: string, value: any) => {
                        handleDropdownChange(field, value, setFieldValue);
                      }}
                      options={[
                        { value: "quarter", label: "Quarter" },
                        { value: "shop", label: "Shop" },
                        {
                          value: "multipurposeHall",
                          label: "Multi-Purpose Hall",
                        },
                        {
                          value: "officerAndStaffClub",
                          label: "Officer and Staff Club",
                        },
                      ]}
                    />
                    <ErrorMessage
                      name="resourceType"
                      component="div"
                      className="text-red-600"
                    />
                  </div>
                  {values?.resourceType === "quarter" && (
                    <div>
                      <label
                        htmlFor="buildingType"
                        className=" block font-medium text-gray-800"
                      >
                        Select Building Type *
                      </label>
                      <CustomSelect
                        field={"buildingType"}
                        placeholder="Select Building Type"
                        setFieldValue={setFieldValue}
                        defaultValue={values.buildingType}
                        isDisabled={false}
                        extraFuction={(field: string, value: any) => {
                          handleDropdownChange(field, value, setFieldValue);
                        }}
                        options={[
                          { label: "A (One Bedroom)", value: "A" },
                          { label: "B (Two Small Bedroom)", value: "B" },
                          { label: "C (Two Big Bedroom)", value: "C" },
                          { label: "D (Bunglow)", value: "D" },
                        ]}
                      />
                      <ErrorMessage
                        name="buildingType"
                        component="div"
                        className="text-red-600"
                      />
                    </div>
                  )}
                </div>

                {action.current == "insert" && (
                  <div className="mt-4 grid grid-cols-3 gap-4">
                    <div className="mb-2">
                      <label
                        htmlFor="rent"
                        className="mb-2 block font-medium text-gray-800"
                      >
                        Rent
                      </label>
                      <Field
                        onBlur={(e: any) => {
                          setFieldValue(e.target.name, e.target?.value?.trim());
                          handleBlur(e);
                        }}
                        //   disabled={user?.personnelInfo}
                        type="text"
                        maxLength={10}
                        name="rent"
                        id="rent"
                        placeholder="Enter Rent"
                        className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                      />
                      {touched && (
                        <ErrorMessage
                          name="rent"
                          component="div"
                          className="text-red-600"
                        />
                      )}
                    </div>
                    <div className="mb-2">
                      <label
                        htmlFor="waterCharges"
                        className="mb-2 block font-medium text-gray-800"
                      >
                        Water Charges
                      </label>
                      <Field
                        onBlur={(e: any) => {
                          setFieldValue(e.target.name, e.target?.value?.trim());
                          handleBlur(e);
                        }}
                        //   disabled={user?.personnelInfo}
                        type="text"
                        maxLength={10}
                        name="waterCharges"
                        id="waterCharges"
                        placeholder="Enter Water Charges"
                        className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                      />
                      {touched && (
                        <ErrorMessage
                          name="waterCharges"
                          component="div"
                          className="text-red-600"
                        />
                      )}
                    </div>
                    <div className="mb-2">
                      <label
                        htmlFor="maintenance"
                        className="mb-2 block font-medium text-gray-800"
                      >
                        Maintenance Charges
                      </label>
                      <Field
                        onBlur={(e: any) => {
                          setFieldValue(e.target.name, e.target?.value?.trim());
                          handleBlur(e);
                        }}
                        //   disabled={user?.personnelInfo}
                        type="text"
                        maxLength={10}
                        name="maintenance"
                        id="maintenance"
                        placeholder="Enter Maintanance Charges"
                        className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                      />
                      {touched && (
                        <ErrorMessage
                          name="maintenance"
                          component="div"
                          className="text-red-600"
                        />
                      )}
                    </div>
                  </div>
                )}

                <button
                  type="submit"
                  className="float-end mt-4 rounded bg-blue-500 p-2 text-white"
                >
                  Submit
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>

      {data?.length > 0 && (
        <>
          <Card extra={"w-full h-full px-6 pb-6 overflow-x-auto mt-6"}>
            <div className="mt-8 overflow-x-auto ">
              <table className="w-full">
                <thead>
                  {table.getHeaderGroups().map((headerGroup: any) => (
                    <tr
                      key={headerGroup.id}
                      className="!border-px !border-gray-400"
                    >
                      {headerGroup.headers.map((header: any) => {
                        return (
                          <th
                            key={header.id}
                            colSpan={header.colSpan}
                            onClick={header.column.getToggleSortingHandler()}
                            className="cursor-pointer border-b-[1px] border-gray-200 pb-2 pr-4 pt-4 text-start"
                          >
                            <div className="items-center justify-between text-xs text-gray-200">
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {{
                                asc: "",
                                desc: "",
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table
                    .getRowModel()
                    .rows.slice(0)
                    .map((row) => {
                      return (
                        <tr key={row.id}>
                          {row.getVisibleCells().map((cell) => {
                            return (
                              <td
                                key={cell.id}
                                className="min-w-[150px] border-white/0 py-3  pr-4"
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </Card>
          {true && (
            <ReactPaginate
              className="mt-2 flex justify-center gap-2"
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={Math.ceil(matchedTotalCount / 10)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={10}
              onPageChange={({ selected }) => setCurrentPage(selected + 1)}
              containerClassName={"pagination"}
              activeClassName={"text-primaryColor-dark font-bold "}
            />
          )}
        </>
      )}
    </>
  );
};
