import { HiX } from "react-icons/hi";
import Logo from "../../assets/img/logo.png";
import routes from "routes";
import { useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Role } from "views/customTypes/types";

const Sidebar = (props: {
  open: boolean;
  // onClose: React.MouseEventHandler<HTMLSpanElement>;
  setIsChildOpen: any;
  isChildOpen: number;
  setOpen: any;
}) => {
  const user = useSelector((store: any) => store.auth.user);
  const { userRole } = useSelector((store: any) => store.appSlice);

  const { requestCountForPages } = useSelector((store: any) => store.appSlice);
  const [openChildRoutes, setOpenChildRoutes] = useState<string[]>([]);
  const [parentActiveRoute, setParentActiveRoute] = useState("");

  // console.log({ parentActiveRoute });

  let location = useLocation();
  const toggleChildRoutes = (path: string) => {
    if (openChildRoutes?.includes(path)) {
      props.setIsChildOpen((prev: number) => ++prev);
      setOpenChildRoutes(openChildRoutes.filter((p) => p !== path));
    } else {
      props.setIsChildOpen((prev: number) => --prev);
      setOpenChildRoutes([...openChildRoutes, path]);
    }
  };

  const activeRoute = (routeName: string) => {
    // console.log({ routeName, url: location.pathname });
    return location.pathname?.includes(routeName);
  };

  let filteredRoutes = routes.filter((route) => {
    if (
      userRole === "user" &&
      (!user.personnelInfo || !user.organizationInfo)
    ) {
      return (
        route.path !== "resource_allotement_request" &&
        route.path !== "all_requests"
      );
    }
    return true;
  });

  filteredRoutes = filteredRoutes.filter(
    (route) => route.sideBarDisp && !route.excludeFor?.includes(user?.role)
  );

  const { open, setOpen } = props;
  const isBorderBottom = (path: string): boolean => {
    if (
      userRole == Role.CLEARK &&
      [
        "request_for_resources",
        "renewal_requests",
        "vacant_resource_request",
        "change_resource_request",
        "clearification_pending",
        "manager_approved_requests",
        "payment_verification_approved",
        "payment_verification_rejected",
        "finance_Approved_request",
      ].includes(path)
    ) {
      return true;
    } else if (
      userRole == Role.MANAGER &&
      [
        "request_for_resources",
        "renewal_requests",
        "vacant_resource_request",
      ].includes(path)
    ) {
      return true;
    }
    else if (
      userRole == Role.FINANCE &&
      [
        "renewal_requests",
        "vacant_resource_request",
        "payment_verification_pending",
        "finance_approval_pending",
      ].includes(path)
    ) {
      return true;
    }
    return false;
  };

  return (
    <div
      className={`sm:none duration-175 linear  fixed z-50  flex h-lvh flex-col bg-gradient-to-b from-[#fff] to-[#f0fffc] pb-4  shadow-xl  transition-all dark:bg-navy-800 dark:text-white md:z-50 lg:z-50 xl:z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute right-4 top-4 block cursor-pointer xl:hidden"
        onClick={() => setOpen((prev: boolean) => false)}
      >
        <HiX />
      </span>

      <div className={`mt-2 flex items-center justify-center self-center `}>
        <img className="w-[60%] " src={Logo} alt="Logo" />
      </div>
      <div className=" mt-3 h-px bg-gray-300 dark:bg-white/30" />

      <ul className="mb-auto pt-2 overflow-auto pr-4 ">
        {filteredRoutes.map((route) => (
          <li
            key={route.path}
            className={`my - 1 rounded-lg border-r-2 ${
              isBorderBottom(route.path) ? " border-blue-300" : "border-blue-50"
            }`}
          >
            {route.children ? (
              <>
                <div
                  className={`flex items-center justify-between rounded-r-md py-2 pl-4 pr-6 text-sm font-medium text-gray-700 shadow-md shadow-[#e5fcff] hover:cursor-pointer hover:bg-gray-200 dark:text-white dark:hover:bg-navy-700 ${
                    activeRoute(route.path) &&
                    "bg-gradient-to-br from-primaryColor-dark to-primaryColor-light text-white hover:bg-primaryColor-dark"
                  } `}
                  onClick={() => {
                    toggleChildRoutes(route.path);
                    setParentActiveRoute(route.path);
                  }}
                >
                  <div className="flex">
                    {route.icon} <span className="ml-3">{route.name}</span>
                  </div>
                  <span
                    className={`text-2 ml-3 flex text-lg font-bold ${
                      openChildRoutes?.includes(route.path) &&
                      "rotate-90 transform" // Apply the rotation when child routes are open
                    }`}
                  >
                    {">"}
                  </span>
                </div>
                <ul>
                  {openChildRoutes?.includes(route.path) &&
                    route.children.map((childRoute: any) => (
                      <li key={childRoute.path}>
                        <Link
                          onClick={() => {
                            open && window.innerWidth < 1200 && setOpen(false);
                          }}
                          to={`${route.layout}/${childRoute.path}`}
                          className={`flex items-center rounded-r-md py-2 pl-10 pr-6 text-sm font-medium text-gray-700 hover:bg-gray-200 dark:text-white dark:hover:bg-navy-700 ${
                            activeRoute(childRoute.path) &&
                            "bg rounded-r-md font-bold !text-[#0672BA]"
                          } ${childRoute.path?.includes(parentActiveRoute)}`}
                        >
                          {/* {childRoute.icon} */}
                          <span className="ml-3">{childRoute?.name}</span>
                        </Link>
                      </li>
                    ))}
                </ul>
              </>
            ) : (
              <Link
                onClick={() => {
                  setParentActiveRoute("");
                  props.setIsChildOpen(false);
                  open && window.innerWidth < 1200 && setOpen(false);
                }}
                to={`${route.layout}/${route.path}`}
                className={`flex items-center rounded-r-md py-2 pl-4 pr-6 text-sm font-medium text-gray-700 shadow-md shadow-[#e5fcff] hover:bg-gray-200 dark:text-white dark:hover:bg-navy-700
                  ${
                    activeRoute(route.path) &&
                    "bg-gradient-to-br from-primaryColor-dark to-primaryColor-light text-white hover:bg-primaryColor-dark "
                  }
                `}
              >
                <div className="flex  flex-1 items-center justify-between ">
                  <div className="flex">
                    {route.icon}
                    <span className="ml-3">{route.name}</span>
                  </div>
                  <div>
                    <span
                      className={`float-end ${
                        requestCountForPages[route.path] &&
                        "animate-pulse shadow-xl "
                      } rounded-[50%] px-2 text-[#1d4e5a]`}
                    >
                      {requestCountForPages[route.path] || ""}
                    </span>
                  </div>
                </div>
              </Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
