import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Checkbox from "components/checkbox";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { adduser, updateUserAdmin } from "services/appServices";
import { useDispatch, useSelector } from "react-redux";
import { errorToast, successToast } from "views/toastConfig";
import { Role } from "views/customTypes/types";
import { CustomSelect } from "components/custom/CustomSelect";

export default function AddUser() {
  const user = useSelector((store: any) => store.auth.user);
  const navigate = useNavigate();
  const location = useLocation();
  const rowData = location.state;
  console.log({ rowData });

  //todo change to useState
  const [formType, currentFormAction] = location.pathname.split("/").slice(-2);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    mobNo: Yup.string()
      .matches(/^[0-9]{10}$/, "Invalid contact number")
      .required("Organization contact is required"),
    role: Yup.string().required("Role is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),

    reportingOfficer: Yup.string().required("Reporting Officer is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
    Cnfpassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const initialValues = {
    name: rowData?.name || "",
    mobNo: rowData?.mobNo || "",
    role: rowData?.role || "",
    reportingOfficer: rowData?.reportingOfficer || "",
    password: "" || "",
    Cnfpassword: "" || "",
    email: rowData?.email || "",
  };

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      let resp;
      if (currentFormAction == "add") {
        resp = await adduser(values);
      } else if (currentFormAction == "edit") {
        resp = await updateUserAdmin({
          filter: { _id: rowData?._id },
          data: values,
        });
      } else {
        console.log("else", { currentFormAction });
      }
      console.log({ resp });
      if (resp?.data?._id) {
        console.log("got it ======>");
        successToast(
          currentFormAction == "edit"
            ? "Data updated successfully!"
            : "User Added Successfully!"
        );
      } else {
        throw Error(resp.data.message);
      }
      navigate(-1);
    } catch (error: any) {
      console.log("Error addUser UserForm ==>", { error });
      errorToast(error.response?.data?.message || error.message);
    }
  };
  console.log({ formType });

  return (
    <div className="mt-8 ">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <div className="flex flex-grow  md:gap-3">
              <div className="w-[100%]">
                <div className="grid gap-3 md:grid-cols-3">
                  <div className="mb-2">
                    <label
                      htmlFor="name"
                      className="mb-2 block font-medium text-gray-800"
                    >
                      Full Name
                    </label>
                    <Field
                      disabled={user?.personnelInfo}
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Enter fullName"
                      className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                    />

                    <ErrorMessage
                      name="name"
                      component="div"
                      className="text-red-600"
                    />
                  </div>

                  {/* organizationContactNumber */}
                  <div className="mb-5">
                    <label
                      htmlFor="mobNo"
                      className="mb-2 block font-medium text-gray-800"
                    >
                      Phone No.
                    </label>
                    <Field
                      type="text"
                      name="mobNo"
                      id="mobNo"
                      maxlength="10"
                      placeholder="Enter Organization Conatact Number"
                      className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                    />
                    <ErrorMessage
                      name="mobNo"
                      component="div"
                      className="text-red-600"
                    />
                  </div>

                  {/* reportingOfficer */}
                  <div className="mb-5">
                    <label
                      htmlFor="type"
                      className="mb-2 block font-medium text-gray-800"
                    >
                      User Email
                    </label>
                    <Field
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter user Email"
                      className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-600"
                    />
                  </div>

                  {/* reportingOfficerContactNumber */}
                  <div className="mb-5">
                    <label
                      htmlFor="reportingOfficer"
                      className="mb-2 block font-medium text-gray-800"
                    >
                      Name of Reporting Officer
                    </label>
                    <Field
                      type="text"
                      name="reportingOfficer"
                      id="reportingOfficer"
                      placeholder="Enter Reporting Offier Mob. Number"
                      className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                    />
                    <ErrorMessage
                      name="reportingOfficer"
                      component="div"
                      className="text-red-600"
                    />
                  </div>

                  {/* Password */}
                  <div className="mb-5">
                    <label
                      htmlFor="password"
                      className="mb-2 block font-medium text-gray-800"
                    >
                      Password
                    </label>
                    <Field
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Min. 6 characters"
                      className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-600"
                    />
                  </div>

                  {/* Confirm Password */}
                  <div className="mb-5">
                    <label
                      htmlFor="Cnfpassword"
                      className="mb-2 block font-medium text-gray-800"
                    >
                      Confirm Password
                    </label>
                    <Field
                      type="password"
                      name="Cnfpassword"
                      id="Cnfpassword"
                      placeholder="Min. 6 characters"
                      className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                    />
                    <ErrorMessage
                      name="Cnfpassword"
                      component="div"
                      className="text-red-600"
                    />
                  </div>
                  {/* reportingOfficer */}
                  {["admin_users"]?.includes(formType) && (
                    <div className="mb-5">
                      <label
                        htmlFor="role"
                        className="mb-2 block font-medium text-gray-800"
                      >
                        Role
                      </label>

                      <CustomSelect
                        field="role"
                        placeholder="Select Type"
                        options={[
                          { value: Role.CLEARK, label: "Cleark" },
                          {
                            value: Role.MANAGER,
                            label: "Manager",
                          },
                          { value: Role.FINANCE, label: "Finance" },
                          { value: Role.HR_ADMIN, label: "HR Admin" },
                          rowData?.role == Role.ADMIN
                            ? {
                                value: Role.ADMIN,
                                label: "Admin",
                              }
                            : {},
                        ]}
                        setFieldValue={setFieldValue}
                        defaultValue={{
                          label: rowData?.role,
                          value: rowData?.role,
                        }}
                      />
                      <ErrorMessage
                        name="role"
                        component="div"
                        className="text-red-600"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Submit Button */}
            <div className="float-end mt-12 grid w-[70%] grid-cols-3 gap-3 md:w-[40%]">
              <button
                type="reset"
                disabled={isSubmitting}
                onClick={() => {
                  navigate(-1);
                }}
                className="linear mt-2 rounded-xl bg-red-400 py-[6%]  text-base font-medium text-white transition duration-200 hover:bg-red-600 active:bg-red-700 dark:bg-red-400 dark:text-white dark:hover:bg-red-300 dark:active:bg-red-200"
              >
                Back
              </button>
              <button
                type="reset"
                disabled={isSubmitting}
                className="linear text-black  mt-2 rounded-xl bg-gray-300 py-[6%] text-base font-medium transition duration-200 hover:bg-gray-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                Clear
              </button>

              <button
                type="submit"
                disabled={isSubmitting}
                className="linear mt-2 rounded-xl bg-primaryColor-dark py-[6%]  text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
