import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  addResource,
  updateResource,
} from "services/appServices";
import { errorToast, successToast } from "views/toastConfig";
import UploadImgContainer from "components/custom/UploadImgContainer";
import { useLocation, useNavigate } from "react-router-dom";
import { snakeToCamelCase } from "helper/helperFunctions";

const MultipurposeHallForm = () => {
  const location = useLocation();
  const rowData = location.state;
  const navigate = useNavigate();
  const pathParts = location.pathname.split("/");
  const [formAction, setFormAction] = useState(
    snakeToCamelCase(pathParts[pathParts.length - 1])
  );
  const [file, setFile] = useState<any>(null);
  const validationSchema = Yup.object().shape({
    propertyNo: Yup.string().required("Property number is required"),
    hallName: Yup.string().required("Hall name is required"),
    rent: Yup.number().required("Rent is required"),
    area: Yup.number().required("Area is required"),
    fromDate:
      formAction == "apply" && Yup.date().required("Form Date is required"),
    tillDate:
      formAction == "apply" && Yup.date().required("Till Date is required"),
    file:
      !file &&
      formAction == "apply" &&
      Yup.string().required("File  is required"),
  });

  let initialValues: any = {
    propertyNo: rowData?.propertyNo || "",
    hallName: rowData?.hallName || "",
    rent: rowData?.rent || "",
    area: rowData?.area || "",
    resourceType: "multipurposeHall",
  };

  const handleFileChange = (file: File | null) => {
    setFile(file);
  };

  useEffect(() => {
    if (formAction == "apply") {
      initialValues.file = "";
      initialValues.fromDate = "";
      initialValues.tillDate = "";
    }
  }, [formAction]);
  useEffect(() => {
    setFormAction(snakeToCamelCase(pathParts[pathParts.length - 1]));
  }, [location.pathname]);

  const handleSubmit = async (values: any, { resetForm }: any) => {
    try {
      console.log("Handle submit ===>", formAction);
      if (formAction == "add") {
        const res = await addResource(values);
        if (res.data?._id) {
          successToast("Hall Added Successfully");
          navigate(-1);
        } else {
          throw Error(res.data.message);
        }
      } else if (formAction == "edit") {
        values._id = rowData._id;
        const res = await updateResource(values);
        console.log("update resource ==>", { data: res.data });
        if (res.data?._id) {
          successToast("Hall Updated Successfully");
          navigate(-1);
        } else {
          throw Error(res.data.message);
        }
      }
    } catch (error: any) {
      errorToast(error.response?.data?.message || error.message);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <div className="grid grid-cols-2 gap-x-6 gap-y-2">
            {formAction == "apply" && (
              <>
                <div className="mb-2">
                  <label
                    htmlFor="fromDate"
                    className="mb-2 block font-medium text-gray-800"
                  >
                    From Date
                  </label>
                  <Field
                    // disabled={user?.personnelInfo}
                    type="date"
                    name="fromDate"
                    id="fromDate"
                    placeholder="Enter Your Date of Birth"
                    className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                    min={new Date().toISOString().split("T")[0]}
                  />

                  <ErrorMessage
                    name="fromDate"
                    component="div"
                    className="text-red-600"
                  />
                </div>
                <div className="mb-2">
                  <label
                    htmlFor="tillDate"
                    className="mb-2 block font-medium text-gray-800"
                  >
                    Till Date
                  </label>
                  <Field
                    // disabled={user?.personnelInfo}
                    type="date"
                    name="tillDate"
                    id="tillDate"
                    placeholder="Enter Your Date of Birth"
                    className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                    // max={new Date().toISOString().split("T")[0]} // Set the max attribute to today's date
                    min={
                      new Date(new Date().setMonth(new Date().getMonth() + 1))
                        .toISOString()
                        .split("T")[0]
                    }
                  />

                  <ErrorMessage
                    name="tillDate"
                    component="div"
                    className="text-red-600"
                  />
                </div>
              </>
            )}
            <div className="mb-5">
              <label
                htmlFor="propertyNo"
                className="mb-2 block font-medium text-gray-800"
              >
                Property Number
              </label>
              <Field
                type="text"
                name="propertyNo"
                id="propertyNo"
                placeholder="Enter Property Number"
                className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
              />
              <ErrorMessage
                name="propertyNo"
                component="div"
                className="text-red-600"
              />
            </div>

            <div className="mb-5">
              <label
                htmlFor="hallName"
                className="mb-2 block font-medium text-gray-800"
              >
                Hall Name
              </label>
              <Field
                type="text"
                name="hallName"
                id="hallName"
                placeholder="Enter Hall Name"
                className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
              />
              <ErrorMessage
                name="hallName"
                component="div"
                className="text-red-600"
              />
            </div>

            <div className="mb-5">
              <label
                htmlFor="rent"
                className="mb-2 block font-medium text-gray-800"
              >
                Rent
              </label>
              <Field
                type="number"
                name="rent"
                id="rent"
                placeholder="Enter Rent"
                className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
              />
              <ErrorMessage
                name="rent"
                component="div"
                className="text-red-600"
              />
            </div>

            <div className="mb-5">
              <label
                htmlFor="area"
                className="mb-2 block font-medium text-gray-800"
              >
                Area
              </label>
              <Field
                type="number"
                name="area"
                id="area"
                placeholder="Enter Area"
                className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
              />
              <ErrorMessage
                name="area"
                component="div"
                className="text-red-600"
              />
            </div>

            {formAction == "apply" && (
              <div className="col-span-2 mb-5">
                <UploadImgContainer
                  htmlFor="file"
                  file={values.file}
                  // setFile={handleFileChange}
                  label="Organization’s Request Letter"
                  css="mb-5"
                />
                {!file && (
                  <ErrorMessage
                    name="file"
                    component="div"
                    className="text-red-600"
                  />
                )}
              </div>
            )}
          </div>
          <div className=" mt-12  grid grid-cols-2 justify-end gap-3">
            <div></div>
            <div className="flex justify-end gap-2">
              <button
                onClick={() => navigate(-1)}
                type="reset"
                disabled={isSubmitting}
                className="linear mt-2 w-full rounded-xl bg-gray-400 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-gray-700 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                Back
              </button>
              <button
                type="reset"
                disabled={isSubmitting}
                className="linear mt-2 w-full rounded-xl bg-red-400 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-red-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                Clear
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="linear mt-2 w-full rounded-xl bg-primaryColor-dark py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                Submit
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default MultipurposeHallForm;
