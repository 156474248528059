import { formatHeader, generateDataFields } from "helper/helperFunctions";
import { memo } from "react";
const PersonnelandPrevResourceInfoSec = ({
  rowData,
  resourceType,
}: {
  rowData: any;
  resourceType: string;
}) => {
  // console.log("Rerendered===>");
  return (
    <div className="mt-4 grid-cols-4 gap-y-6 md:grid">
      {rowData?.personnelInfo && (
        <>
          <div className=" col-span-4 flex items-center justify-start border-b-2 pb-2 font-bold">
            <label>Personal Information</label>
          </div>
          {generateDataFields(
            rowData,
            [
              "name",
              "email",
              "type",
              "fatherOrHusbandName",
              "dob",
              "gender",
              "occupation",
              "reportingOfficer",
              "aadharNo",
              "panNo",
              "organizationContactNumber",
              "requestType",
              "permanentAddress",
              "organizationAddress",
            ],
            [],
            {
              container: "my-2 justify-start border-b-[1px] md:m-0 md:border-0",
              header: "w-2/5 md:w-full  ",
              value: "w-3/5 md:w-full",
            }
          )}
        </>
      )}

      {/* guarantorDetails */}

      {rowData.guarantorDetails?.email && (
        <>
          <div className=" col-span-4 flex items-center justify-start border-b-2 pb-2 font-bold">
            <label>Guarantor's Information</label>
          </div>
          {generateDataFields(rowData.guarantorDetails, [], [], {
            container: "my-2 justify-start border-b-[1px] md:m-0 md:border-0",
            header: "w-2/5 md:w-full  ",
            value: "w-3/5 md:w-full",
          })}
        </>
      )}

      <div className=" border-b-2 pb-2 font-bold md:col-span-full">
        <label className="">
          {rowData?.uid && "Requested"} {formatHeader(resourceType)} Information
        </label>
      </div>
      {generateDataFields(
        {
          ...rowData,
          resourceType: formatHeader(resourceType),
        },
        [
          "resourceType",
          "fromDate",
          "tillDate",
          "status",
          "dateOfPossession",
          "possessionEndDate",
        ],
        [],
        {
          container: "my-2  border-b-[1px] md:m-0 md:border-0 justify-between",
          header: "w-2/3 md:w-full self-end ",
          value: "w-1/3 md:w-full",
        }
      )}

      {rowData?.prevResourceDetails && (
        <>
          <div className=" border-b-2 pb-2 font-bold md:col-span-full">
            <label>
              Previous Allocated {rowData.prevResourceDetails.resourceType}{" "}
              Details
            </label>
          </div>
          {generateDataFields(
            {
              ...rowData.prevResourceDetails,
              resourceType: formatHeader(resourceType),
            },
            [],
            ["_id"],
            {
              container: "my-2  border-b-[1px] md:m-0 md:border-0",
              header: "w-3/5 md:w-full  ",
              value: "w-2/5 md:w-full",
            }
          )}
        </>
      )}
    </div>
  );
};
export const MemoizedPersonnelandPrevResourceInfoSec = memo(
  PersonnelandPrevResourceInfoSec,
  (prevProps, nextProps) => {
    return (
      JSON.stringify(prevProps.rowData) === JSON.stringify(nextProps.rowData) &&
      prevProps.resourceType === nextProps.resourceType
    );
  }
);
