import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { addResource } from "services/appServices";
import { successToast, errorToast } from "views/toastConfig";

export default function AddResourcesForm({
  setResourceFormVisible,
  currentResource,
  rowData,
}: any) {
  const propertyType: PropertyType = {
    quarter: {
      propertyNo: "required",
      quarterName: "required",
      sectorNo: "required",
      area: "required",
    },
    shop: {
      propertyNo: "required",
      shopName: "required",
      area: "required",
    },
    // area: {
    //   shopNo: "required",
    //   // sectorNo: "required",
    //   area: "required",
    // },
    openSpace: {
      propertyNo: "required",
      groundName: "required",
      area: "required",
    },
    multipurposeHall: {
      propertyNo: "required",
      hallName: "required",
      area: "required",
    },
    officerAndStaffClub: {
      propertyNo: "required",
      clubType: "required",
      clubName: "required",
      area: "required",
    },
  };

  console.log({ rowData });
  const [initialValues, setInitialValues] = useState<any>({});
  const [validationSchema, setValidationSchema] = useState<any>(Yup.object());

  const handleSubmit = async (values: any, { resetForm }: any) => {
    try {
      if (!values.resourceType) {
        values.resourceType = currentResource;
      }
      console.log({ values });
      const res = await addResource(values);
      if (res.data?._id) {
        successToast("Quarter Added Successfully");
        resetForm();
      } else {
        throw Error(res.data.message);
      }
      setResourceFormVisible(false);
    } catch (error: any) {
      console.log("Error HandleSubmit QuarterForm==>", { error });
      errorToast(error.response?.data?.message || error.message);
    }
  };

  useEffect(() => {
    // Set initial values and validation schema when component mounts
    setInitialValues(generateInitialValues(propertyType[currentResource]));
    setValidationSchema(
      generateValidationSchema(propertyType[currentResource])
    );
  }, []);

  return (
    <div className="mt-8">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            {/* Render dropdown to select resource type */}
            {/* <div className="mb-5">
              <label
                htmlFor="resourceType"
                className="block font-medium text-gray-800"
              >
                Select Type
              </label>
              <Field
                as="select"
                id="resourceType"
                name="resourceType"
                className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                onChange={(e: any) => {
                  const selectedType = e.target.value;
                  setFieldValue("resourceType", selectedType);
                  setInitialValues(
                    generateInitialValues(propertyType[selectedType])
                  );
                  setValidationSchema(
                    generateValidationSchema(propertyType[selectedType])
                  );
                }}
              >
                {Object.keys(propertyType).map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Field>
            </div> */}

            {/* Render other fields based on selected resource type */}
            <div className="grid grid-cols-2 gap-3">
              {Object.keys(initialValues).map((field) => (
                <div key={field} className="mb-5">
                  <label
                    htmlFor={field}
                    className="block font-medium text-gray-800"
                  >
                    {field}
                  </label>
                  <Field
                    type="text"
                    name={field}
                    id={field}
                    placeholder={`Enter ${field}`}
                    className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                  />
                  <ErrorMessage
                    name={field}
                    component="div"
                    className="text-red-600"
                  />
                </div>
              ))}
            </div>

            {/* Submit Button */}
            <div className="flex justify-end gap-2">
              <button
                type="submit"
                disabled={isSubmitting}
                className="w-[20%] rounded-xl bg-primaryColor-dark py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

interface ResourceType {
  [key: string]: string;
}

interface PropertyType {
  [key: string]: ResourceType;
}

const generateInitialValues = (propertyType: ResourceType) => {
  let initialValues: any = {};
  Object.keys(propertyType).forEach((field) => {
    initialValues[field] = "";
  });
  return initialValues;
};

const generateValidationSchema = (propertyType: ResourceType) => {
  let validationSchemaObject: any = {};
  Object.keys(propertyType).forEach((field) => {
    validationSchemaObject[field] = Yup.string().required(
      `${field} is required`
    );
  });

  return Yup.object().shape(validationSchemaObject);
};
