import { Routes, Route, Navigate } from "react-router-dom";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import store from "redux/store";
import React from "react";
import ForgotPasswordForm from "views/auth/ForgotPassword";

const ProtectedRoute = React.lazy(() => import("./protectedRoutes"));
const CustomRoutes: React.FC = () => {
  return (
    <Routes>
       <Route path="auth/*" element={<AuthLayout />}>
      
        <Route path="auth/ForgotPassword" element={<ForgotPasswordForm />} />
      </Route>


      <Route path="auth/*" element={<AuthLayout />} />
      <Route element={<ProtectedRoute />}>
        <Route path="admin/*" element={<AdminLayout />} />
      </Route>
      <Route path="/" element={<Navigate to="/admin" replace />} />
    </Routes>
  );
};

const App: React.FC = () => {
  let persistor = persistStore(store);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CustomRoutes />
      </PersistGate>
    </Provider>
  );
};

export default App;
