import { toast } from "react-toastify";

export const successToast = (message: string) => {
  toast.success(`${message}`, {
    position: "top-right",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    style: { borderRadius: "15px" },
  });
};

export const infoToast = (message: string) => {
  toast.info(`${message}`, {
    position: "top-right",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    style: { borderRadius: "15px" },
  });
};

export const errorToast = (message: string) => {
  toast.error(`${message}`, {
    position: "top-right",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    style: { borderRadius: "15px" },
  });
};
