import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import ChatIcon from "../../assets/jsx/Chat";
import DocIcon from "../../assets/jsx/Doc";
import DownloadIcon from "../../assets/jsx/Download";
import { conversation, findByIdAllotmentRequest } from "services/appServices";
import { errorToast, infoToast, successToast } from "views/toastConfig";
import { downloadFromS3, handleKeyPress } from "helper/helperFunctions";

function Chat({ user, allotementRequestId, setRefresh ,userRole}: any) {
  const [message, setMessage] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const [conversationData, setConversationData] = useState<any>([]);
  const chatEndRef = useRef<HTMLDivElement>(null);
  const [isChatInputFocused, setIsChatInputFocused] = useState(false);

  const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const droppedFile = event.dataTransfer.files[0];
    if (inputRef.current) {
      inputRef.current.classList.remove("bg-blue-300");
    }
    handleChat(droppedFile);
  };

  const handleFileDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = "copy";
    if (inputRef.current) {
      inputRef.current.classList.add("bg-blue-300");
    }
  };

  const handleFileDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (inputRef.current) {
      inputRef.current.classList.remove("bg-blue-300");
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const handleChat = async (file?: any) => {
    try {
      if (file && file.type !== "application/pdf") {
        infoToast("Please select a PDF file.");
        return;
      }

      const formData = new FormData();
      formData.append("message", message);
      if (file) {
        formData.append("file", file);
      }
      formData.append("date", new Date().toISOString());
      formData.append("allotementRequestId", allotementRequestId);
      formData.append("role", userRole);
      formData.append("name", user.name);
      formData.append("_id", user._id);

      const resp = await conversation(formData);
      if (resp.data._id) {
        setConversationData(resp.data.conversation);
        successToast("Message sent!");
        setMessage("");
      } else {
        throw new Error(resp.data.message);
      }
    } catch (error: any) {
      console.log("Error handleChat Chat ==>", { error });
      errorToast(error.response?.data?.message || error.message);
    }
  };

  const getConversationData = async () => {
    try {
      const conversationData = await findByIdAllotmentRequest(
        allotementRequestId,
        {
          conversation: 1,
          _id: 0,
        }
      );
      const { payload, message, error, statusCode } =
        conversationData?.data || {};

      if (statusCode == 200) {
        console.log({ payload });
        setConversationData(payload?.conversation);
      } else {
        console.log({ error });
        errorToast(message);
      }
    } catch (error) {
      console.log("Error getConversationData Chat ==>", { error });
    }
  };

  useEffect(() => {
    getConversationData();
  }, []);

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [conversationData]);

  return (
    <div
      className=" my-4 flex min-h-[15rem] flex-col justify-between rounded-lg border-l-4 border-r-2 border-t-4 border-double border-cyan-200 bg-[#badeff] bg-gradient-to-b from-[#ebfbfa] to-[#ffffff] p-2 shadow-md shadow-cyan-100 md:min-h-[20rem]"
      onDrop={handleFileDrop}
      onDragOver={handleFileDragOver}
      onDragLeave={handleFileDragLeave}
    >
      {/* Chat Container */}
      <div className="flex max-h-[15rem] flex-col space-y-2 overflow-auto md:pl-10 md:pr-20">
        {conversationData?.map((conversation: any, index: number) => (
          <div
            key={conversation._id + index}
            className={`${
              conversation._id === user._id
                ? "self-end text-right"
                : "self-start text-left"
            } max-w-[100%] md:max-w-[40%]`}
          >
            <div
              className={`shadow-md sm-max:text-sm md:mt-3  md:p-1 ${
                conversation._id === user._id
                  ? "overflow-auto rounded-bl-lg rounded-tl-lg rounded-tr-lg border-2 border-double border-gray-500 bg-gray-300 text-start shadow-gray-300 md:pr-2"
                  : "overflow-auto rounded-br-lg rounded-tl-lg rounded-tr-lg border-2 border-double border-blue-300 bg-blue-100  shadow-primaryColor-light md:pl-2"
              } ${
                conversation.messageType === "remark" &&
                "border-2 border-orange-500 bg-red-200"
              }`}
            >
              {!conversation.fileDetails ? (
                <label>{conversation.message}</label>
              ) : (
                <div className="flex items-center space-x-2 p-2">
                  <div>
                    <DocIcon />
                    <label>{conversation.fileDetails.type}</label>
                  </div>
                  <label>{conversation.fileDetails.originalname}</label>
                  <div
                    onClick={(e: any) => {
                      downloadFromS3(conversation.fileDetails.path,conversation.fileDetails?.originalname,conversation.fileDetails?.type);
                      e.stopPropagation();
                    }}
                    className="cursor-pointer"
                  >
                    <DownloadIcon />
                  </div>
                </div>
              )}
              <div ref={chatEndRef}></div>
            </div>
            <div className="flex">
              <div className="mt-1 pl-1 text-xs text-gray-500">
                {new Date(conversation.date).toLocaleString()}
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* Input Container */}
      <div className="flex items-center justify-between gap-x-[0.15rem]">
        <div
          className={`flex min-h-9  flex-1 items-center justify-between overflow-auto rounded-md border-2 border-gray-100 px-2 md:min-h-12 ${
            isChatInputFocused && "border border-primaryColor-light"
          }`}
        >
          <input
            ref={inputRef}
            value={message}
            onChange={handleInputChange}
            onKeyDown={(e) => {
              message.length !== 0 && handleKeyPress(e, handleChat);
            }}
            onFocus={(e) => {
              setIsChatInputFocused(true);
            }}
            onBlur={() => {
              setIsChatInputFocused(false);
            }}
            className=" b flex-1 flex-shrink items-center justify-between overflow-auto  rounded-md border-0 bg-[tranparent] outline-none sm-max:text-sm"
            placeholder="Write Something..."
            accept="application/pdf"
          />
          <label className="cursor-pointer items-center text-nowrap  pl-1 text-gray-600 sm-max:text-sm">
            Browse file
            <input
              type="file"
              className="hidden"
              accept=".pdf"
              onChange={(e) => handleChat(e.target.files[0])}
            />
          </label>
        </div>
        <div
          className="cursor-pointer rounded-md"
          onClick={() => {
            message
              ? handleChat()
              : infoToast("Type something to send message!");
          }}
        >
          <ChatIcon className="min-h-8 md:min-h-11" />
        </div>
      </div>
    </div>
  );
}

export default Chat;
