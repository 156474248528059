import React from "react";
import uploadLogo from "../../assets/img/uploadLogo.png";

function UploadImgContainer({
  file,
  label,
  label1 = "Click here to upload Organization’s Request Letter",
  css,
  htmlFor,
  setFieldValue,
  field = "file",
  id = "fileInput",
}: {
  label?: string;
  label1?: string;
  css?: string;
  htmlFor?: string;
  file?: any;
  field?: string;
  // setFile: any;
  setFieldValue?: any;
  id?: string;
}) {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      // setFile(event.target.files[0]);
      setFieldValue(field, event.target.files[0]);
    }
  };

  const handleClearFile = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    // setFile(null);
    setFieldValue(field, null);
  };

  return (
    <div
      className={`mb-5 w-full ${css}`}
      onClick={() => console.log("clicked ==>")}
    >
      <label
        htmlFor={htmlFor}
        className="mb-2 block text-sm text-gray-800 md:font-medium"
      >
        {label}
      </label>
      <div
        className=" border-sky-500 flex cursor-pointer items-center justify-center gap-4 rounded-md border-2 px-2 py-4"
        onClick={() => document.getElementById(id)?.click()}
      >
        <img src={uploadLogo} alt="Upload Logo" />
        <div className="flex flex-col">
          <label className="cursor-pointer">{label1}</label>
          <label className="cursor-pointer text-center">
            (file size below 2MB)
          </label>
          {file && (
            <div className="self-center">
              <label>
                Selected file :<span className="font-bold"> {file.name}</span>
              </label>
              <button
                type="button"
                className="ml-2 rounded-md bg-gray-500 px-2"
                onClick={handleClearFile}
                onMouseDown={(e) => e.stopPropagation()} // Stop propagation on mouse down event
              >
                Clear
              </button>
            </div>
          )}
        </div>
      </div>
      <input
        id={id}
        type="file"
        className="hidden"
        onChange={handleFileChange}
        value={""}
        accept="application/pdf"
      />
    </div>
  );
}

export default UploadImgContainer;
