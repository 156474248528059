import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { addResource, updateResource } from "services/appServices";
import { errorToast, infoToast, successToast } from "views/toastConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { snakeToCamelCase } from "helper/helperFunctions";
import { ResourceStatus } from "views/customTypes/types";

//* for allotend or applied _id is allotmentCollection id not resource id
const QuarterForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const rowData = location.state;
  const pathParts = location.pathname.split("/");
  const [formAction, setFormAction] = useState(
    snakeToCamelCase(pathParts[pathParts.length - 1])
  );
  const validationSchema = Yup.object().shape({
    buildingNumber: Yup.string().required("Building Number number is required"),
    buildingType: Yup.string().required("Building Type name is required"),
    sectorNumber: Yup.string().required("Sector Number is required"),
    roomNo: Yup.string().required("Room No number is required"),
    fromDate:
      formAction == "apply" && Yup.date().required("Form Date is required"),
    tillDate:
      formAction == "apply" && Yup.date().required("Till Date is required"),
  });

  let initialValues: any = {
    buildingNumber: rowData?.buildingNumber || "",
    buildingType: rowData?.buildingType || "",
    sectorNumber: rowData?.sectorNumber || "",
    roomNo: rowData?.roomNo || "",
    resourceType: "quarter",
  };

  const handleSubmit = async (values: any, { resetForm }: any) => {
    try {
      console.log("Handle submit ===>", formAction);
      const trimmedValues = Object.fromEntries(
        Object.entries(values).map(([key, value]: [string, any]) => [
          key,
          typeof value === "string" ? value.trim().toUpperCase() : value,
        ])
      );
      trimmedValues.rentalUnit =
        `${trimmedValues.buildingType}${trimmedValues.sectorNumber}/${trimmedValues.buildingNumber}/${trimmedValues.roomNo}`.toUpperCase();
      trimmedValues.resourceType = "quarter";
      if (formAction == "add") {
        const res = await addResource(trimmedValues);
        if (res.data?._id) {
          successToast("Quarter Added Successfully");
          navigate(-1);
        } else {
          throw Error(res.data.message);
        }
      } else if (formAction == "edit") {
        if (rowData.status != ResourceStatus.VACANT) {
          infoToast(`Resource is not vacant!`);
          return;
        }
        trimmedValues._id = rowData._id;
        const res = await updateResource(trimmedValues);
        console.log("update resource ==>", { data: res.data });
        if (res.data?._id) {
          successToast("Quarter Updated Successfully");
          navigate(-1);
        } else {
          console.log({ res: res.data });
          throw Error(res.data.message);
        }
      } else {
        console.log("Unkown from action ==>", formAction);
      }
    } catch (error: any) {
      console.log("Error HandleSubmit QuarterForm==>", { error });
      errorToast(error.response?.data?.message || error.message);
    }
  };
  useEffect(() => {
    if (formAction == "apply") {
      initialValues.file = "";
      initialValues.fromDate = "";
      initialValues.tillDate = "";
    }
    console.log({ rowData });
  }, [formAction]);
  useEffect(() => {
    setFormAction(snakeToCamelCase(pathParts[pathParts.length - 1]));
  }, [location.pathname]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isSubmitting, values }) => (
        <Form>
          <div className="grid grid-cols-2 gap-x-6 gap-y-2">
            <div className="mb-5">
              <label
                htmlFor="buildingNumber"
                className="mb-2 block font-medium text-gray-800"
              >
                Building Number *
              </label>
              <Field
                type="text"
                name="buildingNumber"
                id="buildingNumber"
                placeholder="Enter Property Number"
                className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
              />
              <ErrorMessage
                name="buildingNumber"
                component="div"
                className="text-red-600"
              />
            </div>

            <div className="mb-5">
              <label
                htmlFor="buildingType"
                className="mb-2 block font-medium text-gray-800"
              >
                Building Type *
              </label>
              <Field
                type="text"
                name="buildingType"
                id="buildingType"
                placeholder="Enter Quarter Name"
                className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
              />
              <ErrorMessage
                name="buildingType"
                component="div"
                className="text-red-600"
              />
            </div>

            <div className="mb-5">
              <label
                htmlFor="sectorNumber"
                className="mb-2 block font-medium text-gray-800"
              >
                Sector Number *
              </label>
              <Field
                type="text"
                name="sectorNumber"
                id="sectorNumber"
                placeholder="Enter sectorNumber"
                className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
              />
              <ErrorMessage
                name="sectorNumber"
                component="div"
                className="text-red-600"
              />
            </div>

            <div className="mb-5">
              <label
                htmlFor="roomNo"
                className="mb-2 block font-medium text-gray-800"
              >
                Room No *
              </label>
              <Field
                type="text"
                name="roomNo"
                id="roomNo"
                placeholder="Enter Sector Number"
                className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
              />
              <ErrorMessage
                name="roomNo"
                component="div"
                className="text-red-600"
              />
            </div>
            {values.buildingType &&
              values.sectorNumber &&
              values.buildingNumber &&
              values.roomNo && (
                <div className="mb-5">
                  <label
                    htmlFor="rentalUnit"
                    className="mb-2 block font-medium text-gray-800"
                  >
                    Rental Unit
                  </label>
                  {`${values.buildingType}${values.sectorNumber}/${values.buildingNumber}/${values.roomNo}`.toUpperCase()}
                </div>
              )}
          </div>
          <div className=" mt-12  grid grid-cols-2 justify-end gap-3">
            <div></div>
            <div className="flex justify-end gap-2">
              <button
                onClick={() => navigate(-1)}
                type="reset"
                disabled={isSubmitting}
                className="linear mt-2 w-full rounded-xl bg-gray-400 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-gray-700 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                Back
              </button>
              <button
                type="reset"
                disabled={isSubmitting}
                className="linear mt-2 w-full rounded-xl bg-red-400 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-red-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                Clear
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="linear mt-2 w-full rounded-xl bg-primaryColor-dark py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                Submit
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default QuarterForm;
