import React, { useState } from "react";
import DocImg from "../../..//assets/img/documents.png";

function NoRequest({ setNoRequst }: any) {
  return (
    <div className="flex h-lvh flex-1 flex-col items-center  justify-center ">
      <div>
        <img src={DocImg} className="mb-3 rounded-md " alt="" />
      </div>
      <label className="text-gray-600">No quarters have been added</label>
      <div>
        <button
          onClick={() => {
            setNoRequst(false);
          }}
          // disabled={isSubmitting}
          className="linear mt-6 rounded-md  bg-primaryColor-dark px-3 py-2  text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          Add Resources
        </button>
      </div>
    </div>
  );
}

export default NoRequest;
