import Chat from "components/custom/Chat";
import { DocWithDownloadButton } from "components/custom/DocWithDownloadButton";
import Modal from "components/custom/Modal";
import { UploadDocWithUploadButton } from "components/custom/UploadDocWithUploadButton";
import {
  downloadFilePdf,
  downloadFilePdfFromHtml,
  formatDateField,
  formatHeader,
  generateDataFields,
} from "helper/helperFunctions";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  allotmentRequestAction,
  uploadFileIntoAllotementRequest,
} from "services/appServices";
import { Role, STATUS } from "views/customTypes/types";
import { errorToast, successToast } from "views/toastConfig";
import moment from "moment";

function FoundRequest({
  user,
  userAllotmentRequest,
  setRefresh,
  setNewRequest,
  setNoRequst,
  setIsRenewal,
  setSelectedResouceData,
  index,
  allData,
  setIschangeResouceRequest,
  userRole
}: any) {
  const [isModalVisible, setModalVisible] = useState(false);
  const location = useLocation();
  const pageType = useRef(location.pathname.split("/").pop());
  const headerLabel = useRef("");
  const userAction = useRef("");
  const actionType = useRef("");
  const [reason, setReason] = useState("");
  const [paymentReferenceDetails, setPaymentReferenceDetails] = useState(
    userAllotmentRequest.paymentReferenceDetails || []
  );
  const isAlloted = useRef(
    userAllotmentRequest.allotementStatus.cleark.status == STATUS.APPROVED &&
      userAllotmentRequest.allotementStatus.manager.status == STATUS.APPROVED &&
      userAllotmentRequest.allotementStatus.finance?.status ==
        STATUS.APPROVED &&
      userAllotmentRequest.allotementStatus.payment.status == STATUS.APPROVED
  );

  const uploadFile = async (file: any, fieldName: string) => {
    try {
      const formData = new FormData();
      formData.append("file", file, `${fieldName}_${user._id}_${file.name}`);
      formData.append("date", new Date().toISOString());
      formData.append("allotementRequestId", userAllotmentRequest._id);
      formData.append("role", userRole);
      formData.append("name", user.name);
      formData.append("_id", user._id);
      formData.append("fieldName", fieldName);
      formData.append("fileName", file.name);

      console.log({ formData, file });

      const resUploadFile = await uploadFileIntoAllotementRequest(formData);
      if (resUploadFile?.data?.paymentReferenceDetails) {
        userAllotmentRequest["paymentReferenceDetails"] = resUploadFile.data;
        setPaymentReferenceDetails(resUploadFile.data.paymentReferenceDetails);
        console.log({ resUploadFile });
      } else {
        throw new Error(resUploadFile?.data?.message);
      }
    } catch (error: any) {
      console.log({ error });
      errorToast(
        error.response?.data?.message ||
          error.message ||
          "Something went wrong!"
      );
    }
  };

  const handleConfirm = async (action: string) => {
    console.log(`Confirmed action: ${headerLabel.current}, Reason: ${reason}`);
    setModalVisible(false);
    try {
      if (
        actionType.current == "cancelRequest" ||
        actionType.current == "vacantRequest" ||
        actionType.current == "cancelVacantRequest"
      ) {
        const allotedReqData: any = {
          pageType: pageType.current,
          action,
          actionType: actionType.current,
          _id: userAllotmentRequest._id, //allotment req _id
          resource_id: userAllotmentRequest.resourceId,
          resourceType: userAllotmentRequest.resourceType,
          approvalName: `${user.name}`,
          role: userRole,
          approvalId: user._id,
          reason: reason,
          requestType: userAllotmentRequest.requestType,
        };

        console.log({ allotedReqData });
        const res = await allotmentRequestAction(allotedReqData);
        const { payload, message, error, statusCode } = res?.data || {};

        if (statusCode == 200) {
          // userAllotmentRequest["allotementStatus"] = res.data.allotementStatus;
          setRefresh((prev: boolean) => !prev);
          successToast(message);
        } else {
          console.log({ error });
          errorToast(message);
        }
      }
    } catch (error: any) {
      errorToast(
        error.response?.data?.message ||
          error.message ||
          "Something wnet wrong!"
      );
    }
    setReason("");
  };

  // Handle button click to show modal
  const handleButtonClick = (action: string, type: string, Label?: string) => {
    // successToast(` ${type}`);
    if (type == "renewalRequest") {
      setNewRequest(true);
      setNoRequst(false);
      setIsRenewal(true);
      setSelectedResouceData(userAllotmentRequest);
      return;
    } else if (type == "resourcechangeResouceRequest") {
      setNewRequest(true);
      setNoRequst(false);
      setIschangeResouceRequest(true);
      setSelectedResouceData(userAllotmentRequest);
    } else if (
      type == "cancelRequest" ||
      type == "vacantRequest" ||
      type == "cancelVacantRequest"
    ) {
      console.log("====>", { action, Label });
      headerLabel.current = Label;
      userAction.current = action;
      actionType.current = type;
      setModalVisible(true);
    }
  };
  const [excludedField, setExcludedField] = useState([
    "createdAt",
    "_id",
    "uid",
    "allotementStatus",
    "conversation",
    "updatedAt",
    "resourceId",
    "status",
    "rent",
    "oldRecord",
    "documents",
    "requestLetter",
    "paymentReferenceDetails",
    "personnelInfo",
    "organizationInfo",
    "emailVerified",
    "mobVerified",
    "allotmentLetter",
    "finalPaymentReceipt",
    "bankReceipt",
    "otherDoc",
    "remark",
    "guarantorDetails",
    "isActive",
    "fields",
    "resourceDetails",
    "prevResourceDetails",
    "vacationReport",
    "isCustomCharge",
    "accountStatus"
  ]);
  useEffect(() => {
    if (
      userAllotmentRequest.allotementStatus.cleark.status == STATUS.APPROVED &&
      userAllotmentRequest.allotementStatus.manager.status == STATUS.APPROVED &&
      userAllotmentRequest.allotementStatus.finance?.status ==
        STATUS.APPROVED &&
      userAllotmentRequest.allotementStatus.payment.status == STATUS.APPROVED
    ) {
      setExcludedField((prev: any) => [...prev, "fromDate", "tillDate"]);
    }
  }, []);

  return (
    <div className="relative mt-4 overflow-visible rounded-md bg-[#f6f6f6] px-4 py-3">
      {/* //? Download button */}
      <div className="absolute -top-5 right-0 z-30 md:top-0 ">
        <button
          className="rounded-sm rounded-tr-lg bg-blue-500  px-1 text-base font-medium text-white shadow-lg hover:bg-blue-700 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:py-1.5"
          onClick={() =>
            downloadFilePdf(
              [
                {
                  ...user,
                  ...userAllotmentRequest,
                  clearkStatus:
                    userAllotmentRequest.allotementStatus[Role.CLEARK]?.status,
                  managerStatus:
                    userAllotmentRequest.allotementStatus[Role.MANAGER]?.status,
                  paymentStatus:
                    userAllotmentRequest.allotementStatus.payment?.status ||
                    "pending",
                  Status:
                    userAllotmentRequest.allotementStatus[Role.CLEARK]
                      ?.status == STATUS.APPROVED &&
                    userAllotmentRequest.allotementStatus[Role.MANAGER]
                      ?.status == STATUS.APPROVED &&
                    userAllotmentRequest.allotementStatus.payment?.status ==
                      STATUS.APPROVED
                      ? "Alloted"
                      : userAllotmentRequest.allotementStatus[Role.CLEARK]
                          ?.status == STATUS.REJECTED ||
                        userAllotmentRequest.allotementStatus[Role.MANAGER]
                          ?.status == STATUS.REJECTED ||
                        userAllotmentRequest.allotementStatus.payment?.status ==
                          STATUS.REJECTED
                      ? "Rejected"
                      : "Pending",
                },
              ],
              "AllotmentData",
              [
                "updatedAt",
                "documents",
                "conversation",
                "paymentReferenceDetails",
                "requestLetter",
                "allotementStatus",
                "oldRecord",
                "password",
                "personnelInfo",
                "organizationInfo",
                "emailVerified",
                "mobVerified",
                "allotmentLetter",
                "otherDoc",
                "idCardImg",
                "panCardImg",
                "gstCertificateImg",
                "aadharCardImg",
                "emailVerified",
                "mobVerified",
                "finalPaymentReceipt",
                "bankReceipt",
                "otherDoc",
                "guarantorDetails",
                "isActive",
                "prevResourceDetails",
              ],
              {
                _id: "ID",
                uid: "User ID",
                requestLetter: "Request Letter",
                fromDate: "From Date",
                tillDate: "Till Date",
                allotementStatus: "Allotment Status",
                oldRecord: "Old Record",
                createdAt: "Request Date",
                rent: "Rent",
                buildingType: "Building Type",
                clearkStatus: "Clerk Status",
                managerStatus: "Manager Status",
                paymentStatus: "Payment Status",
                Status: "Final Status",
                mobNo: "Mobile Number",
                dob: "Date of birth",
                aadharNo: "Aadhar Number",
                panNo: "Pan Number",
                staffNo: "Staff Number",
              }
            )
          }
        >
          Download
        </button>
      </div>

      <div className="">
        <div className="grid  gap-3 md:grid-cols-4">
          {generateDataFields(
            { ...userAllotmentRequest, ...userAllotmentRequest.fields },
            [],
            excludedField,
            {
              container: "my-1 justify-start border-b-[1px] md:m-0 md:border-0",
              header: "w-2/5 md:w-full  ",
              value: "w-3/5 md:w-full",
            },
            ["dateOfPossession", "possessionEndDate"]
          )}
        </div>

        {/* //?status sec */}
        <div className="mb-1 mt-8 font-bold">
          <label>Status</label>
        </div>
        <div className=" relative flex-wrap gap-3 sm:flex">
          {/* //?Cleak */}
          <div className="item-center flex flex-col">
            <label
              className={`mb-2 rounded-md px-10 py-1 ${
                [STATUS.PENDING, STATUS.REJECTED]?.includes(
                  userAllotmentRequest.allotementStatus[Role.CLEARK]?.status
                )
                  ? "bg-[#ffb5b0]"
                  : userAllotmentRequest.allotementStatus[Role.CLEARK]
                      ?.status === STATUS.VERIFICATION_PENDING
                  ? "bg-[#e4c247]"
                  : "bg-[#58e55d]"
              }`}
            >
              Cleark :{" "}
              <label
                className={`ml-0.5 font-bold ${
                  [STATUS.PENDING, STATUS.REJECTED]?.includes(
                    userAllotmentRequest.allotementStatus[Role.CLEARK]?.status
                  )
                    ? "text-[#8b2828]"
                    : userAllotmentRequest.allotementStatus[Role.CLEARK]
                        ?.status === STATUS.VERIFICATION_PENDING
                    ? "text-[#65561d]"
                    : "text-[#1e5320]"
                }`}
              >
                {formatHeader(
                  userAllotmentRequest.allotementStatus[Role.CLEARK]?.status
                )}
              </label>
            </label>
            {userAllotmentRequest.allotementStatus[Role.CLEARK]?.status !=
              STATUS.PENDING && (
              <>
                <label className="text-gray-700">
                  Name:{" "}
                  {userAllotmentRequest.allotementStatus[Role.CLEARK].name}
                </label>
                <label className="text-gray-700">
                  Date:{" "}
                  {formatDateField(
                    userAllotmentRequest.allotementStatus[Role.CLEARK].date
                  )}
                </label>
                {userAllotmentRequest.allotementStatus[Role.CLEARK]?.reason && (
                  <label className="font-bold text-gray-700  ">
                    <div className="flex max-w-[16rem] flex-col">
                      <label className="block"> Remark:</label>
                      <label className="ml-1  overflow-auto text-red-600">
                        {
                          userAllotmentRequest.allotementStatus[Role.CLEARK]
                            ?.reason
                        }
                      </label>
                    </div>
                  </label>
                )}
              </>
            )}
          </div>

          {/*  //?Manager */}
          {userAllotmentRequest.allotementStatus[Role.CLEARK]?.status ==
            STATUS.APPROVED && (
            <div className=" flex flex-col ">
              <label
                className={` mb-2 rounded-md px-10 py-1  ${
                  [STATUS.PENDING, STATUS.REJECTED]?.includes(
                    userAllotmentRequest.allotementStatus[Role.MANAGER]?.status
                  )
                    ? "bg-[#ffb5b0]"
                    : "bg-[#58e55d]"
                }`}
              >
                Manager :{" "}
                <label
                  className={`ml-0.5 font-bold ${
                    [STATUS.PENDING, STATUS.REJECTED]?.includes(
                      userAllotmentRequest.allotementStatus[Role.MANAGER]
                        ?.status
                    )
                      ? "text-[#8b2828]"
                      : "text-[#1e5320]"
                  }`}
                >
                  {formatHeader(
                    userAllotmentRequest.allotementStatus[Role.MANAGER]?.status
                  )}
                </label>
              </label>
              {userAllotmentRequest.allotementStatus[Role.MANAGER]?.status !=
                STATUS.PENDING && (
                <>
                  <label className="text-gray-700">
                    Name:{" "}
                    {userAllotmentRequest.allotementStatus[Role.MANAGER].name}
                  </label>
                  <label className="text-gray-700">
                    Date:
                    {formatDateField(
                      userAllotmentRequest.allotementStatus[Role.MANAGER].date
                    )}
                  </label>
                  {userAllotmentRequest.allotementStatus[Role.MANAGER]
                    ?.reason && (
                    <label className="font-bold text-gray-700">
                      <div className="flex max-w-[16rem] flex-col">
                        <label className="block"> Remark:</label>

                        <label className="ml-1  overflow-auto text-red-600">
                          {
                            userAllotmentRequest.allotementStatus[Role.MANAGER]
                              ?.reason
                          }
                        </label>
                      </div>
                    </label>
                  )}
                </>
              )}
            </div>
          )}

          {/*  //?Payment */}
          {userAllotmentRequest.allotementStatus[Role.MANAGER]?.status ==
            STATUS.APPROVED && (
            <div className=" flex flex-col ">
              <label
                className={` mb-2 rounded-md px-10 py-1  ${
                  [
                    STATUS.PENDING,
                    STATUS.REJECTED,
                    STATUS.VERIFICATION_PENDING,
                  ]?.includes(
                    userAllotmentRequest.allotementStatus.payment?.status
                  )
                    ? "bg-[#ffb5b0]"
                    : "bg-[#58e55d]"
                }`}
              >
                Payment :{" "}
                <label
                  className={`ml-0.5 font-bold ${
                    [
                      STATUS.PENDING,
                      STATUS.REJECTED,
                      STATUS.VERIFICATION_PENDING,
                    ]?.includes(
                      userAllotmentRequest.allotementStatus.payment?.status
                    )
                      ? "text-[#8b2828]"
                      : "text-[#1e5320]"
                  }`}
                >
                  {formatHeader(
                    userAllotmentRequest.allotementStatus.payment?.status
                  )}
                </label>
              </label>
              {userAllotmentRequest.allotementStatus.payment?.status !=
                STATUS.PENDING && (
                <>
                  <label className="text-gray-700">
                    Name:{" "}
                    {
                      userAllotmentRequest.allotementStatus.payment?.reviewBy
                        ?.name
                    }
                  </label>
                  <label className="text-gray-700">
                    Date:
                    {formatDateField(
                      userAllotmentRequest.allotementStatus.payment?.reviewBy
                        ?.date
                    )}
                  </label>
                  {userAllotmentRequest.allotementStatus.payment?.reviewBy
                    ?.reason && (
                    <label className="font-bold text-gray-700">
                      <div className="flex max-w-[16rem] flex-col">
                        <label className="block"> Remark:</label>

                        <label className="ml-1  overflow-auto text-red-600">
                          {
                            userAllotmentRequest.allotementStatus.payment
                              ?.reviewBy?.reason
                          }
                        </label>
                      </div>
                    </label>
                  )}
                </>
              )}
            </div>
          )}
          {/* //?Alloted */}
          {userAllotmentRequest.allotementStatus[Role.MANAGER]?.status ===
            STATUS.APPROVED && (
            <>
              {userAllotmentRequest.allotementStatus.payment?.status ===
              STATUS.APPROVED ? (
                <div className="flex flex-col">
                  <label className="mb-2 rounded-md bg-[#58e55d] px-10 py-1">
                    Allotted
                  </label>
                </div>
              ) : userAllotmentRequest.allotementStatus.payment?.status ===
                STATUS.REJECTED ? ( // Replace 'true' with your actual condition
                <div className="flex flex-col">
                  <label className="mb-2 rounded-md bg-[#ffb5b0] px-10 py-1">
                    Allotment : Rejected
                  </label>
                </div>
              ) : (
                userAllotmentRequest.allotementStatus.payment?.status !=
                  STATUS.APPROVED && (
                  <div className="flex flex-col">
                    <label className="mb-2 rounded-md bg-[#ffb5b0] px-10 py-1">
                      Allotment : <span className="font-medium">Pending</span>
                    </label>
                  </div>
                )
              )}
            </>
          )}

          {/* //?caneceled */}
          {userAllotmentRequest.allotementStatus.cancelRequest?.status && (
            <div className="flex flex-col">
              <label className="mb-2 rounded-md bg-[#ff5757] px-10 py-1 font-bold text-red-900">
                {formatDateField(
                  userAllotmentRequest.allotementStatus.cancelRequest.status ==
                    STATUS.APPROVED && "CANCELED"
                )}
              </label>
              <>
                <label className="text-gray-700">
                  Date:
                  {formatDateField(
                    userAllotmentRequest.allotementStatus.cancelRequest?.date
                  )}
                </label>
                {userAllotmentRequest.allotementStatus.cancelRequest
                  ?.reason && (
                  <label className="font-bold text-gray-700">
                    <div className="flex max-w-[16rem] flex-col">
                      <label className="block"> Remark:</label>

                      <label className="ml-1  overflow-auto text-red-600">
                        {
                          userAllotmentRequest.allotementStatus.cancelRequest
                            ?.reason
                        }
                      </label>
                    </div>
                  </label>
                )}
              </>
            </div>
          )}
          {/* //?vacant request */}
          {userAllotmentRequest.allotementStatus.vacantRequest?.status && (
            <div className="item-center flex flex-col">
              <label
                className={`mb-2 rounded-md px-10 py-1 ${
                  [STATUS.PENDING]?.includes(
                    userAllotmentRequest.allotementStatus.vacantRequest?.status
                  )
                    ? "bg-[#eb776f]"
                    : userAllotmentRequest.allotementStatus.vacantRequest
                        ?.status === STATUS.VERIFICATION_PENDING
                    ? "bg-[#e4c247]"
                    : "bg-[#58e55d]"
                }`}
              >
                <label
                  className={`ml-0.5 font-bold ${
                    [STATUS.PENDING]?.includes(
                      userAllotmentRequest.allotementStatus.vacantRequest
                        ?.status
                    )
                      ? "text-[#8b2828]"
                      : userAllotmentRequest.allotementStatus.vacantRequest
                          ?.status === STATUS.VERIFICATION_PENDING
                      ? "text-[#65561d]"
                      : "text-[#1e5320]"
                  }`}
                >
                  <label className="font-medium"> Vacant :</label>{" "}
                  {formatHeader(
                    userAllotmentRequest.allotementStatus.vacantRequest?.status
                  )}
                </label>
              </label>

              <>
                {/* <label className="text-gray-700">
                    Name:{" "}
                    {userAllotmentRequest.allotementStatus[Role.CLEARK].name}
                  </label> */}
                <label className="text-gray-700">
                  Date:{" "}
                  {formatDateField(
                    userAllotmentRequest.allotementStatus.vacantRequest.date
                  )}
                </label>
                {userAllotmentRequest.allotementStatus.vacantRequest
                  ?.reason && (
                  <label className="font-bold text-gray-700  ">
                    <div className="flex max-w-[16rem] flex-col">
                      <label className="block"> Remark:</label>
                      <label className="ml-1  overflow-auto text-red-600">
                        {
                          userAllotmentRequest.allotementStatus.vacantRequest
                            ?.reason
                        }
                      </label>
                    </div>
                  </label>
                )}
              </>
            </div>
          )}
        </div>

        {/* //*Below status container ==> */}
        <div className="mt-2 flex-wrap  gap-4 md:flex">
          {/* //@Proforma letter  */}
          <DocWithDownloadButton
            path={userAllotmentRequest?.requestLetter?.[0]?.path}
            label="Proforma document"
            name={"Document"}
            // type={userAllotmentRequest?.requestLetter?.[0]?.type}
            generate={true}
            generateFunction={() => {
              downloadFilePdfFromHtml(
                {
                  ...user,
                  ...userAllotmentRequest,
                  docType: "proforma",
                },
                "Proforma.pdf",
            
              );
            }}
          />

          {/* //@organization Request letter  */}
          {userAllotmentRequest?.requestLetter &&
            userAllotmentRequest?.requestLetter?.length > 0 && (
              <DocWithDownloadButton
                path={userAllotmentRequest?.requestLetter?.[0]?.path}
                label="Organization's Request Letter"
                name={userAllotmentRequest?.requestLetter?.[0]?.fileName}
                type={userAllotmentRequest?.requestLetter?.[0]?.type}
              />
            )}
          {userAllotmentRequest?.otherDoc &&
            userAllotmentRequest?.otherDoc?.length > 0 && (
              <DocWithDownloadButton
                path={userAllotmentRequest?.otherDoc?.[0]?.path}
                label="Other Documents"
                name={userAllotmentRequest?.otherDoc?.[0]?.fileName}
                type={userAllotmentRequest?.otherDoc?.[0]?.type}
              />
            )}

          {/* //@ Allotement Letter */}
          {userAllotmentRequest?.allotmentLetter?.length > 0 && (
            <DocWithDownloadButton
              path={userAllotmentRequest.allotmentLetter?.[0]?.path}
              label="Allotment Letter"
              name={userAllotmentRequest.allotmentLetter?.[0]?.fileName}
              type={userAllotmentRequest.allotmentLetter?.[0]?.type}
            />
          )}

          {/* //@Doc upload button ==> */}
          {userAllotmentRequest.allotementStatus[Role.MANAGER]?.status ===
            STATUS.APPROVED &&
            (paymentReferenceDetails.length == 0 ? (
              <UploadDocWithUploadButton
                uploadFileFunction={uploadFile}
                inputId="paymentnDoc1"
                label="Payment Reference Details"
                field="paymentReferenceDetails"
              />
            ) : (
              //@Payment Doc
              <DocWithDownloadButton
                path={paymentReferenceDetails?.[0]?.path}
                label="Payment Reference Details"
                name={paymentReferenceDetails?.[0]?.fileName}
                type={paymentReferenceDetails?.[0]?.type}
              />
            ))}
        </div>
      </div>

      {(!(
        userAllotmentRequest.allotementStatus.cleark.status ==
          STATUS.APPROVED &&
        userAllotmentRequest.allotementStatus.manager.status ==
          STATUS.APPROVED &&
        userAllotmentRequest.allotementStatus.finance?.status ==
          STATUS.APPROVED &&
        userAllotmentRequest.allotementStatus.payment.status == STATUS.APPROVED
      ) ||
        userAllotmentRequest.allotementStatus?.vacantRequest?.status ==
          STATUS.PENDING) &&
        pageType.current != "all_requests" && (
          <Chat
            user={user}
            allotementRequestId={userAllotmentRequest._id}
            setRefresh={setRefresh}
            userRole={userRole}
          />
        )}

      {/* //? Button section */}
      {pageType.current != "all_requests" && (
        <div
          className={`flex flex-col md:float-end ${
            isAlloted.current ? "md:w-1/2" : "md:w-2/12"
          } md:flex-row md:space-x-2`}
        >
          {/* //todo dont show when endDate Passed? */}
          {userAllotmentRequest.isActive &&
            moment(userAllotmentRequest.possessionEndDate).diff(
              moment(),
              "days"
            ) <= 90 &&
            isAlloted.current &&
            userAllotmentRequest.allotementStatus?.vacantRequest?.status !=
              STATUS.PENDING &&
            (allData?.length > 1 ? index == 1 : true) && (
              <button
                type="button"
                onClick={() => handleButtonClick("Renewal", "renewalRequest")}
                className="text-black mt-4 flex flex-1 items-center justify-center whitespace-nowrap rounded-md bg-green-400 px-4 py-1 text-base font-medium drop-shadow-lg  hover:bg-green-600 active:bg-green-800 sm:py-2"
              >
                Renewal
              </button>
            )}
          {userAllotmentRequest.isActive &&
            isAlloted.current &&
            (allData?.length > 1 ? index == 1 : true) &&
            ![STATUS.PENDING, STATUS.APPROVED].includes(
              userAllotmentRequest?.allotementStatus?.vacantRequest?.status
            ) && (
              <button
                type="button"
                onClick={() =>
                  handleButtonClick(
                    STATUS.PENDING,
                    "vacantRequest",
                    "Request to Vacate Resource"
                  )
                }
                className="text-black mt-4 flex flex-1 items-center justify-center whitespace-nowrap rounded-md bg-blue-400 px-4 py-1 text-base font-medium drop-shadow-lg  hover:bg-blue-600 active:bg-blue-800 sm:py-2"
              >
                Vacant
              </button>
            )}
          {userAllotmentRequest.isActive &&
            !isAlloted.current &&
            userAllotmentRequest.allotementStatus?.canceled?.status !=
              STATUS.APPROVED && (
              <button
                type="button"
                onClick={() =>
                  handleButtonClick(
                    STATUS.APPROVED, //?No approval needed to cancel the request
                    "cancelRequest",
                    "Cancel Request"
                  )
                }
                className="text-black mt-4 flex flex-1 items-center justify-center whitespace-nowrap rounded-md bg-red-400 px-4 py-1 text-base font-medium drop-shadow-lg  hover:bg-red-600 active:bg-red-800 sm:py-2"
              >
                Cancel Request
              </button>
            )}

          {userAllotmentRequest.isActive &&
            [
              STATUS.PENDING,
              STATUS.VERIFICATION_PENDING,
              STATUS.VERIFIED,
            ].includes(
              userAllotmentRequest.allotementStatus?.vacantRequest?.status
            ) && (
              <button
                type="button"
                onClick={() =>
                  handleButtonClick(
                    STATUS.APPROVED, //?No approval needed to cancel the request
                    "cancelVacantRequest",
                    "Cancel Vacant Request"
                  )
                }
                className=" text-black mt-4 flex flex-1 items-center justify-center whitespace-nowrap rounded-md bg-red-400 px-4 py-1 text-base font-medium drop-shadow-lg  hover:bg-red-600 active:bg-red-800 sm:py-2"
              >
                Cancel Vacant Request
              </button>
            )}

          {userAllotmentRequest.isActive &&
            isAlloted.current &&
            userAllotmentRequest.allotementStatus?.vacantRequest?.status !=
              STATUS.PENDING &&
            (allData?.length > 1 ? index == 1 : true) && (
              <button
                type="button"
                onClick={() =>
                  handleButtonClick(
                    STATUS.PENDING,
                    "resourcechangeResouceRequest",
                    `Change ${formatHeader(
                      userAllotmentRequest.resourceType
                    )} Request`
                  )
                }
                className="text-black mt-4 flex flex-1 items-center justify-center whitespace-nowrap rounded-md bg-orange-400 px-4 py-1 text-base font-medium drop-shadow-lg  hover:bg-orange-600 active:bg-orange-800 sm:py-2"
              >
                Change {formatHeader(userAllotmentRequest.resourceType)} Request
              </button>
            )}
        </div>
      )}

      <Modal
        visible={isModalVisible}
        onClose={() => setModalVisible(false)}
        onConfirm={handleConfirm}
        headerLabel={headerLabel.current}
        reason={reason}
        setReason={setReason}
        action={userAction.current}
      />
    </div>
  );
}

export default FoundRequest;
