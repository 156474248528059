import { useEffect, useState } from "react";
import Select from "react-select/creatable";

export const CustomCreatableSelect = ({
  options,
  field,
  setFieldValue,
  placeholder,
  defaultValue,
  defaultInputValue,
  isDisabled = false,
  withFormik = true,
  extraFuction,
  resetTrigger,
  styles,
}: any) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue || null);
  useEffect(() => {
    if (resetTrigger !== undefined) {
      setSelectedValue(null);
    }
  }, [resetTrigger]);
  return (
    <Select
      name={field}
      id={field}
      placeholder={`${placeholder}`}
      {...(resetTrigger && { value: selectedValue })}
      options={options}
      {...(defaultValue?.value && { defaultValue })}
      {...(defaultInputValue && { defaultInputValue })}
      onChange={(selectedOption: any) => {
        withFormik
          ? setFieldValue(field, selectedOption.value)
          : setFieldValue(selectedOption.value);
        extraFuction && extraFuction(field, selectedOption.value);
      }}
      isDisabled={isDisabled}
      styles={{
        control: (provided, state) => ({
          ...provided,
          padding: "2px", // Add your desired padding here
          //   borderColor: "#E9E9E9",
          borderWidth: "2px",
          "&:hover": {
            borderColor: "#B9B9B9",
          },
          "&:active": {
            borderColor: "darkgray", // Set active border color to desired color
          },
          borderColor: state.isFocused ? "silver" : "#E9E9E9",
          boxShadow: state.isFocused ? "0 0 0 0 darkgray" : "none",
          backgroundColor: isDisabled ? "white" : "white",
          // color: "red", // Set text color to red
        }),

        valueContainer: (provided) => ({
          ...provided,
          // color: "red", // Set text color to red
        }),
        input: (provided) => ({
          ...provided,
          // color: "red", // Set text color to red
        }),
        placeholder: (provided) => ({
          ...provided,
          // color: "red", // Set text color to red
        }),
        singleValue: (provided) => ({
          ...provided,
          color: "black", // Set text color to red
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          color: isDisabled ? "transparent" : "gray", // Set text color to red
        }),
        // menu: (provided) => ({
        //   ...provided,
        //   zIndex: 9999, // Ensure the menu is displayed above other elements

        // }),
        // menuList: (provided) => ({
        //   ...provided,
        // }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? "lightslategray" : "white",
          color: "black",
          "&:hover": {
            backgroundColor: "lightgray",
          },
        }),
      }}
    />
  );
};
