import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/authReducer"; // Ensure the path is correct
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import appReducer from "./reducers/appReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  appSlice: appReducer,
});

// Configuration for redux-persist
const rootReducerConfig = {
  key: "authAndSideBar",
  storage,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(rootReducerConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializable check for non-serializable data
    }),
});

export default store;
