import { ChakraProvider, Spinner as Spin } from "@chakra-ui/react";
import { useSelector } from "react-redux";

function Spinner() {
  return (
    <ChakraProvider>
      <Spin
        thickness="4px"
        speed="0.999s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </ChakraProvider>
  );
}

export default Spinner;
