import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { updateUser, updateUserWithDoc } from "services/appServices";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "redux/reducers/authReducer";
import { errorToast, successToast } from "views/toastConfig";
import { isMobOrLandlineNo, isOnlyText } from "helper/customRejex";
import { AddressComponent } from "components/custom/AddressComponent";

export default function OrganizationInfo() {
  const user = useSelector((store: any) => store.auth.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    organizationName: Yup.string()
      .trim()
      .matches(isOnlyText, "Invalid Organization Name")
      .required("Organization Name is required"),
    organizationContactNumber: Yup.string()
      .trim()
      .matches(isMobOrLandlineNo, "Invalid Organization Contact number")
      .required("Mobile number is required"),
    reportingOfficer: Yup.string()
      .trim()
      .matches(isOnlyText, "Invalid Reporting Officer Name")
      .required("Reporting Officer is required"),
    reportingOfficerContactNumber: Yup.string()
      .trim()
      .matches(isMobOrLandlineNo, "Invalid reporting officer contact number")
      .required("Mobile number is required"),
    addressLine1: Yup.string().trim().required("Address is required"),
    city: Yup.string()
      .trim()
      .matches(isOnlyText, "Invalid City value")
      .required("City is required"),
    state: Yup.string()
      .trim()
      .matches(isOnlyText, "Invalid State Value")
      .required("State is required"),
  });

  const initialValues = {
    organizationName: user.organizationName,
    organizationContactNumber: user.organizationContactNumber,
    reportingOfficer: user.reportingOfficer,
    reportingOfficerContactNumber: user.reportingOfficerContactNumber,
    addressLine1: user.organizationAddress?.addressLine1 || "",
    addressLine2: user.organizationAddress?.addressLine2 || "",

    city: user.organizationAddress?.city || "",
    state: user.organizationAddress?.state || "",
  };

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      // const trimmedValues = Object.fromEntries(
      //   Object.entries(values).map(([key, value]: any) => [key, value?.trim()])
      // );
      values._id = user._id;
      values.action = "organizationInfo";
      const res = await updateUserWithDoc(values);
      const { payload, message, error, statusCode } = res?.data || {};
      console.log({ payload, statusCode });
      if (statusCode == 200) {
        successToast("Organization Info Updated Succefully");
        user.personnelInfo && navigate("/admin/resource_allotement_request");
        dispatch(setUser(payload));
      } else {
        console.log("Error while updating Organization Info =>", { error });
        errorToast(message);
      }
    } catch (error: any) {
      console.log("Error while updating Organization Info ==>", { error });
      errorToast(error.response?.data?.message || error.message);
    }
    // Handle form submission here
    setSubmitting(false);
  };

  return (
    <div className="mt-8 ">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue, handleBlur }) => (
          <Form>
            <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
              {/* organizationName */}
              <div className=" mb-5">
                <label
                  htmlFor="organizationName"
                  className="mb-2 block font-medium text-gray-800"
                >
                  Organization Name *
                </label>
                <Field
                  onBlur={(e: any) => {
                    setFieldValue(e.target.name, e.target?.value?.trim());
                    handleBlur(e);
                  }}
                  disabled={user?.organizationInfo}
                  type="text"
                  name="organizationName"
                  id="organizationName"
                  placeholder="Enter Organization Name"
                  className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                />
                <ErrorMessage
                  name="organizationName"
                  component="div"
                  className="text-red-600"
                />
              </div>
              {/* organizationContactNumber */}
              <div className="mb-5">
                <label
                  htmlFor="organizationContactNumber"
                  className="mb-2 block font-medium text-gray-800"
                >
                  Organization Contact No. *
                </label>
                <div className="relative">
                  <Field
                    onBlur={(e: any) => {
                      setFieldValue(e.target.name, e.target?.value?.trim());
                      handleBlur(e);
                    }}
                    disabled={user?.organizationInfo}
                    maxlength="10"
                    type="text"
                    name="organizationContactNumber"
                    id="organizationContactNumber"
                    placeholder="Enter Organization Conatact Number"
                    className=" w-full rounded-lg border-2 border-gray-200 p-2 pl-2 hover:border-gray-500 focus:border-gray-500"
                  />
                </div>
                <ErrorMessage
                  name="organizationContactNumber"
                  component="div"
                  className="text-red-600"
                />
              </div>
              {/* reportingOfficer */}
              <div className="mb-5">
                <label
                  htmlFor="reportingOfficer"
                  className="mb-2 block font-medium text-gray-800"
                >
                  Reporting Officer *
                </label>
                <Field
                  onBlur={(e: any) => {
                    setFieldValue(e.target.name, e.target?.value?.trim());
                    handleBlur(e);
                  }}
                  disabled={user?.organizationInfo}
                  type="text"
                  name="reportingOfficer"
                  id="reportingOfficer"
                  placeholder="Enter Reporting Officer Name"
                  className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                />
                <ErrorMessage
                  name="reportingOfficer"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="mb-5">
                <label
                  htmlFor="reportingOfficerContactNumber"
                  className="mb-2 block font-medium text-gray-800"
                >
                  Reporting Officer Mob. No. *
                </label>
                <div className="relative">
                  {/* <span className="absolute inset-y-0 left-0 flex items-center pl-2 ">
                        +91
                      </span> */}
                  <Field
                    onBlur={(e: any) => {
                      setFieldValue(e.target.name, e.target?.value?.trim());
                      handleBlur(e);
                    }}
                    disabled={user?.organizationInfo}
                    type="text"
                    maxlength={10}
                    name="reportingOfficerContactNumber"
                    id="reportingOfficerContactNumber"
                    placeholder="Enter Reporting Offier Mob. Number"
                    className="w-full rounded-lg border-2 border-gray-200 p-2 pl-2 hover:border-gray-500 focus:border-gray-500"
                  />
                </div>
                <ErrorMessage
                  name="reportingOfficerContactNumber"
                  component="div"
                  className="text-red-600"
                />
              </div>
            </div>
            {/* organizationAddress */}
            <AddressComponent
              user={user}
              type="organizationInfo"
              addressFor={"Organization"}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
            />

            {/* Submit Button */}

            {!user?.organizationInfo && (
              <div className="float-end grid w-[40%] grid-cols-2 gap-3">
                <button
                  type="reset"
                  disabled={isSubmitting}
                  className="linear text-black  mt-2 rounded-md bg-gray-300 py-[4%] text-base font-medium transition duration-200 hover:bg-gray-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                >
                  Clear
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="linear mt-2 rounded-md bg-primaryColor-dark py-[4%]  text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                >
                  Submit
                </button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}
