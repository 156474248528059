import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { findUserAllotmentRequest } from "services/appServices";
import { errorToast } from "views/toastConfig";
import FoundRequest from "./FoundRequest";
import { NoData } from "components/custom/NoData";

export const AllUserRequests = () => {
  const user = useSelector((store: any) => store.auth.user);
  const { userRole } = useSelector((store: any) => store.appSlice);
  const [userAllotmentRequest, setUserAllotmentRequest] = useState<any>([]);

  const findUserRequest = async (_id: any) => {
    try {
      const res = await findUserAllotmentRequest(
        { uid: user?._id },
        {
          createdAt: -1,
          updatedAt: 1,
        }
      );
      if (res.data?.length > 0) {
        setUserAllotmentRequest(res.data);
      }
    } catch (error: any) {
      console.log("Error findUserRequest AllotmentRequestForm ==>", {
        error,
      });
      errorToast(error.response?.data?.message || error.message);
    }
  };
  useEffect(() => {
    findUserRequest(user?._id);
  }, []);
  return (
    <>
      {userAllotmentRequest?.map((e: any, index: number) => (
        <FoundRequest
          index={index}
          key={e.id} // Assuming each element has a unique identifier like 'id'
          user={user} // Assuming 'user' is defined elsewhere in your component
          userAllotmentRequest={e} // The entire array passed
          //   setRefresh={setRefresh} // Function to update state for refresh
          //   setNewRequest={setNewRequest} // Function to update state for new requests
          //   setNoRequst={setNoRequst} // Function to update state for no requests
          //   setRenewal={setRenewal} // Function to update state for renewal
          //   setRenewalData={setRenewalData}
          //   allData={userAllotmentRequest}
          // Other props specific to each 'e' object in userAllotmentRequest
          userRole={userRole}
        />
      ))}
      <NoData
        isVisible={userAllotmentRequest.length == 0}
        label={"No Data found!"}
      />
    </>
  );
};
