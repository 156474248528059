import axios from "axios";
import store from "../redux/store";
import { setToken } from "redux/reducers/authReducer";
import { setLoader } from "redux/reducers/appReducer";

const customAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // baseURL: "http://192.168.253.15:5500",
  // timeout: 120000,
});

customAxios.interceptors.request.use(
  function (request) {
    store.dispatch(setLoader(true));
    request.headers.Authorization = `Bearer ${store.getState().auth.token}`;
    return request;
  },
  (error) => {
    console.log("Error while Requesting ==>");
    store.dispatch(setLoader(false));
    return Promise.reject(error);
  }
);

customAxios.interceptors.response.use(
  function (response) {
    store.dispatch(setLoader(false));
  return response;
  },
  (error) => {
    console.log("Error in Response ==>");
    store.dispatch(setLoader(false));
    const statusCode = error.response?.status || error.code;
    const errorMessage =
      error.response?.data?.message ||
      error.message ||
      "Something Went Wrong!!";

    switch (statusCode) {
      case 401:
        console.log("Error code:401 ==>");
        break;

      case 403:
        console.log("Error code:403 ==>");
        // errorToast("Session Expired !");
        store.dispatch(setToken(null));
        console.log(errorMessage);
        return Promise.reject(error);

      case 404:
        console.log("Error code:404 ==>");
        return Promise.reject(error);

      case 500:
        console.log("Error code:500 ==>");
        return Promise.reject(error);

      case "ERR_NETWORK":
        console.log("Error code:ERR_NETWORK ==>");
        return Promise.reject(error);

      case "ECONNABORTED":
        console.log("Error code:ECONNABORTED ==>");
        return Promise.reject(error);

      default:
        console.log(
          "Error Default ===>",
          errorMessage,
          { statusCode },
          { error }
        );
        return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default customAxios;
