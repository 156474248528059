import Switch from "components/custom/Switch";
import { ErrorMessage, Field } from "formik";
import { SetStateAction, useMemo } from "react";
import { Role } from "views/customTypes/types";

export const ChargesSec = ({
  settingdata,
  rowData,
  setFieldValue,
  isStandardCharges,
  setIsStandardCharges,
  handleBlur,
  pageType,
  role,
}: {
  settingdata: any;
  rowData: any;
  setFieldValue: (name: string, value: any) => void;
  isStandardCharges: boolean;
  setIsStandardCharges: (data: SetStateAction<boolean>) => void;
  handleBlur: any;
  pageType: string;
  role: string;
}) => {
  return (
    <div className="mt-2 grid grid-cols-2 gap-3  pt-2 md:grid-cols-4">
      {[
        "request_for_resources",
        "change_resource_request",
        "renewal_requests",
        "clearification_pending",
      ].includes(pageType) && role == Role.CLEARK ? (
        <>
          <div className="col-span-full mb-2 flex items-center gap-2 border-b-2 pb-2 font-bold ">
            {isStandardCharges ? "Standard Charges" : "Custom Charges"}
            <>
              <Switch
                checked={isStandardCharges}
                onChange={setIsStandardCharges}
              />
            </>
          </div>
          {isStandardCharges ? (
            <>
              {settingdata?.rent ? (
                <>
                  <div className="px-2">
                    <label className="block">Rent</label>
                    <label>Rs {settingdata?.rent}</label>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div className="px-2">
                {settingdata?.waterCharges ? (
                  <>
                    <label className="block">Water Charges</label>
                    <label>Rs {settingdata?.waterCharges}</label>
                  </>
                ) : (
                  <></>
                )}
              </div>
              {settingdata?.maintenance ? (
                <>
                  <div className="px-2">
                    <label className="block">Maintenance</label>
                    <label>Rs {settingdata?.maintenance}</label>
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              <div className="mb-2">
                <label
                  htmlFor="rent"
                  className="mb-2 block font-medium text-gray-800"
                >
                  Enter Rent
                </label>
                <Field
                  onBlur={(e: any) => {
                    setFieldValue(e.target.name, e.target?.value?.trim());
                    handleBlur(e);
                  }}
                  type="text"
                  maxLength={10}
                  name="rent"
                  id="rent"
                  placeholder="Enter Alternate Mob no."
                  className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                />
                <ErrorMessage
                  name="rent"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="waterCharges"
                  className="mb-2 block font-medium text-gray-800"
                >
                  Enter Water Charges
                </label>
                <Field
                  onBlur={(e: any) => {
                    setFieldValue(e.target.name, e.target?.value?.trim());
                    handleBlur(e);
                  }}
                  type="text"
                  maxLength={10}
                  name="waterCharges"
                  id="waterCharges"
                  placeholder="Enter Alternate Mob no."
                  className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                />
                <ErrorMessage
                  name="waterCharges"
                  component="div"
                  className="text-red-600"
                />
              </div>
              <div className="mb-2">
                <label
                  htmlFor="maintenance"
                  className="mb-2 block font-medium text-gray-800"
                >
                  Enter Maintenance
                </label>
                <Field
                  onBlur={(e: any) => {
                    setFieldValue(e.target.name, e.target?.value?.trim());
                    handleBlur(e);
                  }}
                  type="text"
                  maxLength={10}
                  name="maintenance"
                  id="maintenance"
                  placeholder="Enter Alternate Mob no."
                  className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                />
                <ErrorMessage
                  name="maintenance"
                  component="div"
                  className="text-red-600"
                />
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div className="col-span-full mb-2 border-b-2 pb-2 ">
            <span className=" font-bold"> Charges</span>
          </div>
          {rowData?.rent ? (
            <>
              <div className="px-2">
                <label className="block">Rent</label>
                <label>Rs {rowData?.rent}</label>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="px-2">
            {rowData?.waterCharges ? (
              <>
                <label className="block">Water Charges</label>
                <label>Rs {rowData?.waterCharges}</label>
              </>
            ) : (
              <></>
            )}
          </div>
          {rowData?.maintenance ? (
            <>
              <div className="px-2">
                <label className="block">Maintenance</label>
                <label>Rs {rowData?.maintenance}</label>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};
