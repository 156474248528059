import React from "react";
import { useSelector } from "react-redux";

export const NoData = ({
  isVisible,
  label,
}: {
  isVisible: boolean;
  label: string;
}) => {
  const { loader } = useSelector((store: any) => store.appSlice);


  return (
    isVisible &&
    !loader && (
      <div className="flex h-[80lvh]  items-center justify-center  rounded-lg bg-gradient-to-bl from-[#fffdfd] to-[#eefdfe] text-2xl font-bold">
        <label>{label}</label>
      </div>
    )
  );
};
