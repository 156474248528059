import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={30}
    height={30}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_612_204)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6599 28.2353H5.48345V1.76469H19.6011V8.82352H26.6599V28.2353ZM21.3658 2.12998L26.2946 7.05884H21.3658V2.13V2.12998ZM27.6499 5.91884L22.5058 0.774688C22.0046 0.275313 21.3393 0 20.6335 0H3.71875V30H28.4246V7.79117C28.4246 7.0853 28.1493 6.42 27.6499 5.91883L27.6499 5.91884ZM9.01286 19.4118H21.3658V17.6471H9.01286V19.4118ZM9.01286 12.3529H23.1305V10.5882H9.01286V12.3529ZM9.01286 22.9412H16.0717V21.1765H9.01286V22.9412ZM9.01286 15.8824H19.6011V14.1177H9.01286V15.8824ZM9.01286 8.82353H16.0717V7.05883H9.01286V8.82353Z"
        fill="#434343"
      />
    </g>
    <defs>
      <clipPath id="clip0_612_204">
        <rect width={30} height={30} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SVGComponent;
