import { Textarea } from "@chakra-ui/react";
import { ErrorMessage, Field } from "formik";
import { Role } from "views/customTypes/types";

export const RemarksSec = ({
  rowData,
  pageType,
  userRole,
}: {
  rowData: any;
  pageType: string;
  userRole: string;
}) => {
  return (
    <>
      {![
        "approved_allotment_requests",
        "rejected_requests",
        "all_resources",
        "alloted_resources",
        "canceled_requests",
      ]?.includes(pageType) &&
        !(
          [
            "manager_approved_requests",
            "clearification_pending",
            "payment_verification_pending",
            "finance_approval_pending",
          ].includes(pageType) && userRole == Role.MANAGER
        ) &&
        !(
          [
            "manager_approval_pending",
            "payment_verification_pending",
            "finance_approval_pending",
          ].includes(pageType) && userRole == Role.CLEARK
        ) &&
        !(
          [
            "payment_verification_approved",
            "payment_verification_rejected",
            "finance_Approved_request",
          ].includes(pageType) && userRole == Role.FINANCE
        ) &&
        userRole !== Role.HR_ADMIN && (
          <div className=" col-span-4 mb-4 mt-4 flex w-full flex-col">
            <label className="block">Add Remark</label>
            <Field
              as={Textarea}
              name="reason"
              placeholder="Enter reason here"
              className="rounded-md border-2 p-1 focus:border-primaryColor-light focus:outline-none active:border-blue-50 "
            />
            <ErrorMessage
              name="reason"
              component="div"
              className="text-red-600"
            />
          </div>
        )}
      <div className="mt-3 flex space-x-3 text-lg font-semibold">
        {rowData?.allotementStatus?.cleark?.reason && (
          // (userRole != Role.CLEARK || pageType == "rejected_requests") &&
          <div className="flex flex-col">
            <label>Cleark's Remark : </label>{" "}
            <label className="text-red-400">
              {rowData?.allotementStatus?.cleark?.reason}
            </label>
          </div>
        )}
        {rowData?.allotementStatus?.manager?.reason && (
          // (userRole != Role.MANAGER || pageType == "rejected_requests") &&
          <div className="flex flex-col">
            <label>Manager's Remark: </label>
            <label className="text-red-400">
              {rowData?.allotementStatus?.manager?.reason}
            </label>
          </div>
        )}
        {rowData?.allotementStatus?.finance?.reason &&
          (userRole != Role.FINANCE || pageType == "rejected_requests") && (
            <div className="flex flex-col">
              <label>Finance's Remark: </label>

              <label className="text-red-400">
                {rowData?.allotementStatus?.finance?.reason}
              </label>
            </div>
          )}
        {rowData?.allotementStatus?.payment?.reviewBy?.reason && (
          <div className="flex flex-col">
            <label>Payment's Remark: </label>
            <label className="text-red-400">
              {rowData?.allotementStatus?.payment?.reviewBy?.reason}
            </label>
          </div>
        )}
        {rowData?.allotementStatus?.cancelRequest?.reason &&
          pageType == "canceled_requests" && (
            <div className="flex flex-col">
              <label> Remark: </label>
              <label className="text-red-400">
                {rowData?.allotementStatus?.cancelRequest?.reason}
              </label>
            </div>
          )}
      </div>
    </>
  );
};
