import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import routes from "routes";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "components/custom/Spinner";
import { Role } from "../../views/customTypes/types";
import { getCountForAllAdminPages } from "helper/helperFunctions";
import { DeleteModal } from "components/custom/DeleteModal";

export default function Admin(props: { [x: string]: any }) {
  const { user } = useSelector((store: any) => store.auth);
  const { loader,userRole } = useSelector((store: any) => store.appSlice);
  

  const { ...rest } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(
    window.innerWidth < 1200 ? false : true
  );
  const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");
  const [isChildOpen, setIsChildOpen] = useState<number>(0);
  const pathParts = location.pathname.split("/");

  const secondLastRoute = pathParts[pathParts.length - 2];
  const currentResource = pathParts[pathParts.length - 1];
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const formatHeader = (key: any) => {
    //? Split the key by capital letters or underscore
    const words = key.split(/(?=[A-Z])|_/);
    //? Capitalize the first letter of each word
    const formattedKey = words
      .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return formattedKey;
  };

  useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
    getCountForAllAdminPages(userRole, dispatch);
  }, []);
  useEffect(() => {
    // console.log({ isChildOpen });
  }, [isChildOpen]);

  //? filter route if user not filled personnel and organization route
  let filteredRt = routes.filter((route) => {
    if (
      userRole === "user" &&
      (!user.personnelInfo || !user.organizationInfo)
    ) {
      return route.path !== "resource_allotement_request"; // Include the return statement here
    }
    return true; // Include this return statement for other cases to keep the route in the array
  });

  React.useEffect(() => {
    getActiveRoute(filteredRt);
  }, [location.pathname]);

  const getActiveRoute = (filteredRt: RoutesType[]): string | boolean => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < filteredRt.length; i++) {
      if (
        window.location.href.indexOf(
          filteredRt[i].layout + "/" + filteredRt[i].path
        ) !== -1
      ) {
        setCurrentRoute(filteredRt[i].name);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (filteredRt: RoutesType[]): string | boolean => {
    let activeNavbar = false;
    for (let i = 0; i < filteredRt.length; i++) {
      if (
        window.location.href.indexOf(
          filteredRt[i].layout + filteredRt[i].path
        ) !== -1
      ) {
        return filteredRt[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (filteredRt: RoutesType[]): any => {
    return filteredRt
      .map((prop, key) => {
        if (prop.layout === "/admin") {
          if (prop.children) {
            return prop.children.map((e: any, childKey: any) => (
              <Route
                path={`/${e.path}`}
                element={e.component}
                key={`${key}-${childKey}-${e.path}`}
              />
            ));
          } else {
            return (
              <Route
                path={`/${prop.path}`}
                element={prop.component}
                key={`${key}-${prop.path}`}
              />
            );
          }
        }
        return null;
      })
      .filter((route) => route !== null); // Filter out null values
  };

  document.documentElement.dir = "ltr";
  return (
    <div className="flex h-full w-full bg-primaryColor-light">
      <Sidebar
        open={open}
        // onClose={() => setOpen(false)}
        setIsChildOpen={setIsChildOpen}
        isChildOpen={isChildOpen}
        setOpen={setOpen}
      />
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-lightPrimary   dark:!bg-navy-900">
        {/* Main Content */}
        <main
          className={` h-full flex-none transition-all md:px-2 ${
            userRole == Role.ADMIN
              ? isChildOpen
                ? `xl:ml-[18.2rem]`
                : `xl:ml-[18.2rem]`
              : userRole == Role.MANAGER
              ? isChildOpen
                ? `xl:ml-[20.7rem]`
                : `xl:ml-[20.7rem]`
              : userRole == Role.CLEARK || userRole == Role.HR_ADMIN
              ? isChildOpen
                ? `xl:ml-[21rem]`
                : `xl:ml-[21rem]`
              : userRole == Role.FINANCE
              ? isChildOpen
                ? `xl:ml-[19.8rem]`
                : `xl:ml-[19.8rem]`
              : userRole == Role.USER &&
                (!user.personnelInfo || !user.organizationInfo)
              ? `xl:ml-[16.7rem]`
              : userRole == Role.USER
              ? `xl:ml-[18.1rem]`
              : `xl:ml-[18.1rem]`
          }`}
        >
          {/* Routes */}
          <div
            className=" h-full overflow-auto"
            onClick={() => {
              open && window.innerWidth < 1200 && setOpen(false);
            }}
          >
            {showDeleteModal && <DeleteModal  setShowDeleteModal={setShowDeleteModal} />}
            {loader && (
              <div className=" fixed z-50 flex h-full  w-[100%] items-center justify-center  bg-[rgba(167,245,255,0.2)] xl:pr-[17em]">
                <Spinner />
              </div>
            )}
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              brandText={formatHeader(currentResource)}
              secondary={getActiveNavbar(filteredRt)}
              {...rest}
              role={userRole}
              setOpen={setOpen}
              setShowDeleteModal={setShowDeleteModal}
            />

            <div className="mb-auto mt-10 box-border flex  min-h-[85lvh] flex-col overflow-auto bg-[#ffffff] p-6 sm:mt-[4.2rem] md:px-6 md:py-6 ">
              <Routes>
                {getRoutes(filteredRt)}

                <Route
                  path="/"
                  element={
                    <Navigate
                      to={`${
                        userRole == Role.USER
                          ? "personnel_info"
                          : userRole == Role.CLEARK
                          ? "dashboard"
                          : userRole == Role.MANAGER
                          ? "dashboard"
                          : userRole == Role.ADMIN
                          ? "dashboard"
                          : "dashboard"
                      }`}
                      replace
                    />
                  }
                />
              </Routes>
            </div>
            {/* <div className="p-3">
              <Footer />
            </div> */}
          </div>
        </main>
      </div>
    </div>
  );
}
