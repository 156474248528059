import React, { useEffect, useState } from "react";
import Card from "components/card";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

import { useLocation, useNavigate } from "react-router-dom";
import filterImg from "../../../assets/img/filterimg.png";
import Eye from "../../../assets/img/eye.png";
import { FiSearch } from "react-icons/fi";
import { DeleteApi, deleteUser, getUsers } from "services/appServices";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import Thrash from "../../../assets/img/thrash.png";
import Edit from "../../../assets/img/edit.png";
import { errorToast, successToast } from "views/toastConfig";
import { Role } from "views/customTypes/types";
import {
  columnsTable,
  downloadFile,
  handleKeyPress,
} from "helper/helperFunctions";
import { DownloadButton } from "components/custom/DownloadButton";
import { Filter } from "assets/jsx/Filter";
import { FilterFields } from "components/custom/FilterFields";
import { UserDetails } from "./UserDetails";
import { NoData } from "components/custom/NoData";

interface fieldType {
  key: string;
  type: "text" | "select" | "date";
  label: string;
  placeholder: string;
  options?: { value: string; label: string }[];
  collection: string;
}
const filters: fieldType[] = [
  {
    key: "name",
    type: "text",
    label: "Enter Customer Name",
    placeholder: "Enter text",
    collection: "user",
  },

  {
    key: "type",
    type: "text",
    label: "Customer Type",
    placeholder: "Select Type",
    collection: "user",

    // options: [
    //   { value: "jnpaEmployee", label: "JNPA Employee" },
    //   { value: "otherGovtEmployee", label: "Other Govt. Employee" },
    //   { value: "CISF", label: "CISF" },
    //   { value: "other", label: "Other" },
    // ],
  },
  {
    key: "occupation",
    type: "text",
    label: "Enter Occupation",
    placeholder: "Enter text",
    collection: "user",
  },
  // {
  //   key: "createdAt",
  //   type: "date",
  //   label: "Date",
  //   placeholder: "Enter Date",
  //   collection: "user",
  // },
];

export default function Users() {
  const { user } = useSelector((store: any) => store.auth);
  const { userRole } = useSelector((store: any) => store.appSlice);
  const [isPageChanged, setIsPageChanged] = useState<boolean>(false);
  const [searchFilter, setSearchFilter] = useState<{ [key: string]: any }>({});
  const [isFilterOpen, setIsFilteOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimt] = useState(9);
  const [totalCount, setTotalCount] = useState(0);
  const pathParts = location.pathname.split("/");
  const [pageType, setPageType] = useState(pathParts[pathParts.length - 1]);
  const [data, setData] = React.useState(() => []);
  const [showDetails, setShowDetails] = useState(false);
  const [rowData, setRowData] = useState("");

  const columns = columnsTable(
    data,
    [],
    [
      "personnelInfo",
      "organizationInfo",
      "updatedAt",
      "_id",
      "idCardImg",
      "otherDoc",
      "panCardImg",
      "gstCertificateImg",
      "aadharCardImg",
      "emailVerified",
      "mobVerified",
      "role",
      "otherDoc",
      "oldRecord",
      "password",
      "accountStatus"
    ],
    ["name", "email"]
  );
  columns.splice(columns.length, 0, {
    accessor: "action",
    id: "action",
    header: () => (
      <p className="text-center text-sm font-bold  dark:text-white">Action</p>
    ),
    cell: (info: any) => (
      <div className=" flex w-full min-w-36 items-center justify-center ">
        <img
          src={Eye}
          className=" mx-1"
          onClick={() => {
            setShowDetails(true);
            setRowData(info.row.original);
          }}
          alt="details"
        />
        {userRole != Role.HR_ADMIN && (
          <>
            <img
              src={Edit}
              className=" mx-1"
              onClick={() => {
                navigate("edit", { state: info.row.original });
              }}
              alt="edit"
            />
            <img
              src={Thrash}
              onClick={() => {
                removeUser(info.row.original);
              }}
              alt="delete"
            />
          </>
        )}
      </div>
    ),
  });

  //?Delete User
  const removeUser = async (rowData: any) => {
    if (rowData._id == user._id) {
      window.alert("You can't delete yourself!");
      return;
    }
    const Keys = ["name", "email", "role", "mobNo"];
    const isConfirmed = window.confirm(
      `Are you sure you want to delete ?\n${Object.entries(rowData)
        .filter(([key]) => Keys?.includes(key))
        .map(([key, value]) => `${key}: ${value}`)
        .join("\n")}`
    );
    if (isConfirmed) {
      try {
        const resp = await DeleteApi(rowData._id,userRole,user._id);
        const { payload, message, error, statusCode } = resp?.data || {};

        if (statusCode == 200) {
          successToast(message);
          data.length! <= 1
            ? setCurrentPage((prev: number) => --prev)
            : getUser();
        } else {
          console.log({error})
          errorToast(message)
        }
      } catch (error: any) {
        console.log("Error removeUser Users ==>", { error });
        errorToast(error.response?.data?.message || error.message);
      }
    }
  };

  const getUser = async (isDownload = false) => {
    try {
      console.log("getUser called ===>", pageType);
      let filter = {};
      if (pageType == "admin_users") {
        filter = { role: { $nin: [Role.USER] } };
      } else if (pageType == "users") {
        filter = { role: { $in: [Role.USER] } };
      }
      console.log({isDownload})
      filter={...filter,'accountStatus.isActive':true}
      const res = await getUsers(
        { ...filter, searchFilter },
        !isDownload && currentPage,
        !isDownload&& limit
      );
      console.log({ res: res.data });
      if (res?.data?.documents) {
        if (isDownload) {
          downloadFile(res.data.documents);
        } else {
          setData(res.data.documents);
          setTotalCount(res.data.totalCount);
        }
      } else {
        throw Error(res.data.message);
      }
    } catch (error: any) {
      console.log("Error getUser Users ==>", { error });
      errorToast(error.response?.data?.message || error.message);
    }
  };

  useEffect(() => {
    if (Object.keys(searchFilter).length > 0) {
      setCurrentPage(1);
    }
  }, [searchFilter]);

   useEffect(() => {
     getUser();
   }, [currentPage, isPageChanged]);

   //* Just to refresh as we are using the same component for two routes
   useEffect(() => {
     setData([]);
     setCurrentPage(1);
     setTotalCount(0);
     setPageType(pathParts[pathParts.length - 1]);
     setSearchFilter([]);
     setIsPageChanged((prev) => !prev);
     setIsFilteOpen(false);
     setShowDetails(false);
   }, [location.pathname]);

   const table = useReactTable({
     data,
     columns,
     state: {
       sorting,
     },
     onSortingChange: setSorting,
     getCoreRowModel: getCoreRowModel(),
     getSortedRowModel: getSortedRowModel(),
     debugTable: true,
   });
   return (
     <>
       {!showDetails ? (
         <>
           <Card extra={"w-full h-full px-6 pb-6 overflow-x-auto"}>
             <div className="  mt-4  flex h-20  md:h-11 md:self-end ">
               <div className="flex h-full w-full flex-col space-y-2 md:flex-row md:space-y-0">
                 {/*//* Search section */}
                 <div className="flex items-center justify-between rounded-md bg-gray-100 px-2 md:mr-2 md:h-full">
                   <input
                     value={searchFilter?.global?.value || ""}
                     onChange={(e) =>
                       setSearchFilter((prev) => ({
                         ...prev,
                         global: {
                           value: e.target.value,
                           collection: "global",
                         },
                       }))
                     }
                     onKeyDown={(e) => handleKeyPress(e, getUser)}
                     type="text"
                     placeholder="Search..."
                     className="text-md block h-[2.4rem]  bg-gray-100 font-medium text-navy-700 outline-none placeholder:text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:text-white  md:w-fit"
                   />
                   <div
                     onClick={() => {
                       searchFilter.global?.value && getUser();
                     }}
                     className="flex cursor-pointer"
                   >
                     <FiSearch />
                   </div>
                 </div>

                 <div className="flex h-[4.8rem] flex-col space-y-2 md:h-full md:flex-row-reverse md:space-y-0 ">
                   <div className="flex flex-nowrap  items-center  justify-between space-x-1 md:mr-2 md:h-full">
                     <DownloadButton data={data} getAllData={getUser} />
                     {["admin_users"]?.includes(pageType) && (
                       <button
                         className="h-full text-nowrap rounded bg-green-500 px-1 text-white md:h-[80%]"
                         onClick={() => navigate("add")}
                       >
                         Add Users
                       </button>
                     )}
                     <div
                       className=" cursor-pointer shadow-md shadow-[#d4e9ff]"
                       onClick={() => setIsFilteOpen((prev) => !prev)}
                     >
                       <Filter />
                     </div>
                   </div>
                 </div>
               </div>
             </div>
             {isFilterOpen && (
               <FilterFields
                 filters={filters}
                 searchFilter={searchFilter}
                 setSearchFilter={setSearchFilter}
                 cancleFunction={setIsFilteOpen}
                 searchFunction={getUser}
                 onKeyDown={(e: any) => handleKeyPress(e, getUser)}
               />
             )}
             <div className="mt-8 overflow-x-auto">
               {data.length > 0 && (
                 <table className="w-full">
                   <thead>
                     {table.getHeaderGroups().map((headerGroup) => (
                       <tr
                         key={headerGroup.id}
                         className="!border-px !border-gray-400"
                       >
                         {headerGroup.headers.map((header) => {
                           return (
                             <th
                               key={header.id}
                               colSpan={header.colSpan}
                               onClick={header.column.getToggleSortingHandler()}
                               className="mb-2 cursor-pointer border-b-[1px] border-gray-200 bg-gradient-to-t from-[#ffffff] to-[#c7fdff] py-2 text-center align-top shadow-lg"
                             >
                               <div className=" items-center justify-between  text-center text-xs text-[#00353C]">
                                 {flexRender(
                                   header.column.columnDef.header,
                                   header.getContext()
                                 )}
                                 {{
                                   asc: "",
                                   desc: "",
                                 }[header.column.getIsSorted() as string] ??
                                   null}
                               </div>
                             </th>
                           );
                         })}
                       </tr>
                     ))}
                   </thead>
                   <tbody>
                     {table
                       .getRowModel()
                       .rows.slice(0, limit)
                       .map((row) => {
                         return (
                           <tr key={row.id}>
                             {row.getVisibleCells().map((cell) => {
                               return (
                                 <td
                                   key={cell.id}
                                   className="border-x border-y-0 px-1 py-3 text-center"
                                 >
                                   {flexRender(
                                     cell.column.columnDef.cell,
                                     cell.getContext()
                                   )}
                                 </td>
                               );
                             })}
                           </tr>
                         );
                       })}
                   </tbody>
                 </table>
               )}
             </div>
           </Card>

           <ReactPaginate
             className="mt-1 flex justify-center gap-2 font-sans "
             previousLabel={"<"}
             nextLabel={">"}
             breakLabel={"...."}
             pageCount={Math.ceil(totalCount / limit)}
             marginPagesDisplayed={2}
             pageRangeDisplayed={5}
             onPageChange={({ selected }) => setCurrentPage(selected + 1)}
             containerClassName={"pagination"}
             activeClassName={"text-primaryColor-dark font-bold  "}
           />

           <NoData isVisible={data.length == 0} label={"No Data found!"} />
         </>
       ) : (
         <UserDetails rowData={rowData} setShowDetails={setShowDetails} />
       )}
     </>
   );
}
