import * as React from "react";
const SVGComponent = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.1667 7.50171C15.9792 7.5118 16.9608 7.59218 17.6011 8.2325C18.3334 8.96475 18.3334 10.1433 18.3334 12.5003V13.3336C18.3334 15.6906 18.3334 16.8692 17.6011 17.6013C16.8689 18.3336 15.6904 18.3336 13.3334 18.3336H6.66669C4.30966 18.3336 3.13115 18.3336 2.39892 17.6013C1.66669 16.8692 1.66669 15.6906 1.66669 13.3336V12.5003C1.66669 10.1433 1.66669 8.96475 2.39892 8.23249C3.03924 7.59218 4.02083 7.5118 5.83335 7.50171"
      stroke="#1C274C"
      strokeWidth={1.25}
      strokeLinecap="round"
    />
    <path
      d="M10 1.66675V12.5001M10 12.5001L7.5 9.58341M10 12.5001L12.5 9.58341"
      stroke="#1C274C"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SVGComponent;
