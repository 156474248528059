import React, { useState, ChangeEvent, KeyboardEvent } from "react";

interface OtpInputProps {
  length: number;
  inputStyle?: string;
  containerStyle?: string;
  onComplete?: (otp: string) => void;
  _id?: string;
}

export const OtpInput: React.FC<OtpInputProps> = ({
  length,
  inputStyle,
  containerStyle,
  onComplete,
  _id = "otpInput",
}) => {
  const [otp, setOtp] = useState(Array(length).fill(""));

  const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < length - 1) {
        (
          document.getElementById(`${_id}-${index + 1}`) as HTMLInputElement
        ).focus();
      }

      if (newOtp.every((num) => num !== "")) {
        onComplete && onComplete(newOtp.join(""));
      }
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      (
        document.getElementById(`${_id}-${index - 1}`) as HTMLInputElement
      ).focus();
    }
  };

  return (
    <div className={`flex w-full justify-evenly  ${containerStyle} `}>
      {otp.map((value, index) => (
        <input
          key={index}
          id={`${_id}-${index}`}
          type="text"
          maxLength={1}
          value={value}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          className={`rounded border border-gray-300 text-center text-lg focus:border-blue-500 focus:outline-none  ${inputStyle}`}
        />
      ))}
    </div>
  );
};
