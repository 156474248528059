import background from "assets/img/auth/background.jpeg";
import Spinner from "components/custom/Spinner";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "routes";

export default function Auth() {
  const { user } = useSelector((store: any) => store.auth);
  const { loader } = useSelector((store: any) => store.appSlice);


  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  return (
    <div className="float-right h-full min-h-screen w-full overflow-auto bg-[url('assets/img/auth/background.jpeg')] bg-cover bg-center dark:!bg-navy-900">
      {/* <FixedPlugin /> */}

      <main className={`mx-auto min-h-screen`}>
        {loader && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-[rgba(167,245,255,0.2)]">
            <Spinner />
          </div>
        )}

        <div className="mx-4 mb-auto flex h-screen flex-1 items-center  justify-center md:mx-6">
          <Routes>
            {getRoutes(routes)}
            <Route
              path="/signUp"
              element={<Navigate to="/auth/sign_up" replace />}
            />
            <Route
              path="/signIn"
              element={<Navigate to="/auth/sign_in" replace />}
            />
            <Route path="/" element={<Navigate to="/" replace />} />
          </Routes>

          {/* <div className="absolute right-0 hidden h-full min-h-screen md:flex lg:w-[49vw] 2xl:w-[44vw]">
                  <div
                    className="min-h-screen flex-1 "
                    style={{
                      maxWidth: "0px",
                      boxShadow: "14px 17px 40px 4px",
                    }}
                  ></div>
                  <div
                    className="relative flex-1 bg-cover bg-center"
                    style={{
                      backgroundImage: `url(${authImg})`,
                      minWidth: "0",
                      // boxShadow: "14px 17px 40px 1px",
                    }}
                  />
                </div> */}
          {/* <Footer /> */}
        </div>
      </main>
    </div>
  );
}
