export interface allotementStatus {
  cleark: {
    name: string;
    status: STATUS;
    date: Date;
    reason: string;
    _id: any | null;
  };
  manger: {
    name: string;
    status: STATUS;
    date: Date;
    reason: string;
    _id: any | null;
  };
  // admin: {
  //   name: string;
  //   status: STATUS;
  //   date: Date;
  //   reason: string;
  //   _id: Types.ObjectId | null;
  // };
}
enum STATUS {
  PENDING = "pending",
  REJECTED = "rejected",
  APPROVED = "approved",
  VERIFICATION_PENDING = "verificationPending",
  VERIFIED = "verified",
  NOT_VERIFIED = "notVerified",
  CLEARIFY = "clearify",
  CANCELED = "canceled",
  VACANT_REQUEST = "vacantRequest",
  VACANT_REQUEST_APPROVED = "vacantRequestApproved",
}

enum Role {
  USER = "user",
  CLEARK = "cleark",
  MANAGER = "manager",
  ADMIN = "admin",
  FINANCE = "finance",
  HR_ADMIN = "hrAdmin",
}
enum ResourceStatus {
  OCCUPIED = "occupied",
  VACANT = "vacant",
  APPLIED = "applied",
  ALLOTED = "alloted",
}
// enum REQ_STATUS {
//   APPLIED = "applied",
//   // PAYMENT_PENDING = "paymentPending",
//   // PAYMENT_COMPLETED = "paymentCompleted",
//   CLOSED = "closed",
// }

//?not using below enum
export enum PAYMENT_STATUS {
  PAYMENT_PENDING = "paymentPending",
  PAYMENT_COMPLETED = "paymentCompleted",
}
export { Role, ResourceStatus, STATUS };
