import React, { Dispatch, SetStateAction, useRef } from "react";
import Select, { SingleValue } from "react-select";

interface Filter {
  key: string;
  type: "text" | "select" | "date";
  label: string;
  placeholder: string;
  collection: string;
  options?: { value: string; label: string }[];
}

interface FilterFieldsProps {
  filters: Filter[];

  searchFilter: { [key: string]: any };
  setSearchFilter: Dispatch<SetStateAction<{ [key: string]: any }>>;
  searchFunction: any;
  cancleFunction: any;
  onKeyDown: any;
}

export const FilterFields: React.FC<FilterFieldsProps> = ({
  filters,
  searchFilter,
  setSearchFilter,
  searchFunction,
  cancleFunction,
  onKeyDown,
}) => {
  const selectInputRefs = useRef<any>([]);

  const handleInputChange = (
    key: string,
    value: any,
    collection: string,
    type: string
  ) => {
    setSearchFilter((prevState) => {
      const newState = { ...prevState };

      if (!value) {
        delete newState[key];
      } else {
        newState[key] = { value, collection };
      }
      // Reset select field value to null if value is not provided
      if (type === "select" && !value) {
        newState[key] = null;
      }

      return newState;
    });
  };

  const onClear = () => {
    setSearchFilter({});
    // Clear select fields
    selectInputRefs.current.forEach((selectRef: any) => {
      if (selectRef) {
        selectRef.setValue(null); // Use setValue instead of select
      }
    });
  };

  const renderInputField = (filter: Filter, index: number) => {
    if (filter.type !== "select") {
      return (
        <input
          key={index}
          type={filter.type}
          placeholder={filter.placeholder}
          value={searchFilter[filter.key]?.value || ""}
          onChange={(e) =>
            handleInputChange(
              filter.key,
              e.target.value,
              filter.collection,
              filter.type
            )
          }
          onKeyDown={onKeyDown}
          className="hover:border-1 h-[2.4rem] w-full rounded-md border-[1px] border-[#488c9f] px-2 hover:border-[#47b1b1] focus:border-2 focus:border-[#4194a9] focus:outline-none"
        />
      );
    } else {
      return (
        <Select
          key={index}
          options={filter.options}
          isClearable
          onKeyDown={onKeyDown}
          value={filter.options?.find(
            (option) => option.value === searchFilter[filter.key]?.value
          )}
          onChange={(selected: SingleValue<{ value: string; label: string }>) =>
            handleInputChange(
              filter.key,
              selected ? selected.value : null,
              filter.collection,
              filter.type
            )
          }
          placeholder={filter.placeholder}
          styles={{
            control: (provided: any, state: any) => ({
              ...provided,
              // padding: "1px", // Add your desired padding here
              borderColor: state.isFocused ? "#4AA941" : "#4194a9",
              borderWidth: "1px", // Initial border width
              // height: "4px", // Set the desired height here
              // maxHeight: "10px", // Ensure it doesn't get too small
              transition: "border-width 0.3s ease", // Smooth transition for border width
              "&:hover": {
                borderColor: "#47b1b1", // Change border color on hover
                borderWidth: "1px", // Change border width on hover
              },
              "&:focus": {
                borderColor: "#4194a9",
                outline: "none",
              },
            }),
            menu: (provided: any) => ({
              ...provided,

              // backgroundColor: "red",
              // zIndex: 9999, // Ensure the menu is displayed above other elements
            }),
            option: (provided: any, state: any) => ({
              ...provided,
              zIndex: 9999,
              overflow: "auto",
              // minHeight: "5rem",
              flex: 1,
              backgroundColor: state.isSelected ? "deepskyblue" : "white",
              color: "black",
              "&:hover": {
                backgroundColor: "lightblue",
              },
            }),
          }}
          ref={(ref) => {
            selectInputRefs.current[index] = ref;
          }}
        />
      );
    }
  };

  return (
    <div className="mt-3">
      <div className="grid items-center justify-center gap-3 md:grid-cols-4 ">
        {filters.map((filter, index) => (
          <div key={index} className="">
            <label>{filter.label}</label>
            {renderInputField(filter, index)}
          </div>
        ))}
      </div>
      <div className="float-end space-x-2">
        <button
          onClick={onClear}
          type="button"
          className="linear mt-2 rounded-md bg-gray-600 px-2 py-1 text-base font-medium text-white transition duration-200 hover:bg-gray-700 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          Clear
        </button>
        <button
          onClick={() => cancleFunction()}
          type="submit"
          className="linear mt-2 rounded-md bg-red-400 px-2 py-1 text-base font-medium text-white transition duration-200 hover:bg-red-500 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          Cancel
        </button>
        <button
          onClick={() => searchFunction()}
          type="submit"
          className="linear ml-3 mt-2 rounded-md bg-green-600 px-2 py-1 text-base font-medium text-white transition duration-200 hover:bg-green-700 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          Search
        </button>
      </div>
    </div>
  );
};
