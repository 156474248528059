import Dropdown from "components/dropdown";
import React from "react";
// import { FiAlignJustify } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
// import navbarimage from "assets/img/layout/Navbar.png";
// import { FiSearch } from "react-icons/fi";
import avatar from "assets/img/avatars/avatar4.png";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "redux/reducers/authReducer";
import { FiAlignJustify } from "react-icons/fi";
import { Role } from "views/customTypes/types";
import { setRequestCountForPages } from "redux/reducers/appReducer";

const Navbar = (props: {
  onOpenSidenav: () => void;
  brandText: string;
  secondary?: boolean | string;
  role?: string;
  setOpen: any;
  setShowDeleteModal:(show:boolean)=>void
}) => {
  const { user } = useSelector((store: any) => store.auth);
  const { userRole } = useSelector((store: any) => store.appSlice);

  const [openWrapper, setOpenWrapper] = React.useState(false);

  const { brandText, role } = props;
  const [darkmode, setDarkmode] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <nav
      className={` fixed top-0 z-40 flex w-full flex-row  items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]
    ${
      userRole == Role.ADMIN
        ? `xl:pr-[16.4rem]`
        : userRole == Role.MANAGER
        ? `xl:pr-[20.52rem]`
        : userRole == Role.CLEARK
        ? `xl:pr-[20.4rem]`
        : userRole == Role.FINANCE
        ? `xl:pr-[20.4rem]`
        : userRole == Role.USER &&
          (!user.personnelInfo || !user.organizationInfo)
        ? `xl:pr-[17.6rem]`
        : userRole == Role.USER
        ? `xl:pr-[19rem]`
        : `xl:pr-[19rem]`
    }
    `}
    >
      <div className="ml-[6px] flex items-center">
        <FiAlignJustify
          className=" h-7 w-10 xl:hidden"
          onClick={() => {
            props.setOpen((prev: boolean) => true);
          }}
        />

        <p className="shrink capitalize text-navy-700 dark:text-white sm:text-xl md:text-[1.5rem]">
          <label
            // to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {brandText}
          </label>
        </p>
      </div>

      <div
        className={`relative mt-[3px] flex w-[10%] items-center justify-around  gap-2 rounded-full shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:h-[61px] md:flex-grow-0 md:gap-1 md:bg-white   md:px-2 md:py-2   xl:gap-2`}
      >
        <Dropdown
          openWrapper={openWrapper}
          setOpenWrapper={setOpenWrapper}
          button={
            <img
              className="rounded-full md:h-10 md:w-10"
              src={avatar}
              alt="Elon Musk"
            />
          }
          children={
            <div className="flex max-w-[20rem] flex-col justify-start rounded-[20px] bg-gradient-to-bl from-[#d6fdff] via-[#f0fff8] to-[#e5fff8] px-4 py-4 shadow-2xl dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className=" mt-3">
                <div className="flex ">
                  <label className=" text-sm font-bold text-navy-700 dark:text-white">
                    Name
                  </label>
                  <label className="ml-1 max-w-[11rem] overflow-auto text-sm  text-navy-700 dark:text-white">
                    {user.name}
                  </label>{" "}
                </div>
              </div>
              <div className=" mt-2">
                <div className="flex  ">
                  <label className=" text-sm font-bold text-navy-700 dark:text-white">
                    Email
                  </label>
                  <label className="ml-1 max-w-[9rem]  overflow-auto text-sm text-navy-700  dark:text-white md:max-w-[11rem]">
                    {user.email}
                  </label>{" "}
                </div>
              </div>
              <div className=" mt-2">
                <div className="flex items-center gap-2">
                  <label>Role</label>
                  <label className="text-sm font-bold text-navy-700 dark:text-white">
                    {userRole}
                  </label>{" "}
                </div>
              </div>
              <div className=" mt-2">
                <div
                  className="flex items-center gap-2"
                  onClick={() => {
                    navigate("profile", { state: user });
                    setOpenWrapper(false);
                  }}
                >
                  <label className="cursor-pointer font-bold text-blueSecondary">
                    Profile
                  </label>
                </div>
              </div>
              <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className=" mt-3 flex flex-col">
                <Link
                  onClick={() => {
                      dispatch(setToken(null));
                      dispatch(setUser({}));
                      dispatch(setRequestCountForPages({}));
                  }}
                  to="/auth/sign_in"
                  replace={true}
                  className=" text-sm font-medium text-red-500 hover:text-brand-900 dark:text-white"
                >
                  Log Out
                </Link>
              </div>
              
              <div className=" mt-2">
                <div
                  className="flex items-center gap-2"
                  onClick={() => {
                   props.setShowDeleteModal(true)
                  }}
                >
                  <label className="cursor-pointer font-medium text-red-500 text-sm">
                    Delete Account
                  </label>
                </div>
              </div>
            </div>
          }
          classNames={
            " top-14 -left-[11rem]   sm:top-20 md:top-16 xl:-left-[10rem] 2xl:-left-[8rem]"
          }
        />
      </div>
    </nav>
  );
};

export default Navbar;



