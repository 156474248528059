import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import UploadImgContainer from "../../../components/custom/UploadImgContainer";
import { errorToast, successToast } from "views/toastConfig";
import { useSelector } from "react-redux";
import NoRequestFound from "./NoRequest";
import FoundRequest from "./FoundRequest";
import {
  addResourceAllotmentRequest,
  findUserAllotmentRequest,
  getSuggestionForbldType,
  renewalAllotmentRequest,
} from "services/appServices";
import { CustomSelect } from "components/custom/CustomSelect";
import { isOnlyText } from "helper/customRejex";
import { formatHeader, generateDataFields } from "helper/helperFunctions";
import { STATUS } from "views/customTypes/types";

interface ResourceType {
  [key: string]: string;
}

interface PropertyType {
  [key: string]: ResourceType;
}
const propertyType: PropertyType = {
  quarter: {
    buildingType: "Building Type",
  },
  shop: {
    shopType: "Shop Type",
  },
  multipurposeHall: {
    hallType: "Hall Type",
  },
  officerAndStaffClub: {
    clubType: "Club Type",
  },
};

export default function ResourceAllotementRequest() {
  const user = useSelector((store: any) => store.auth.user);
  const { userRole } = useSelector((store: any) => store.appSlice);
  const [userAllotmentRequest, setUserAllotmentRequest] = useState<any>([]);
  const [NoRequest, setNoRequst] = useState<boolean>(true);
  const [isRenewal, setIsRenewal] = useState(false);
  const [ischangeResouceRequest, setIschangeResouceRequest] = useState(false);

  const [selectedResouceData, setSelectedResouceData] = useState<any>();
  const [selectedResourceType, setSelectedResourceType] =
    useState<string>("quarter");

  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );
  const [refresh, setRefresh] = useState<boolean>(false);
  const [newRequest, setNewRequest] = useState<boolean>(false);

  const [minFromDate, setMinFromDate] = useState<string>(
    new Date().toISOString().split("T")[0]
  );
  const [minTillDate, setMinTillDate] = useState<string>(
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toString()
  );
  const baseValidationSchema = Yup.object().shape({
    fromDate: Yup.string().required("From Date is required"),
    tillDate: Yup.string().required("Till Date is required"),
    file: Yup.mixed().required("Please Select Request Letter"),
    name: Yup.string()
      .matches(isOnlyText, "Invalid Full Name")
      .trim()
      .required("Name is required"),
    alternateMobNo: Yup.string()
      .matches(/^[0-9]{10}$/, "Invalid mobile number")
      .optional(),
    mobNo: Yup.string()
      .matches(/^[0-9]{10}$/, "Invalid mobile number")
      .required("Mobile number is required")
      .notOneOf(
        [user.mobNo?.toString()],
        "Guarantor mobile number must be different from the user's mobile number."
      ),
    email: Yup.string()
      .email("Invalid email")
      .required("Email is required")
      .notOneOf(
        [user.email],
        "Guarantor email must be different from the user's email."
      ),
  });

  const baseInitialValues: any = {
    fromDate: "",
    tillDate: "",
    name: "",
    email: "",
    alternateMobNo: "",
    mobNo: "",
    // resourceType: "",
    file: null,
    otherDoc: null,
  };

  const [validationSchema, setValidationSchema] =
    useState<any>(baseValidationSchema);
  const [initialValues, setInitialValues] = useState(baseInitialValues);

  //?todo ntc error
  const findUserRequest = async (_id: any) => {
    try {
      console.log("findUserRequest ==>");
      const res = await findUserAllotmentRequest({
        uid: user?._id,
        "allotementStatus.cancelRequest.status": { $ne: STATUS.APPROVED },
        isActive: true,
      });
      console.log({ Data: res.data });
      if (res.data?.length >= 0) {
        setUserAllotmentRequest(res.data);
      } else {
        setNoRequst(true);
      }
    } catch (error: any) {
      console.log("Error findUserRequest AllotmentRequestForm ==>", { error });
      errorToast(error.response?.data?.message || error.message);
    }
  };

  useEffect(() => {
    if (!NoRequest) {
      getSuggestionForBuildingType({ status: "vacant" });
    }
  }, [NoRequest]);

  const getSuggestionForBuildingType = async (filter: any) => {
    try {
      const resp = await getSuggestionForbldType(filter);
      if (resp.data?.buildingTypes) {
        const formattedOptions = resp.data.buildingTypes.map(
          (type: string) => ({
            value: type,
            label:
              type === "A"
                ? `${type} (One Bedroom)`
                : type === "B"
                ? `${type} (Two Small Bedroom)`
                : type === "C"
                ? `${type} (Two Big Bedroom)`
                : type === "D"
                ? `${type} (Bunglow)`
                : type,
          })
        );
        setOptions(formattedOptions);
      }
      // todo throw error here after checking
    } catch (error: any) {
      errorToast(error.response?.data?.message || error.message);
    }
  };

  useEffect(() => {
    findUserRequest(user?._id);
  }, [refresh]);

  useEffect(() => {
    if (isRenewal) {
      setMinTillDate(
        new Date(
          new Date(selectedResouceData?.tillDate || "").getTime() +
            2 * 24 * 60 * 60 * 1000
        )
          .toISOString()
          .split("T")[0]
      );
      setInitialValues({
        fromDate: new Date(
          new Date(selectedResouceData?.tillDate || "").getTime() +
            1 * 24 * 60 * 60 * 1000
        )
          .toISOString()
          .split("T")[0],
        tillDate: "",
        name: selectedResouceData?.guarantorDetails?.name || "",
        email: selectedResouceData?.guarantorDetails?.email || "",
        alternateMobNo:
          selectedResouceData?.guarantorDetails?.alternateMobNo || "",
        mobNo: selectedResouceData?.guarantorDetails?.mobNo || "",
        // resourceType: "",
        file: null,
        otherDoc: null,
      });
      setValidationSchema(
        Yup.object().shape({
          ...baseValidationSchema.fields,
          fromDate: Yup.string()
            .required("From Date is required")
            .test(
              "fromDate",
              "From Date must be less than Till Date",
              function (value) {
                const { tillDate } = this.parent;
                return !tillDate || new Date(value) < new Date(tillDate);
              }
            ),
          tillDate: Yup.string()
            .required("Till Date is required")
            .test(
              "tillDate",
              "Till Date must be greater than From Date",
              function (value) {
                const { fromDate } = this.parent;
                return !fromDate || new Date(value) > new Date(fromDate);
              }
            ),
        })
      );
    } else if (ischangeResouceRequest) {
      setInitialValues({
        ...baseInitialValues,
        ...Object.keys(propertyType[selectedResourceType] || {}).reduce(
          (values, field) => {
            values[field] = "";
            return values;
          },
          {} as { [key: string]: string }
        ),
        fromDate: new Date().toISOString().split("T")[0],
        tillDate: new Date(new Date(selectedResouceData?.tillDate || ""))
          .toISOString()
          .split("T")[0],
        name: selectedResouceData?.guarantorDetails?.name || "",
        email: selectedResouceData?.guarantorDetails?.email || "",
        alternateMobNo:
          selectedResouceData?.guarantorDetails?.alternateMobNo || "",
        mobNo: selectedResouceData?.guarantorDetails?.mobNo || "",
        // resourceType: "",
        requestType: "changeResouceRequest",
        // file: null,
        otherDoc: null,
      });
    } else {
      setInitialValues({
        ...baseInitialValues,
        ...Object.keys(propertyType[selectedResourceType] || {}).reduce(
          (values, field) => {
            values[field] = "";
            return values;
          },
          {} as { [key: string]: string }
        ),
      });

      setValidationSchema(
        Yup.object().shape({
          ...baseValidationSchema.fields,
          ...Object.keys(propertyType[selectedResourceType] || {}).reduce(
            (schema, field) => {
              schema[field] = Yup.string().required(`${field} is required`);
              return schema;
            },
            {} as { [key: string]: Yup.StringSchema }
          ),
          fromDate: Yup.string()
            .required("From Date is required")
            .test(
              "fromDate",
              "From Date must be less than Till Date",
              function (value) {
                const { tillDate } = this.parent;
                return !tillDate || new Date(value) < new Date(tillDate);
              }
            ),
          tillDate: Yup.string()
            .required("Till Date is required")
            .test(
              "tillDate",
              "Till Date must be greater than From Date",
              function (value) {
                const { fromDate } = this.parent;
                return !fromDate || new Date(value) > new Date(fromDate);
              }
            ),
        })
      );
    }
  }, [selectedResourceType, NoRequest, isRenewal, ischangeResouceRequest]);

  const handleSubmit = async (values: any) => {
    try {
      console.log("handle submit=>", { values });
      // return;
      const formData = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        key != "otherDoc" && formData.append(key, value as string | Blob);
      });
      values.otherDoc &&
        formData.append(
          "otherDoc",
          values.otherDoc,
          `${"otherDoc"}_${user._id}_${values.otherDoc.name}`
        );

      if (isRenewal) {
        formData.append("uid", user._id);
        formData.append("userName", user.name);
        formData.append(
          "oldResourceDetails",
          JSON.stringify(selectedResouceData)
        );
        formData.append("resourceType", selectedResourceType);
        const res = await renewalAllotmentRequest(formData);
        const { payload, message, error, statusCode } = res?.data || {};

        if (statusCode == 201) {
          successToast(message);
          await findUserRequest(user?._id);
          setNewRequest(false);
          setNoRequst(false);
          setIsRenewal(false);
        } else {
          console.log({ error });
          errorToast(message);
        }
      } else if (ischangeResouceRequest) {
        formData.append("uid", user._id);
        formData.append("userName", user.name);
        formData.append(
          "oldResourceDetails",
          JSON.stringify(selectedResouceData)
        );
        formData.append("resourceType", selectedResourceType);

        const res = await addResourceAllotmentRequest(formData);
        const { payload, message, error, statusCode } = res?.data || {};

        if (statusCode == 201) {
          successToast(message);

          await findUserRequest(user?._id);
          setNewRequest(false);
          setNoRequst(false);
          setIschangeResouceRequest(false);
        } else {
          console.log({ error });
          errorToast(message);
        }
      } else {
        console.log("inside else ==>");
        formData.append("uid", user._id);
        formData.append("userName", user.name);
        formData.append("resourceType", selectedResourceType);
        const res = await addResourceAllotmentRequest(formData);
        const { payload, message, error, statusCode } = res?.data || {};
        if (statusCode == 201) {
          successToast(message);
          setUserAllotmentRequest([payload]);
        } else {
          console.log({ error });
          errorToast(message);
        }
      }
    } catch (error: any) {
      console.log("Error handleSubmit AllotementRequestForm ==>", { error });
      errorToast(
        error.response?.data?.message ||
          error.message ||
          "Something went wrong!"
      );
    }
  };

  return (
    <>
      {userAllotmentRequest.length <= 0 || newRequest ? (
        <>
          {NoRequest ? (
            <NoRequestFound setNoRequst={setNoRequst} />
          ) : (
            <div className="mt-8 w-full xl-max:w-full ">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
              >
                {({
                  isSubmitting,
                  setFieldValue,
                  values,
                  resetForm,
                  setFieldError,
                }) => (
                  <Form>
                    {!isRenewal && (
                      <div className="mb-5">
                        <label
                          htmlFor="resourceType"
                          className="block font-medium text-gray-800"
                        >
                          Select Type *
                        </label>
                        <CustomSelect
                          placeholder=" Select Resource Type"
                          setFieldValue={setSelectedResourceType}
                          defaultValue={{
                            label: formatHeader(selectedResourceType),
                            value: selectedResourceType,
                          }}
                          withFormik={false}
                          isDisabled={false}
                          // field={"resourceType"}
                          // extraFuction={setSelectedResourceType}
                          options={[
                            { value: "quarter", label: "Quarter" },
                            // {
                            //   value: "shop",
                            //   label: "Shop",
                            // },
                            // {
                            //   value: "multipurposeHall",
                            //   label: "Multi-Purpose Hall",
                            // },
                            // {
                            //   value: "officerAndStaffClub",
                            //   label: "Officer and Staff Club",
                            // },
                          ]}
                        />
                        <ErrorMessage
                          name="resourceType"
                          component="div"
                          className="text-red-600"
                        />
                      </div>
                    )}

                    <div className="grid md:grid-cols-2 md:gap-3">
                      <div className="mb-5">
                        <label
                          htmlFor="fromDate"
                          className="block font-medium text-gray-800"
                        >
                          From Date *
                        </label>
                        <Field
                          disabled={ischangeResouceRequest}
                          type="date"
                          name="fromDate"
                          className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                          min={minFromDate}
                          onChange={(e: any) => {
                            setFieldValue("fromDate", e.target.value);
                            const nextDay = new Date(e.target.value);
                            nextDay.setDate(nextDay.getDate() + 1); // Add one day
                            setMinTillDate(nextDay.toISOString().split("T")[0]);
                          }}
                        />
                        <ErrorMessage
                          name="fromDate"
                          component="div"
                          className="text-red-600"
                        />
                      </div>

                      <div className="mb-5">
                        <label
                          htmlFor="tillDate"
                          className="block font-medium text-gray-800"
                        >
                          Till Date *
                        </label>
                        <Field
                          disabled={ischangeResouceRequest}
                          type="date"
                          name="tillDate"
                          className="-gray-500 w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border"
                          min={minTillDate}
                          onChange={(e: any) =>
                            setFieldValue("tillDate", e.target.value)
                          }
                        />
                        <ErrorMessage
                          name="tillDate"
                          component="div"
                          className="text-red-600"
                        />
                      </div>
                      {!isRenewal &&
                        Object.keys(
                          propertyType[selectedResourceType] || {}
                        ).map((field: any) => (
                          <div key={field} className="mb-5">
                            <label
                              htmlFor={field}
                              className="block font-medium text-gray-800"
                            >
                              {propertyType[selectedResourceType][field]} *
                            </label>
                            <CustomSelect
                              placeholder=" Select Resource Type"
                              withFormik={true}
                              setFieldValue={setFieldValue}
                              options={options}
                              field={field}
                            />
                            <ErrorMessage
                              name={field}
                              component="div"
                              className="text-red-600"
                            />
                          </div>
                        ))}
                      {isRenewal &&
                        generateDataFields(
                          {
                            ...selectedResouceData,
                            ...selectedResouceData.fields,
                          },
                          [],
                          [
                            "createdAt",
                            "_id",
                            "uid",
                            "allotementStatus",
                            "conversation",
                            "updatedAt",
                            "resourceId",
                            "status",
                            "rent",
                            "oldRecord",
                            "documents",
                            "requestLetter",
                            "paymentReferenceDetails",
                            "personnelInfo",
                            "organizationInfo",
                            "emailVerified",
                            "mobVerified",
                            "allotmentLetter",
                            "finalPaymentReceipt",
                            "bankReceipt",
                            "otherDoc",
                            "remark",
                            "guarantorDetails",
                            "isActive",
                            "fields",
                            "resourceDetails",
                            "fromDate",
                            "tillDate",
                            "maintenance",
                            "waterCharges",
                            "rent",
                            "customerCode",
                            "monthOfPossession",
                            "vacationReport",
                            "isCustomCharge",
                          ]
                        )}
                    </div>
                    <div className="col-span-full mt-2 border-b-2 pb-2 font-bold">
                      <label className="">Guarantor’s Information</label>
                    </div>
                    <div className="mt-4 grid md:grid-cols-2 md:gap-3">
                      <div className="mb-5">
                        <label
                          htmlFor="name"
                          className="block font-medium text-gray-800"
                        >
                          Name *
                        </label>
                        <Field
                          type="text"
                          name="name"
                          placeholder="Enter Gurantor's Mobile Number"
                          className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-red-600"
                        />
                      </div>
                      <div className="mb-5">
                        <label
                          htmlFor="email"
                          className="block font-medium text-gray-800"
                        >
                          Email *
                        </label>
                        <Field
                          type="text"
                          name="email"
                          placeholder="Enter Gurantor's email"
                          className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-red-600"
                        />
                      </div>
                      <div className="mb-5">
                        <label
                          htmlFor="mobNo"
                          className="block font-medium text-gray-800"
                        >
                          Mobile No. *
                        </label>
                        <Field
                          type="text"
                          name="mobNo"
                          maxLength={10}
                          placeholder="Enter Gurantor's Mobile Number"
                          className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                        />
                        <ErrorMessage
                          name="mobNo"
                          component="div"
                          className="text-red-600"
                        />
                      </div>{" "}
                      <div className="mb-5">
                        <label
                          htmlFor="alternateMobNo"
                          className="block font-medium text-gray-800"
                        >
                          Alternate Mob No.
                        </label>
                        <Field
                          type="text"
                          name="alternateMobNo"
                          maxLength={10}
                          placeholder="Enter Gurantor's Alternate Mobile Number"
                          className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                        />
                        <ErrorMessage
                          name="alternateMobNo"
                          component="div"
                          className="text-red-600"
                        />
                      </div>
                    </div>
                    <div className="col-span-4 mb-4 mt-2 border-t-2 pb-2 font-bold"></div>

                    <div>
                      <UploadImgContainer
                        htmlFor="file"
                        file={values.file}
                        label="Organization’s Request Letter *"
                        css="mb-5"
                        setFieldValue={setFieldValue}
                      />
                      <ErrorMessage
                        name="file"
                        component="div"
                        className="text-red-600"
                      />
                    </div>

                    <div className="light col-span-2 ">
                      <UploadImgContainer
                        htmlFor="otherDoc"
                        file={values.otherDoc}
                        label="Other Documents"
                        label1="Upload Other Documents (Merge into Single File)"
                        css="mb-5"
                        setFieldValue={setFieldValue}
                        field="otherDoc"
                        id="otherFileInput"
                      />
                    </div>
                    <ErrorMessage
                      name="otherDoc"
                      component="div"
                      className="text-red-600"
                    />

                    <div className="flex justify-end gap-2">
                      <button
                        type="button"
                        onClick={() => {
                          if (isRenewal) {
                            setNewRequest(false);
                            setIsRenewal(false);
                          } else if (ischangeResouceRequest) {
                            setIschangeResouceRequest(false);
                            setNewRequest(false);
                          } else {
                            setNoRequst(true);
                          }
                        }}
                        className="mt-2 w-[20%] rounded-xl bg-gray-400 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-gray-700 active:bg-gray-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                      >
                        Back
                      </button>
                      <button
                        onClick={() => resetForm(baseInitialValues)}
                        type="reset"
                        className="mt-2 w-[20%] rounded-xl bg-red-400 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-red-700 active:bg-gray-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                      >
                        Clear
                      </button>
                      <button
                        onClick={() => {
                          !selectedResourceType &&
                            setFieldError(
                              "resourceType",
                              "Please Select Resource Type"
                            );
                        }}
                        type="submit"
                        className="mt-2 w-[20%] rounded-xl bg-primaryColor-dark py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                        disabled={isSubmitting}
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </>
      ) : (
        <>
          {userAllotmentRequest?.map((e: any, index: number) => (
            <FoundRequest
              index={index}
              key={e.id} // Assuming each element has a unique identifier like 'id'
              user={user} // Assuming 'user' is defined elsewhere in your component
              userAllotmentRequest={e} // The entire array passed
              setRefresh={setRefresh} // Function to update state for refresh
              setNewRequest={setNewRequest} // Function to update state for new requests
              setNoRequst={setNoRequst} // Function to update state for no requests
              setIsRenewal={setIsRenewal} // Function to update state for isRenewal
              setSelectedResouceData={setSelectedResouceData}
              allData={userAllotmentRequest}
              setIschangeResouceRequest={setIschangeResouceRequest}
              userRole={userRole}
              // Other props specific to each 'e' object in userAllotmentRequest
            />
          ))}
        </>
      )}
    </>
  );
}

