import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: false,
  requestCountForPages: {
    request_for_resources: "",
    manager_approval_pending: "",
    clearification_pending: "",
    payment_verification_pending: "",
    payment_verification_approved: "",
    payment_verification_rejected: "",
    finance_approval_pending: "",
    finance_Approved_request: "",
    approved_allotment_requests: "",
    manager_approved_requests: "",
    rejected_requests: "",
    canceled_requests: "",
    vacant_resource_request: "",
    renewal_requests: "",
    change_resource_request: "",
    manager_approval_vacant_pending: "",
    finance_approval_vacant_pending: "",
  },
  userRole:''
};

export const appSlice = createSlice({
  name: "appSlice",
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setRequestCountForPages: (state, action) => {
      state.requestCountForPages = action.payload;
    }, setUserRole: (state, action) => {
      state.userRole=action.payload
    }
  },
});

export const { setLoader, setRequestCountForPages,setUserRole } = appSlice.actions;

export default appSlice.reducer;
