import { ErrorMessage, Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { errorToast } from "views/toastConfig";
import * as Yup from "yup";
import Logo from "../../assets/img/logo.png";
import { sendOtp } from "services/appServices";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { state } = useLocation();
  const { user, token } = useSelector((store: any) => store.auth);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    // oldPassword: Yup.string().required("Old Password is required"),
    newPassword: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("New Password is required"),
  });

  const initialValues = {
    email: "",
    // oldPassword: "",
    newPassword: "",
    type: "forgotPassword",
  };

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      const res = await sendOtp(values);
      console.log(res,"res")

      if (res?.data?.statusCode == 200) {
        navigate("/auth/otp_screen", {
          state: {
            email: values.email,
            newPassword: values.newPassword,
            type: "forgotPassword",
          },
        });
      } else {
        console.log("Inside else ==>", { res });
        console.log(
          "Error while createUser =>",
          res.data?.otpResponse?.message
        );
        throw Error(res.data?.message || res.data?.otpResponse?.message);
      }
      setSubmitting(false);
    } catch (error: any) {
      console.log("Error while createUser ==>", { error });
      errorToast(
        error.response?.data?.message ||
          error.message ||
          "Something went wrong!"
      );
    }
  };

  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="w-full max-w-full items-center rounded-md bg-white px-5 py-10 md:px-10 xl:max-w-[420px]">
        <div className="flex items-center justify-center self-center">
          <img className="w-[40%]" src={Logo} alt="Logo" />
        </div>
        <h4 className="mt-2 text-center text-3xl font-bold text-navy-700 dark:text-white md:text-3xl">
          Forgot Password
        </h4>
        <p className="mb-4 ml-1 text-center text-base text-gray-800">
          Please enter your email and new password.
        </p>

        <div className="self-center">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="mb-5">
                  <label
                    htmlFor="email"
                    className="mb-2 block font-medium text-gray-800"
                  >
                    Email *
                  </label>
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter your Email"
                    className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-600"
                  />
                </div>

                {/* <div className="mb-5">
                  <label
                    htmlFor="oldPassword"
                    className="mb-2 block font-medium text-gray-800"
                  >
                    Old Password *
                  </label>
                  <Field
                    type="password"
                    name="oldPassword"
                    id="oldPassword"
                    placeholder="Enter your Old Password"
                    className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                  />
                  <ErrorMessage
                    name="oldPassword"
                    component="div"
                    className="text-red-600"
                  />
                </div> */}

                <div className="mb-5">
                  <label
                    htmlFor="newPassword"
                    className="mb-2 block font-medium text-gray-800"
                  >
                    New Password *
                  </label>
                  <Field
                    type="password"
                    name="newPassword"
                    id="newPassword"
                    placeholder="Enter your New Password"
                    className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                  />
                  <ErrorMessage
                    name="newPassword"
                    component="div"
                    className="text-red-600"
                  />
                </div>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="linear mb-4 mt-5 w-full rounded-xl bg-primaryColor-dark py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                >
                  Reset Password
                </button>

                <div className="mt-4 text-center">
                  <Link
                    to="/auth/sign_in"
                    className="text-sm font-medium text-brand-300 hover:text-brand-600 dark:text-white" replace={true}
                  >
                    Back to Login
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
