import { CustomCreatableSelect } from "components/custom/CustomCreatableSelect";
import { ErrorMessage, Field } from "formik";
import {
  alphanumericSort,
  convertToOptions,
  formatHeader,
  getMinMonth,
} from "helper/helperFunctions";
import { Role } from "views/customTypes/types";
import { infoToast } from "views/toastConfig";

const fieldOrder = ["buildingType", "sectorNumber", "buildingNumber", "roomNo"];
export const FormFieldsSec = ({
  rowData,
  pageType,
  role,
  values,
  initialDynamicFields,
  rentalUnit,
  setFieldValue,
  setInitialDynamicFields,
  generateCode,
  suggestion,
  setIsStandardCharges,
}: {
  rowData: any;
  pageType: any;
  role: any;
  values: any;
  initialDynamicFields: any;
  rentalUnit: any;
  setFieldValue: any;
  setInitialDynamicFields: any;
  generateCode: any;
  suggestion: any;
  setIsStandardCharges:(data:boolean)=>void
}) => {
  const splitSuggestions = (suggestions: string[]) => {
    const buildingTypes = new Set<string>();
    const sectorNumbers = new Set<string>();
    const buildingNumbers = new Set<string>();
    const roomNumbers = new Set<string>();

    suggestions.forEach((suggestion) => {
      const [buildingType, sectorNumber, buildingNumber, roomNo] =
        suggestion.split("/");
      buildingTypes.add(buildingType);
      sectorNumbers.add(sectorNumber);
      buildingNumbers.add(buildingNumber);
      roomNumbers.add(roomNo);
    });

    return {
      buildingTypes: Array.from(buildingTypes)
        .sort(alphanumericSort)
        .filter((type) => !type.startsWith("S")),
      sectorNumbers: Array.from(sectorNumbers).sort(alphanumericSort),
      buildingNumbers: Array.from(buildingNumbers).sort(alphanumericSort),
      roomNumbers: Array.from(roomNumbers).sort(alphanumericSort),
    };
  };
  const suggestionOptions = splitSuggestions(suggestion);

  const fieldToSuggestionKeyMap: {
    [key: string]: keyof typeof suggestionOptions;
  } = {
    buildingType: "buildingTypes",
    sectorNumber: "sectorNumbers",
    buildingNumber: "buildingNumbers",
    roomNo: "roomNumbers",
  };
  const getFilteredOptions = (fieldKey: string) => {
    const { buildingType, sectorNumber, buildingNumber, roomNo } =
      initialDynamicFields;

    let filteredSuggestions = suggestion;
    if (fieldKey === "sectorNumber" && buildingType) {
      filteredSuggestions = filteredSuggestions.filter((s: any) =>
        s.startsWith(`${buildingType}/`)
      );
    }

    if (fieldKey === "buildingNumber" && buildingType && sectorNumber) {
      filteredSuggestions = filteredSuggestions.filter((s: any) =>
        s.startsWith(`${buildingType}/${sectorNumber}/`)
      );
    }

    if (
      fieldKey === "roomNo" &&
      buildingType &&
      sectorNumber &&
      buildingNumber
    ) {
      // console.log({ filteredSuggestions });
      filteredSuggestions = filteredSuggestions.filter((s: any) =>
        s.startsWith(`${buildingType}/${sectorNumber}/${buildingNumber}/`)
      );
    }

    const splitFilteredSuggestions = splitSuggestions(filteredSuggestions);
    return splitFilteredSuggestions[fieldToSuggestionKeyMap[fieldKey]];
  };

  return (
    <>
      {/* //* Resource field Section*/}
      <div className="mt-4 grid grid-cols-2 gap-y-6 md:grid-cols-4">
        {Object.entries(initialDynamicFields).map(
          ([key, value]: [string, any]) =>
            key !== "remark" &&
            key != "dateOfPossession" && ( // Add this check to skip the 'remark' field
              <div key={key} className="px-2">
                <label className="block">
                  {formatHeader(key)}
                  {value}
                </label>

                <CustomCreatableSelect
                  options={convertToOptions(getFilteredOptions(key))}
                  setFieldValue={setFieldValue}
                  placeholder={`Select ${key}`}
                  field={key}
                  resetTrigger={!values[key] && true}
                  defaultValue={{ label: value, value }}
                  extraFuction={(field: any, value: any) => {
                    setInitialDynamicFields((prevFields: any) => {
                      setIsStandardCharges(true)
                      const updatedFields = {
                        ...prevFields,
                        [field]: value,
                      };

                      if (updatedFields.buildingType === "D") {
                        delete updatedFields.roomNo;
                      }

                      // Find the index of the modified field
                      const fieldIndex = fieldOrder.indexOf(field);

                      // Reset all fields after the modified field
                      fieldOrder.forEach((key, index) => {
                        if (index > fieldIndex) {
                          updatedFields[key] = ""; // Reset subsequent fields
                        }
                      });
                      return updatedFields;
                    });
                  }}
                  isDisabled={
                    ![
                      "request_for_resources",
                      "clearification_pending",
                      "change_resource_request",
                    ]?.includes(pageType) || role == Role.MANAGER
                  }
                />

                <ErrorMessage
                  name={key}
                  component="div"
                  className="text-red-600"
                />
              </div>
            )
        )}
        <div className=" mb-2 ml-3">
          <label
            htmlFor="rentalUnit"
            className=" block font-medium text-gray-800"
          >
            Rental Unit.
          </label>
          <label>{rentalUnit}</label>
        </div>

        {(rowData.customerCode ||
          (pageType == "payment_verification_approved" &&
            role === Role.CLEARK)) && (
          <div className="px-2">
            <label className="block">Customer Code</label>
            <div className="flex flex-1 items-center gap-2">
              {!rowData.customerCode ? (
                <div>
                  <div className="flex gap-3">
                    <Field
                      disabled={true}
                      type="text"
                      name="customerCode"
                      placeholder={`User's Customer Code`}
                      className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                      min={0}
                    />
                    <button
                      className="rounded-xl bg-gradient-to-r from-blue-300 to-blue-400 px-2 py-2 text-base  font-medium text-white shadow-md transition duration-200 hover:bg-gradient-to-t hover:to-blue-500 hover:shadow-xl active:bg-brand-700 active:to-blue-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:py-2"
                      onClick={() => {
                        generateCode();
                      }}
                      type="button"
                    >
                      Generate
                    </button>
                  </div>

                  <ErrorMessage
                    name="customerCode"
                    component="div"
                    className="text-red-600"
                  />
                </div>
              ) : (
                <label className="font-bold text-blue-500">
                  {rowData.customerCode}
                </label>
              )}
            </div>
          </div>
        )}

        {(rowData.dateOfPossession ||
          (pageType == "finance_Approved_request" && role == Role.CLEARK)) && (
          <div className="px-2">
            {!rowData.dateOfPossession && (
              <>
                <label className="block">Date Of Possession</label>
                <Field
                  name="dateOfPossession"
                  type="month"
                  min={getMinMonth()} // Calculate inline
                  onChange={(event: any) => {
                    const date = event.target.value;
                    // Extract the year and month from the input value and set it to the first day of the month
                    const [year, month] = date.split("-");
                    const firstDayOfMonth = `${year}-${month}-01`;
                    setFieldValue("dateOfPossession", firstDayOfMonth);
                  }}
                  value={
                    values.dateOfPossession
                      ? values.dateOfPossession.slice(0, 7)
                      : ""
                  }
                />
              </>
            )}

            <ErrorMessage
              name={"dateOfPossession"}
              component="div"
              className="text-red-600"
            />
          </div>
        )}
      </div>
    </>
  );
};
