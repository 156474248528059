import React, { useEffect, useState, useRef } from "react";
import { formatHeader } from "helper/helperFunctions";

export const Modal = ({
  visible,
  onClose,
  onConfirm,
  headerLabel,
  reason,
  setReason,
  action,
}: any) => {
  const [showModal, setShowModal] = useState(false);
  const [animationClass, setAnimationClass] = useState("");
  const [isReasonValid, setIsReasonValid] = useState(false); // State to track if reason is valid
  const [showError, setShowError] = useState(false); // State to manage when to show error message

  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (visible) {
      setShowModal(true);
      setTimeout(() => setAnimationClass("opacity-100 scale-100"), 10);
    } else {
      setAnimationClass("opacity-0 scale-95");
      setTimeout(() => setShowModal(false), 300);
    }
  }, [visible]);

  useEffect(() => {
    // Check if reason is not empty
    setIsReasonValid(reason.trim() !== "");
  }, [reason]);

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onClose(); // Call onClose function when clicking outside of modal
    }
  };

  useEffect(() => {
    if (showModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showModal]);

  const handleConfirm = () => {
    if (isReasonValid) {
      onConfirm(action); // Call onConfirm function if reason is valid
    } else {
      setShowError(true); // Show error message if reason is not valid
    }
  };

  const handleChangeReason = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReason(e.target.value);
    setShowError(false); // Hide error message when reason is being edited
  };

  if (!showModal) return null;

  return (
    <div className="bg-black fixed inset-0 flex items-center justify-center bg-opacity-50 transition-opacity duration-300">
      <div
        ref={modalRef}
        className={`transform rounded-lg bg-gradient-to-b from-[#d7f8ff] to-[#ffffff] p-8 shadow-lg transition-transform duration-300 md:pt-10 ${animationClass} items-center justify-center`}
      >
        <h2 className="text-transparent mb-4 bg-clip-text text-center text-2xl font-bold text-[#181a22]">
          {headerLabel}
        </h2>

        <div className="relative mb-4 w-full md:w-96">
          <textarea
            className="w-full rounded border border-gray-300 p-2 outline-blue-200"
            placeholder="Enter reason..."
            value={reason}
            rows={4}
            onChange={handleChangeReason}
          ></textarea>
          {showError && !isReasonValid && (
            <p className="absolute bottom-0 left-1/2 -mb-5 -translate-x-1/2 text-nowrap text-sm text-red-500">
              Reason is required.
            </p>
          )}
        </div>
        <div className="flex justify-center space-x-2 pt-4">
          <button
            className="rounded bg-gray-300 px-4 py-2 transition-colors duration-200 hover:bg-gray-400"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className={`rounded bg-gradient-to-r from-blue-300 to-blue-500 px-4 py-2 text-white shadow-lg transition-colors duration-200 hover:from-blue-500 hover:to-blue-300 active:bg-gradient-to-t`}
            onClick={handleConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
