import { Button } from "@chakra-ui/react";
import { useState } from "react";
import { addResourceFromCsv } from "services/appServices";
import { errorToast, successToast } from "views/toastConfig";
import * as FS from "file-saver";
import { useLocation, useNavigate } from "react-router-dom";
import { downloadFile } from "helper/helperFunctions";
const samplefile = require("../../../sampleFiles/ResourceUpload.xlsx");

//todo change file name
function AddFromCsv() {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const location = useLocation();

  // todo check error
  const submitFile = async () => {
    try {
      if (!file) {
        errorToast("Please select a file");
      } else {
        let formData = new FormData();
        formData.append("file", file);
        const fileUploadRes = await addResourceFromCsv(formData);
        console.log({ fileUploadRes: fileUploadRes.data.data });
        if (fileUploadRes.data.data.length == 0) {
          successToast("File Uploaded successfully");
        } else {
          downloadFile(fileUploadRes.data.data, "FaildeData");
          errorToast("Some Data not uploaded \nPlease check downloaded file");
        }
      }
    } catch (error) {
      console.log("Error submitFile addFRomCsv.tsx", { error });
      errorToast("Something went wrong!");
    }
  };

  return (
    <div className="flex max-h-96 w-full flex-col items-center justify-center border-4 border-dotted border-gray-500 bg-gray-300">
      <input
        className="my-24 bg-gray-100"
        type="file"
        id="uploadFile"
        name="file"
        accept=".xls, .xlsx"
        onChange={(e) => e.target.files[0] && setFile(e.target.files[0])}
      ></input>
      <br />
      <div className="my-8">
        <Button
          type="reset"
          className="mx-5 rounded-md bg-gray-500 p-2 px-5 text-white dark:bg-brand-400"
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </Button>

        {location.pathname?.includes("quarter") && (
          <Button
            type="button"
            className="mx-5 rounded-md bg-green-500 p-2 text-white dark:bg-brand-400"
            onClick={(e) => {
              FS.saveAs(samplefile, "Sample.xlsx");
              e.stopPropagation();
            }}
          >
            Download Sample File
          </Button>
        )}
        <Button
          type="submit"
          className="mx-5 rounded-md bg-brand-500 p-2 text-white dark:bg-brand-400"
          onClick={() => {
            submitFile();
          }}
        >
          Upload File
        </Button>
      </div>
    </div>
  );
}

export default AddFromCsv;
