const SVGComponent = (props) => (
  <svg
    // width={38}
    // height={38}
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <ellipse cx={22.5214} cy={22.5} rx={22.4786} ry={22.5} fill="#49C6F4" />
    <path
      d="M26.8161 22.8135L14.8547 24.8081C14.7172 24.8311 14.5882 24.8899 14.4805 24.9786C14.3729 25.0673 14.2906 25.1829 14.2417 25.3135L10.1175 36.372C9.72367 37.3892 10.7861 38.3587 11.7612 37.8692L40.3464 23.5653C40.544 23.4662 40.7101 23.314 40.8262 23.1258C40.9423 22.9376 41.0038 22.7208 41.0038 22.4996C41.0038 22.2785 40.9423 22.0617 40.8262 21.8735C40.7101 21.6853 40.544 21.5331 40.3464 21.434L11.7612 7.1301C10.7861 6.64218 9.72367 7.61166 10.1175 8.62724L14.2433 19.6857C14.2922 19.8164 14.3745 19.9319 14.4821 20.0207C14.5897 20.1094 14.7188 20.1682 14.8563 20.1912L26.8177 22.1858C26.8923 22.1977 26.9602 22.2358 27.0092 22.2934C27.0583 22.3509 27.0852 22.424 27.0852 22.4996C27.0852 22.5753 27.0583 22.6484 27.0092 22.7059C26.9602 22.7635 26.8923 22.8016 26.8177 22.8135H26.8161Z"
      fill="white"
    />
  </svg>
);
export default SVGComponent;
