import { AddressComponent } from "components/custom/AddressComponent";
import { CustomCreatableSelect } from "components/custom/CustomCreatableSelect";
import { CustomSelect } from "components/custom/CustomSelect";
import { DocWithDownloadButton } from "components/custom/DocWithDownloadButton";
import UploadImgContainer from "components/custom/UploadImgContainer";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { isOnlyText } from "helper/customRejex";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "redux/reducers/authReducer";
import {
  getOccupationSuggestions,
  updateUserWithDoc,
} from "services/appServices";
import { errorToast, successToast } from "views/toastConfig";
import * as Yup from "yup";

export default function PersonnelInfo() {
  const navigate = useNavigate();
  const user = useSelector((store: any) => store.auth.user);
  const dispatch = useDispatch();

  const [occupationSuggesionList, setOccupationSuggesionList] = useState([
    { value: "service", label: "service" },
  ]);

  const fetchSuggestions = async () => {
    try {
      console.log("fetchSuggestions pi ==>");
      const suggestions = await getOccupationSuggestions();
      if (true) {
        setOccupationSuggesionList(
          suggestions.data.map((e: any) => {
            return {
              value: e,
              label: e,
            };
          })
        );
        console.log(occupationSuggesionList);
      }
    } catch (error) {
      console.error("Error fetching occupation suggestions: PersonnelInfo", {
        error,
      });
      setOccupationSuggesionList([]); // Assuming you have setFieldValue available here
    }
  };

  const handleFileChange = (event: any, setFieldValue: any, field: string) => {
    if (event.target.files && event.target.files.length > 0) {
      setFieldValue(field, event.target.files[0]);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(isOnlyText, "Invalid Full Name")
      .trim()
      .required("Name is required"),
    aadharNo: Yup.string()
      .matches(/^\d{12}$/, "Invalid Adhaar card number")
      .trim()
      .required("Aadhar No. is required"),
    type: Yup.string().trim().required("Type is required"),
    dob: Yup.string().required("D.O.B. is required"),
    fatherOrHusbandName: Yup.string()
      .matches(isOnlyText, "Invalid Father/Husband Name")
      .trim()
      .required("Father/Husband Name is required"),
    gender: Yup.string().trim().required("Gender is required"),
    occupation: Yup.string().trim().required("Occupation is required"),
    panNo: Yup.string()
      .matches(
        /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}$/,
        "Invalid PAN card number"
      )
      .required("Pan No. is required"),
    addressLine1: Yup.string().trim().required("Address is required"),
    city: Yup.string()
      .trim()
      .matches(isOnlyText, "Invalid City value")
      .required("City is required"),
    state: Yup.string()
      .trim()
      .matches(isOnlyText, "Invalid State Value")
      .required("State is required"),
    gstCertificateNo: Yup.string()
      .trim()
      // .matches(/^\d{15}$/, "Invalid GST Certificate No.")
      .optional(),
    alternateMobNo: Yup.string()
      .matches(/^[0-9]{10}$/, "Invalid mobile number")
      .optional(),
  });

  const initialValues = {
    name: user.name || "",
    aadharNo: user.aadharNo || "",
    type: user.type || "",
    dob: user.dob || "",
    fatherOrHusbandName: user.fatherOrHusbandName || "",
    gender: user.gender || "",
    occupation: user.occupation || "",
    panNo: user.panNo || "",
    addressLine1: user.permanentAddress?.addressLine1 || "",
    addressLine2: user.permanentAddress?.addressLine2 || "",
    city: user.permanentAddress?.city || "",
    state: user.permanentAddress?.state || "",
    gstCertificateNo: user.gstCertificateNo || "",
    alternateMobNo: user.alternateMobNo || "",
    otherDoc: null as any,
    idCardImg: null as any,
    gstCertificateImg: null as any,
    aadharCardImg: null as any,
  };

  const handleSubmit = async (
    values: any,
    { setSubmitting, setFieldError }: FormikHelpers<any>
  ) => {
    try {
      if (
        !values.aadharCardImg ||
        !values.panCardImg ||
        !values.idCardImg ||
        (values.gstCertificateNo && !values.gstCertificateImg)
      ) {
        if (!values.aadharCardImg) {
          setFieldError("aadharNo", "Please upload Aadhar card image.");
        }
        if (!values.panCardImg) {
          setFieldError("panNo", "Please upload PAN card image.");
        }
        if (!values.idCardImg) {
          setFieldError("type", "Please upload Id card image.");
        }
        if (values.gstCertificateNo && !values.gstCertificateImg) {
          setFieldError(
            "gstCertificateNo",
            "Please upload GST certificate image."
          );
        }
        return;
      }
      const formData = new FormData();

      Object.keys(values).forEach((key: any) => {
        if (values[key] == null) return;

        if (
          key === "aadharCardImg" ||
          key === "panCardImg" ||
          key === "gstCertificateImg" ||
          key === "idCardImg" ||
          key === "otherDoc"
        ) {
          formData.append(
            key,
            values[key],
            `${key}_${user._id}_${values[key].name}`
          );
        } else if (typeof values[key] === "string") {
          formData.append(key, values[key].trim());
        } else {
          formData.append(key, values[key]);
        }
      });
      formData.append("action", "personnelInfo");
      formData.append("_id", user._id);
      const updateUserRes = await updateUserWithDoc(formData);
      const { payload, message, error, statusCode } = updateUserRes?.data || {};

      if (statusCode == 200) {
        successToast("Personnel Info Updated Successfully");
        navigate("/admin/organization_info");
        dispatch(setUser(payload));
      } else {
        console.log("Error while updating Personnel Info:", { error });
        errorToast(message);
      }
    } catch (error: any) {
      console.log("Error while updating Personnel Info:", { error });
      errorToast(error.response?.data?.message || error.message);
    }
    setSubmitting(false);
  };

  useEffect(() => {
    user && !user?.personnelInfo && fetchSuggestions();
    console.log({ user });
  }, []);

  return (
    <div className="mt-8">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnBlur={true}
      >
        {({ isSubmitting, setFieldValue, handleBlur, values }) => (
          <Form>
            <div className="grid gap-4 md:grid-cols-2">
              {/* Full Name */}
              <div className="mb-2">
                <label
                  htmlFor="name"
                  className="mb-2 block font-medium text-gray-800"
                >
                  Full Name (As per Adhaar Card) *
                </label>
                <Field
                  onBlur={(e: any) => {
                    setFieldValue(e.target.name, e.target?.value?.trim());
                    handleBlur(e);
                  }}
                  disabled={user?.personnelInfo}
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Full Name as per Adhaar Card"
                  className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-red-600"
                />
              </div>

              {/* Occupation */}
              <div className="mb-2">
                <label
                  htmlFor="occupation"
                  className="mb-2 block font-medium text-gray-800"
                >
                  Occupation *
                </label>

                <CustomCreatableSelect
                  field="occupation"
                  placeholder="Select Occupation"
                  options={occupationSuggesionList}
                  setFieldValue={setFieldValue}
                  isDisabled={user.personnelInfo}
                  defaultValue={{
                    label: initialValues.occupation,
                    value: initialValues.occupation,
                  }}
                />

                <ErrorMessage
                  name="occupation"
                  component="div"
                  className="text-red-600"
                />
              </div>

              {user.personnelInfo && (
                <>
                  {/* Mob No */}
                  <div className="mb-2">
                    <label
                      htmlFor="alternateMobNo"
                      className="mb-2 block font-medium text-gray-800"
                    >
                      Mob. No.
                    </label>
                    <Field
                      onBlur={(e: any) => {
                        setFieldValue(e.target.name, e.target?.value?.trim());
                        handleBlur(e);
                      }}
                      disabled={user?.personnelInfo}
                      type="text"
                      maxLength={10}
                      name="mobNo"
                      id="mobNo"
                      value={user?.mobNo}
                      placeholder="Enter Alternate Mob no."
                      className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                    />
                    <ErrorMessage
                      name="mobNo"
                      component="div"
                      className="text-red-600"
                    />
                  </div>

                  {/* Email */}
                  <div className="mb-2">
                    <label
                      htmlFor="alternateMobNo"
                      className="mb-2 block font-medium text-gray-800"
                    >
                      Email
                    </label>
                    <Field
                      onBlur={(e: any) => {
                        setFieldValue(e.target.name, e.target?.value?.trim());
                        handleBlur(e);
                      }}
                      disabled={user?.personnelInfo}
                      type="text"
                      maxLength={10}
                      name="email"
                      id="email"
                      value={user?.email}
                      placeholder="Enter Alternate Mob no."
                      className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-600"
                    />
                  </div>
                </>
              )}

              {/* Date of Birth */}
              <div className="mb-2">
                <label
                  htmlFor="dob"
                  className="mb-2 block font-medium text-gray-800"
                >
                  Date of Birth *
                </label>
                <Field
                  disabled={user?.personnelInfo}
                  type="date"
                  name="dob"
                  id="dob"
                  placeholder="Enter Your Date of Birth"
                  className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                  min="1924-01-01"
                  max={
                    new Date(
                      new Date().setFullYear(new Date().getFullYear() - 10)
                    )
                      .toISOString()
                      .split("T")[0]
                  }
                />
                <ErrorMessage
                  name="dob"
                  component="div"
                  className="text-red-600"
                />
              </div>

              {/* Customer Type */}
              <div className="mb-2">
                <label
                  htmlFor="type"
                  className="mb-2 block font-medium text-gray-800"
                >
                  Customer Type *
                </label>
                <div className="flex flex-1 items-center gap-2 md-max:flex-wrap">
                  <CustomSelect
                    className={"basis-[60%]"}
                    field="type"
                    placeholder="Select Type"
                    isDisabled={user.personnelInfo}
                    options={[
                      { value: "jnpaEmployee", label: "JNPA Employee" },
                      {
                        value: "otherGovtEmployee",
                        label: " Other Govt. Employee",
                      },
                      {
                        value: "retiredJnpaEmployee",
                        label: "Retired JNPA Employee",
                      },
                      { value: "other", label: "Other" },
                    ]}
                    setFieldValue={setFieldValue}
                    defaultValue={{
                      label: initialValues.type,
                      value: initialValues.type,
                    }}
                  />
                  {!user.idCardImg && !user.personnelInfo && (
                    // &&
                    // ["otherGovtEmployee"]?.includes(
                    //   values.type || initialValues.type
                    // )
                    <input
                      // ref={idCardInput}
                      type="file"
                      name="idCardImg"
                      id="idCardImg"
                      className=" md:basis-[50%] md-max:w-full lg:basis-[37%] xl:basis-[42%] 2xl:basis-[40%] 3xl:basis-[30%]"
                      onChange={(event) => {
                        handleFileChange(event, setFieldValue, "idCardImg");
                      }}
                      accept=".pdf"
                    />
                  )}
                </div>
                <ErrorMessage
                  name="type"
                  component="div"
                  className="text-red-600"
                />
              </div>

              {/* Father/Husband Name */}
              <div className="mb-2">
                <label
                  htmlFor="fatherOrHusbandName"
                  className="mb-2 block font-medium text-gray-800"
                >
                  Father/Husband Name *
                </label>
                <Field
                  onBlur={(e: any) => {
                    setFieldValue(e.target.name, e.target?.value?.trim());
                    handleBlur(e);
                  }}
                  disabled={user?.personnelInfo}
                  type="text"
                  name="fatherOrHusbandName"
                  id="fatherOrHusbandName"
                  placeholder="Enter full name"
                  className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                />
                <ErrorMessage
                  name="fatherOrHusbandName"
                  component="div"
                  className="text-red-600"
                />
              </div>

              {/* Gender */}
              <div className="mb-2">
                <label
                  htmlFor="gender"
                  className="mb-2 block font-medium text-gray-800"
                >
                  Gender *
                </label>
                {!user?.personnelInfo ? (
                  <CustomCreatableSelect
                    field="gender" // Change field to "gender"
                    placeholder="Select gender"
                    options={[
                      { value: "male", label: "Male" },
                      { value: "female", label: "Female" },
                      { value: "other", label: "Other" },
                    ]}
                    setFieldValue={setFieldValue}
                    // defaultInputValue={initialValues.gender}
                    defaultValue={{
                      label: initialValues.gender,
                      value: initialValues.gender,
                    }}
                  />
                ) : (
                  <Field
                    onBlur={(e: any) => {
                      setFieldValue(e.target.name, e.target?.value?.trim());
                      handleBlur(e);
                    }}
                    disabled={user?.personnelInfo}
                    type="text"
                    name="gender"
                    id="gender"
                    placeholder="Full Name as per Adhaar Card"
                    className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                  />
                )}
                <ErrorMessage
                  name="gender"
                  component="div"
                  className="text-red-600"
                />
              </div>

              {/* Alternate mob no */}
              <div className="mb-2">
                <label
                  htmlFor="alternateMobNo"
                  className="mb-2 block font-medium text-gray-800"
                >
                  Alternate Mob. No.
                </label>
                <Field
                  onBlur={(e: any) => {
                    setFieldValue(e.target.name, e.target?.value?.trim());
                    handleBlur(e);
                  }}
                  disabled={user?.personnelInfo}
                  type="text"
                  maxLength={10}
                  name="alternateMobNo"
                  id="alternateMobNo"
                  placeholder="Enter Alternate Mob no."
                  className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                />
                <ErrorMessage
                  name="alternateMobNo"
                  component="div"
                  className="text-red-600"
                />
              </div>

              {/* Aadhaar No */}
              <div className=" mb-2 flex items-center justify-between">
                <div className="flex flex-1 flex-col">
                  <label
                    htmlFor="aadharNo"
                    className="mb-2 block font-medium text-gray-800"
                  >
                    Aadhar No. *
                  </label>
                  <div className="flex flex-1 items-center gap-2 sm-max:mb-1 md-max:flex-col">
                    <Field
                      onBlur={(e: any) => {
                        setFieldValue(e.target.name, e.target?.value?.trim());
                        handleBlur(e);
                      }}
                      disabled={user?.personnelInfo}
                      type="text"
                      name="aadharNo"
                      id="aadharNo"
                      maxLength="12"
                      placeholder="Enter Your Aadhar No"
                      className="w-full  rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                    />
                    {!user.aadharCardImg && (
                      <input
                        type="file"
                        name="aadharCardImg"
                        id="aadharCardImg"
                        className="md-max:w-full "
                        onChange={(event) => {
                          handleFileChange(
                            event,
                            setFieldValue,
                            "aadharCardImg"
                          );
                        }}
                        accept=".pdf"
                      />
                    )}
                    {/* Only show error if Aadhar card number is not empty and Aadhar card image is not selected */}
                  </div>

                  <ErrorMessage
                    name="aadharNo"
                    component="div"
                    className="text-red-600"
                  />
                </div>
              </div>

              {/* Gst */}
              <div className=" mb-2 flex items-center justify-between">
                <div className="flex flex-1 flex-col">
                  <label
                    htmlFor="gstCertificateNo"
                    className="mb-2 block font-medium text-gray-800"
                  >
                    Gst Certificate No.
                  </label>
                  <div className="flex flex-1 items-center gap-2 sm-max:mb-1 sm-max:flex-col">
                    <Field
                      onBlur={(e: any) => {
                        setFieldValue(e.target.name, e.target?.value?.trim());
                        handleBlur(e);
                      }}
                      disabled={user?.personnelInfo}
                      type="text"
                      name="gstCertificateNo"
                      id="gstCertificateNo"
                      maxLength="15"
                      placeholder="Enter Your Gst Certificate No"
                      className="w-full  rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                    />
                    {!user.gstCertificateImg && values.gstCertificateNo && (
                      <input
                        // ref={gstCertificateImgInput}
                        type="file"
                        id="gstCertificateImg"
                        name="gstCertificateImg"
                        className="md-max:w-full "
                        onChange={(event) => {
                          handleFileChange(
                            event,
                            setFieldValue,
                            "gstCertificateImg"
                          );
                        }}
                        accept=".pdf"
                      />
                    )}
                  </div>

                  <ErrorMessage
                    name="gstCertificateNo"
                    component="div"
                    className="text-red-600"
                  />
                </div>
              </div>

              {/* Pan No */}
              <div className=" mb-2 flex items-center justify-between">
                <div className="flex flex-1 flex-col">
                  <label
                    htmlFor="panNo"
                    className="mb-2 block font-medium text-gray-800"
                  >
                    Pan No. *
                  </label>
                  <div className="flex flex-1 items-center gap-2 sm-max:mb-4 sm-max:flex-col">
                    <Field
                      onBlur={(e: any) => {
                        setFieldValue(e.target.name, e.target?.value?.trim());
                        handleBlur(e);
                      }}
                      disabled={user?.personnelInfo}
                      type="text"
                      name="panNo"
                      id="panNo"
                      maxLength="12"
                      placeholder="Enter Your Pan No"
                      className="w-full  rounded-lg border-2 border-gray-200 p-2  uppercase hover:border-gray-500 focus:border-gray-500"
                    />
                    {!user.panCardImg && (
                      <input
                        // ref={panCardImgInput}
                        type="file"
                        name="panCardImg"
                        id="panCardImg"
                        className="md-max:w-full "
                        onChange={(event) => {
                          handleFileChange(event, setFieldValue, "panCardImg");
                        }}
                        accept=".pdf"
                      />
                    )}
                  </div>

                  <ErrorMessage
                    name="panNo"
                    component="div"
                    className="text-red-600"
                  />
                </div>
              </div>
            </div>
            {/* Permanent Address */}
            <AddressComponent
              user={user}
              addressFor={"Permanent"}
              type="personnelInfo"
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
            />

            {!user.otherDoc && !user?.personnelInfo && (
              <div className="light col-span-2 ">
                <UploadImgContainer
                  htmlFor="otherDoc"
                  file={values.otherDoc}
                  label1="Upload Other Documents(Merge into Single File)"
                  css="mb-5"
                  setFieldValue={setFieldValue}
                  field="otherDoc"
                />
              </div>
            )}
            {user?.personnelInfo && (
              <div className=" mt-2 ">
                <label className=" font-semibold">All Documents</label>
                <div className="ml-4  flex-wrap gap-4 sm:flex sm:flex-col md:flex-row">
                  {user.aadharCardImg && (
                    <DocWithDownloadButton
                      path={user.aadharCardImg?.path}
                      label="Aadhar Card"
                      name={user.aadharCardImg?.fileName}
                      type={user.aadharCardImg?.type}
                    />
                  )}
                  {user.panCardImg && (
                    <DocWithDownloadButton
                      path={user.panCardImg?.path}
                      label="Pan Card"
                      name={user.panCardImg?.fileName}
                      type={user.panCardImg?.type}
                    />
                  )}
                  {user.gstCertificateImg && (
                    <DocWithDownloadButton
                      path={user.gstCertificateImg?.path}
                      label="Gst Certificate"
                      name={user.gstCertificateImg?.fileName}
                      type={user.gstCertificateImg?.type}
                    />
                  )}
                  {user.idCardImg && (
                    <DocWithDownloadButton
                      path={user.idCardImg?.path}
                      label="Id Card"
                      name={user.idCardImg?.fileName}
                      type={user.idCardImg?.type}
                    />
                  )}
                  {user.otherDoc && (
                    <DocWithDownloadButton
                      path={user.otherDoc?.path}
                      label="Other Documents"
                      name={user.otherDoc?.fileName}
                      type={user.otherDoc?.type}
                    />
                  )}
                </div>
              </div>
            )}

            {/* Submit Button */}
            {!user?.personnelInfo && (
              <div className="float-end grid w-[40%] grid-cols-2 gap-3">
                <button
                  type="reset"
                  disabled={isSubmitting}
                  className="linear text-black mt-2 rounded-md bg-gray-300 py-[12px] text-base font-medium transition duration-200 hover:bg-gray-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                >
                  Clear
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="linear mt-2 rounded-md bg-primaryColor-dark py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                >
                  Submit
                </button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}
