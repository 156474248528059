import { ErrorMessage, Field, Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setToken, setUser } from "redux/reducers/authReducer";
import { LoginApi } from "services/appServices";
import { Role } from "views/customTypes/types";
import { errorToast, infoToast, successToast } from "views/toastConfig";
import * as Yup from "yup";
import Logo from "../../assets/img/logo.png";

export default function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // dispatch(setLoader(false));
  const locatation = useLocation();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  });

  const initialValues = {
    name: "",
    email: "",
    password: "",
  };

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      const res = await LoginApi(values);
      const { payload, message, error, statusCode } = res?.data || {};
      console.log("Sigin HandleSubmit res ==>", {
        loginRes: payload?.user?.email,
      });
      if (statusCode == 200) {
        if (payload.otpResponse?.statusCode !== 417) {
          if (
            locatation.pathname.split("/").pop() == "sign_in" &&
            [Role.ADMIN, Role.CLEARK, Role.FINANCE, Role.MANAGER].includes(
              payload?.user?.role
            )
          ) {
            infoToast("This page is only for user login.");
            return;
          }
          successToast("Login Successfull!");
          dispatch(setToken(payload.token));
          dispatch(setUser(payload.user));
          navigate("/admin", { replace: true });
        } else {
          console.log("Error while createUser =>", payload?.otpResponse);
          errorToast(payload.otpResponse.message);
        }
      } else {
        console.log("Error while Login =>", { error });
        errorToast(message);
      }
    } catch (error: any) {
      console.log("Error LoginApi ==>", { error });
      errorToast(
        error.message ||
          error.response?.data?.message ||
          "Something went wrong, please try again!"
      );
    }
    // Handle form submission here
    setSubmitting(false);
  };

  return (
    <div className="mx-auto w-[100%] rounded-md bg-white px-3 py-2 shadow-inner sm:max-w-[88%] sm:px-8 sm:py-6  md:max-w-[54%] xl:max-w-[30%] xl:px-10 xl:py-6 2xl:max-w-[30%] 2xl:py-8 3xl:max-w-[25%] 3xl:py-20">
      <div className={` flex items-center justify-center self-center`}>
        <img className="w-[40%] " src={Logo} alt="Logo" />
      </div>
      <h4 className=" text:xl mt-2 text-center font-bold  text-navy-700 dark:text-white md:text-3xl">
        Welcome to Quarter Allotment Portal
      </h4>
      <p className="mb-4 ml-1 text-center text-base text-gray-800"></p>
      <p className="mb-9 ml-1 text-center text-base text-primaryColor-dark ">
        {locatation.pathname.includes("admin") ? "Admin" : "Login to continue"}
      </p>

      <div className="self-center">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              {/* Email */}
              <div className="mb-5">
                <label
                  htmlFor="email"
                  className="mb-2 block font-medium text-gray-800"
                >
                  Email / Mobile / Customer Code *
                </label>
                <Field
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Enter your Email"
                  className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-600"
                />
              </div>

              {/* Password */}
              <div className="mb-5">
                <label
                  htmlFor="password"
                  className="mb-2 block font-medium text-gray-800"
                >
                  Password
                </label>
                <Field
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Min. 6 characters"
                  className="w-full rounded-lg border-2 border-gray-200 p-2 hover:border-gray-500 focus:border-gray-500"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-600"
                />
              </div>

              {/* Checkbox */}
              {/* <div className="mb-8 flex items-center justify-between px-2"> */}
              {/* <div className="flex items-center">
                    <Checkbox />
                    <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                      Remember me
                    </p>
                  </div> */}
              {/*//todo changed this to link */}
              {/* <label
                    // href=""
                    className="text-sm font-medium text-brand-300 hover:text-brand-600 dark:text-white"
                  >
                    Forgot Password?
                  </label> */}
              {/* </div> */}

              {/* Submit Button */}
              <button
                type="submit"
                disabled={isSubmitting}
                className="linear mb-4 mt-5 w-full rounded-xl bg-primaryColor-dark py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                Sign In
              </button>

              {/* Forgot Password Link */}
              <Link
                to="/auth/ForgotPassword" replace={true}
                className="float-end flex flex-1 self-center text-right text-sm font-medium text-brand-300 hover:text-brand-600 dark:text-white"
              >
                Forgot Password?
              </Link>

              {/* Already have an Account */}
              <div className="mt-4 flex w-full flex-1 flex-col  pt-2 text-center">
                <span className="text-sm font-medium text-navy-700 dark:text-gray-600">
                  Don’t have a Account?
                </span>
                {/* Using Link component */}
                <Link
                  to="/auth/sign_up" replace={true}
                  className="ml-1 text-sm font-medium text-brand-300 hover:text-brand-600 dark:text-white"
                >
                  Sign up
                </Link>
                {!locatation.pathname.includes("admin") ? (
                  <Link
                    to="/auth/admin_sign_in"
                    className="ml-1 block pt-1 text-sm font-medium text-brand-300 hover:text-brand-600 dark:text-white" replace={true}
                  >
                    Admin Sign In
                  </Link>
                ) : (
                  <Link
                    to="/auth/sign_in"
                    className="ml-1 block text-sm font-medium text-brand-300 hover:text-brand-600 dark:text-white" replace={true}
                  >
                    Back
                  </Link>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
