import { Form, Formik, FormikHelpers } from "formik";
import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";

import Chat from "components/custom/Chat";
import { getCountForAllAdminPages } from "helper/helperFunctions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  allotmentRequestAction,
  generateCustomerCode,
  getSettingData,
  getSuggestionBasedRentalUnit,
  uploadFileIntoAllotementRequest,
} from "services/appServices";
import { errorToast, infoToast, successToast } from "views/toastConfig";
import { ResourceStatus, Role, STATUS } from "../../customTypes/types";
import moment from "moment";
import { ButtonsSec } from "./components/ButtonsSec";
import { RemarksSec } from "./components/RemarkSec";
import { DocSec } from "./components/DocSec";
import { ChargesSec } from "./components/ChargesSec";
import { FormFieldsSec } from "./components/FormFieldsSec";
import { MemoizedPersonnelandPrevResourceInfoSec } from "./components/PesonnelAndResourceInfoSec";
const RequestDetails = ({
  rowData = {},
  backFuction,
  refreshFunction,

  setRefresh,
  setRowData,
}: any) => {
  const user = useSelector((store: any) => store.auth.user);
  const { userRole } = useSelector((store: any) => store.appSlice);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pathParts = location.pathname.split("/");
  const [pageType, setPageType] = useState(pathParts[pathParts.length - 1]);
  const validationSchema = useRef<any>();
  const [initialDynamicFields, setInitialDynamicFields] = useState<any>({});
  const [rentalUnit, setRentalUnit] = useState("");
  const [suggestion, setSuggestion] = useState<string[]>([]);
  const [isChatVisible, setIsChatVisible] = useState<boolean>(false);
  const resourceType = useRef(
    rowData?.resourceType || rowData?.resourceDetails?.resourceType
  );
  const [settingdata, setSettingData] = useState<any>({});
  const [isStandardCharges, setIsStandardCharges] = useState<boolean>(
    !rowData.isCustomCharge
  );

  const getSuggetionData = async (filter: any) => {
    try {
      console.log("get Suggestion called ==>", { pageType });
      const resp = await getSuggestionBasedRentalUnit(filter);
      if (resp.data.uniqueRentalUnits) {
        const addSlashAfterFirstChar = resp.data.uniqueRentalUnits.map(
          (str: string) => {
            if (str.length > 1) {
              return str[0] + "/" + str.slice(1);
            }
            return str;
          }
        );
        console.log({ addSlashAfterFirstChar });
        //* buildingType/sectorNumber/buildingNumber/roomNo
        setSuggestion(addSlashAfterFirstChar);
      } else {
        // todo need to throw error here
        console.log(resp.data);
      }
    } catch (error) {
      console.log("Error getSuggestionData RequestDetails ==>", { error });
    }
  };

  const getCharges = async (filter: any) => {
    try {
      console.log("getCharges Called ==>");
      const settingDataRes = await getSettingData(filter, { createdAt: -1 });
      const { payload, message, error, statusCode } =
        settingDataRes?.data || {};
      console.log({ settingDataRes });
      if (statusCode == 200) {
        if (payload) {
          setSettingData(payload);
        } else {
          infoToast(
            "Not Data Found,Please add Charges for the current Selected Resource in Setting > Charges!"
          );
        }
      } else {
        console.log({ error });
        errorToast(message);
      }
    } catch (error: any) {
      errorToast(
        error.response?.data?.message ||
          error.message ||
          "Something went wrong!"
      );
    }
  };

  const generateCode = async () => {
    try {
      const res = await generateCustomerCode({
        userCustomerCode:
          rowData.userData.customerCodes?.[resourceType.current],
        name: rowData.userData.name,
        customerCode: rowData.customerCode,
        allotmentRequestId: rowData._id,
        uid: rowData.userData._id,
        resourceType: resourceType.current,
      });
      const { payload, message, error, statusCode } = res?.data || {};

      if (statusCode == 201) {
        setRowData((prev: any) => ({
          ...prev,
          customerCode: payload.customerCode,
        }));
        setRefresh((prev: boolean) => !prev);
      } else {
        console.log({ error });
        errorToast(message);
      }
    } catch (error) {
      console.log("Errro generateCode", { error });
      errorToast("Something went wrong while generating Cutomer Code !");
    }
  };

  const uploadFile = async (file: any, fieldName: string) => {
    try {
      const formData = new FormData();
      formData.append("file", file, `${fieldName}_${user._id}_${file.name}`);
      formData.append("date", new Date().toISOString());
      formData.append("allotementRequestId", rowData._id);
      formData.append("role", userRole);
      formData.append("name", user.name);
      formData.append("_id", user._id);
      formData.append("fieldName", fieldName);
      formData.append("fileName", file.name);

      console.log({ formData, file });

      const resUploadFile = await uploadFileIntoAllotementRequest(formData);
      if (
        resUploadFile?.data?.allotmentLetter ||
        resUploadFile?.data?.bankReceipt ||
        resUploadFile?.data?.finalPaymentReceipt
      ) {
        setRowData((prev: any) => ({ ...prev, ...resUploadFile.data }));
        setRefresh((prev: boolean) => !prev);

        console.log({ resUploadFile });
      } else {
        throw new Error(resUploadFile?.data?.message);
      }
    } catch (error: any) {
      console.log({ error });
      errorToast(
        error.response?.data?.message ||
          error.message ||
          "Something went wrong!"
      );
    }
  };

  const validation = async (buttonType: STATUS, values: any) => {
    console.log({ buttonType });
    if (
      rowData.allotmentLetter?.length == 0 &&
      buttonType == STATUS.APPROVED &&
      userRole == Role.MANAGER
    ) {
      errorToast("Please Upload Allotement Letter!");
      return false;
    }

    if (
      rowData.bankReceipt?.length == 0 &&
      buttonType == STATUS.VERIFIED &&
      userRole == Role.FINANCE
    ) {
      errorToast("Please Upload Bank Receipt!");
      return false;
    }

    if (
      rowData.vacationReport?.length == 0 &&
      buttonType == STATUS.APPROVED &&
      userRole == Role.FINANCE &&
      pageType == "vacant_resource_request"
    ) {
      errorToast("Please Upload Vacation Report!");
      return false;
    }

    if (
      pageType == "manager_approved_requests" &&
      (!rowData.paymentReferenceDetails ||
        rowData.paymentReferenceDetails?.length == 0) &&
      buttonType == STATUS.VERIFICATION_PENDING &&
      userRole == Role.CLEARK
    ) {
      infoToast("Please wait for the user to upload the payment Documents.");
      return false;
    }

    if (
      pageType == "finance_Approved_request" &&
      !values.dateOfPossession &&
      userRole == Role.CLEARK &&
      buttonType == STATUS.APPROVED
    ) {
      infoToast("Please Select Date of Possession");
      return false;
    }

    if (
      rowData.finalPaymentReceipt?.length == 0 &&
      buttonType == STATUS.APPROVED &&
      userRole == Role.FINANCE
    ) {
      errorToast("Please Upload Final Payment Receipt!");
      return false;
    }

    let dynamicValidationSchema = validationSchema.current;

    if (
      [
        STATUS.REJECTED,
        STATUS.VERIFICATION_PENDING,
        STATUS.CLEARIFY,
        STATUS.NOT_VERIFIED,
      ]?.includes(buttonType) ||
      ["clearification_pending"].includes(pageType)
    ) {
      dynamicValidationSchema = Yup.object().shape({
        reason: Yup.string().required("Reason is required"),
      });
    } else if (pageType == "payment_verification_approved") {
      dynamicValidationSchema = Yup.object().shape({
        customerCode: Yup.string().required("Customer Code is required"),
      });
    } else {
      dynamicValidationSchema = Yup.object().shape({
        rent:
          !isStandardCharges &&
          pageType == "request_for_resources" &&
          userRole == Role.CLEARK &&
          Yup.number()
            .typeError("Rent Charges must be a number")
            .required(`Rent is required`)
            .min(0, `Rent must be a non-negative value`),
        maintenance:
          !isStandardCharges &&
          pageType == "request_for_resources" &&
          userRole == Role.CLEARK &&
          Yup.number()
            .typeError("Maintenance must be a number")
            .required(`Maintenance is required`)
            .min(0, `Maintenance must be a non-negative value`),
        waterCharges:
          !isStandardCharges &&
          pageType == "request_for_resources" &&
          userRole == Role.CLEARK &&
          Yup.number()
            .typeError("Water Charges must be a number")
            .required(`Water Charges are required`)
            .min(0, `Water Charges must be a non-negative value`),
        ...Object.keys(initialDynamicFields || {}).reduce(
          (schema: any, field) => {
            if (
              field === "roomNo" &&
              initialDynamicFields["buildingType"] === "D"
            ) {
              schema[field] = Yup.string();
            } else {
              schema[field] = Yup.string().required(`${field} is required`);
            }

            return schema;
          },

          {} as { [key: string]: Yup.StringSchema }
        ),
      });
    }
    await dynamicValidationSchema?.validate(values, { abortEarly: false });
    return true;
  };

  const handleSubmit = async (
    values: any,
    { setErrors }: FormikHelpers<any>
  ) => {
    try {
      console.log(
        "handleSubmit called==>",
        { initialDynamicFields },
        { values }
      );

      // return;

      //* Determine the status based on the button clicked
      const clickedButton = document.activeElement as HTMLButtonElement;
      const buttonType: any = clickedButton.getAttribute("data-type");
      if (!buttonType) return;
      if (!(await validation(buttonType, values))) {
        return;
      }
      if (initialDynamicFields.buildingType != "D") {
        initialDynamicFields["rentalUnit"] = rentalUnit;
      }

      const allotedReqData: any = {
        pageType,
        action: buttonType,
        _id: rowData._id, //allotment req _id
        resource_id: rowData.resourceId,
        resourceType: rowData.resourceType,
        approvalName: `${user.name}`,
        role: userRole,
        approvalId: user._id,
        reason: values.reason,
        ...([
          "change_resource_request",
          "request_for_resources",
          "renewal_requests",
          "clearification_pending",
        ].includes(pageType) &&
          userRole === Role.CLEARK && {
            rent: !isStandardCharges ? Number(values.rent) : settingdata.rent,
            waterCharges: !isStandardCharges
              ? Number(values.waterCharges)
              : settingdata.waterCharges,
            maintenance: !isStandardCharges
              ? Number(values.maintenance)
              : settingdata.maintenance,
            isCustomCharge: !isStandardCharges,
          }),
        ...(values.customerCode && { customerCode: values.customerCode }),
        ...(values.dateOfPossession && {
          dateOfPossession: values.dateOfPossession,
          possessionEndDate: moment(values.dateOfPossession)
            .add(10, "months")
            .endOf("month")
            .toDate(),
          update: true,
        }),
        ...(userRole == Role.CLEARK &&
          (pageType == "payment_verification_approved"
            ? {
                finance: {
                  status: STATUS.PENDING,
                  // reviewBy: {
                  //   name: user.name,
                  //   role: userRole,
                  //   date: new Date(),
                  //   id: user._id,
                  //   reason: values.reason,
                  // },
                },
              }
            : pageType == "finance_Approved_request"
            ? {
                payment: {
                  status: buttonType,
                  reviewBy: {
                    name: user.name,
                    role: userRole,
                    date: new Date(),
                    id: user._id,
                    reason: values.reason,
                  },
                },
                requestType: rowData.requestType,
                uid: rowData.uid,
                prevResourceDetails: rowData.prevResourceDetails, //? this is for changeResourecRequest
              }
            : pageType == "clearification_pending"
            ? {
                mangerStatus: STATUS.PENDING,
              }
            : (pageType == "manager_approved_requests" ||
                pageType == "payment_verification_rejected") &&
              buttonType == STATUS.VERIFICATION_PENDING && {
                payment: {
                  status: buttonType,
                  reviewBy: {
                    name: user.name,
                    role: userRole,
                    date: new Date(),
                    id: user._id,
                    reason: values.reason,
                  },
                },
              })),

        ...(buttonType == STATUS.APPROVED &&
          userRole == Role.MANAGER && {
            payment: {
              status: STATUS.PENDING,
              reviewBy: {
                name: user.name,
                role: userRole,
                date: new Date(),
              },
            },
          }),
        ...(userRole == Role.FINANCE &&
        (buttonType == STATUS.NOT_VERIFIED || buttonType == STATUS.VERIFIED) &&
        pageType == "payment_verification_pending"
          ? {
              payment: {
                status: buttonType,
                reviewBy: {
                  name: user.name,
                  role: userRole,
                  date: new Date(),
                  id: user._id,
                  reason: values.reason,
                },
              },
              rowData,
            }
          : {}),

        initialDynamicFields,
      };

      console.log({ allotedReqData });
      const res = await allotmentRequestAction(allotedReqData);
      const { payload, message, error, statusCode } = res?.data || {};

      console.log({ allotmentRequestActionRes: payload });

      if (statusCode == 200) {
        successToast(message);
        refreshFunction();
        getCountForAllAdminPages(userRole, dispatch);

        pageType == "request_for_resources" &&
        buttonType == STATUS.VERIFICATION_PENDING
          ? navigate("/admin/verification_pending")
          : backFuction(false);
      } else {
        console.log({ error });
        errorToast(message);
      }
    } catch (error: any) {
      if (error.name === "ValidationError") {
        const validationErrors: { [key: string]: string } = {};
        error.inner.forEach((err: any) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      } else {
        errorToast(
          error.response?.data?.message ||
            error.message ||
            "Something wnet wrong!"
        );
      }
    }
  };

  //*important
  useEffect(() => {
    if (
      [
        "request_for_resources",
        "clearification_pending",
        "change_resource_request",
      ]?.includes(pageType) &&
      userRole == Role.CLEARK
    ) {
      setInitialDynamicFields({
        buildingType:
          rowData.resourceData?.buildingType ||
          rowData?.resourceDetails?.buildingType,
        sectorNumber:
          rowData.resourceData?.sectorNumber ||
          rowData?.resourceDetails?.sectorNumber ||
          "",
        buildingNumber:
          rowData.resourceData?.buildingNumber ||
          rowData?.resourceDetails?.buildingNumber ||
          "",
        ...((rowData?.resourceDetails?.buildingType != "D" ||
          (initialDynamicFields.buildingType &&
            initialDynamicFields.buildingType != "D")) && {
          roomNo:
            rowData.resourceData?.roomNo ||
            rowData?.resourceDetails?.roomNo ||
            "",
        }),
      });
      console.log("inside if");
    } else {
      setInitialDynamicFields(
        Object.entries(rowData?.resourceData || [])
          .filter(
            ([key, value]: [string, any]) =>
              ![
                "_id",
                "updatedAt",
                "createdAt",
                "From Date",
                "status",
                "resourceType",
                "remark",
                "rentalUnit",
              ]?.includes(key) && value !== "n/a"
          )
          .reduce((acc: any, [key, value]: [string, any]) => {
            acc[key] = value;
            return acc;
          }, {})
      );
    }

    console.log({ pageType, rowData });
    if (
      rowData?.conversation?.length > 0 &&
      [
        "request_for_resources",
        "vacant_resource_request",
        "payment_verification_rejected",
        "change_resource_request",
        "clearification_pending",
      ].includes(pageType) &&
      (userRole == Role.USER || userRole == Role.CLEARK)
    ) {
      setIsChatVisible(true);
    }
  }, [rowData]);

  useEffect(() => {
    setRentalUnit(
      `${initialDynamicFields?.buildingType || ""}${
        initialDynamicFields?.sectorNumber || ""
      }/${initialDynamicFields?.buildingNumber || ""}${
        initialDynamicFields?.roomNo ? "/" + initialDynamicFields?.roomNo : ""
      }`
    );
  }, [initialDynamicFields]);

  useEffect(() => {
    //* this is for page ==> [Request for resources,]

    if (
      [
        "request_for_resources",
        "verification_pending",
        "clearification_pending",
        "change_resource_request",
      ]?.includes(pageType) &&
      [Role.CLEARK]?.includes(userRole)
    ) {
      getSuggetionData({
        status: ResourceStatus.VACANT,
        // "fields.buildingType": rowData.resourceDetails?.buildingType,
      });
    }
    if (
      location.pathname?.includes("all_resources") ||
      location.pathname?.includes("alloted_resources")
    ) {
      setPageType(pathParts[pathParts.length - 2]);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (
      Object.keys(initialDynamicFields).length != 0 &&
      initialDynamicFields.buildingType !== "D" &&
      !initialDynamicFields.hasOwnProperty("roomNo")
    ) {
      setInitialDynamicFields((prev: any) => {
        return { ...prev, roomNo: prev.roomNo || "" };
      });
    }
  }, [initialDynamicFields.buildingType]);

  useEffect(() => {
    if (
      [
        "request_for_resources",
        "renewal_requests",
        "change_resource_request",
        "clearification_pending",
      ].includes(pageType) &&
      userRole == Role.CLEARK &&
      isStandardCharges &&
      initialDynamicFields.buildingType
      // || true
    ) {
      //todo called twice fix this
      let userType = rowData.userData.type;
      const chargesType = [
        "jnpaEmployee",
        "otherGovtEmployee",
        "retiredJnpaEmployee",
      ].includes(userType)
        ? "government"
        : userType == "other"
        ? "private"
        : userType;
      if (!(chargesType == "private" || chargesType == "government")) {
        errorToast(`Invalid User Type ${userType}`);
      }
      getCharges({
        "resourceDetails.buildingType": initialDynamicFields.buildingType,
        chargesType,
      });
    }
  }, [isStandardCharges, initialDynamicFields.buildingType]);

  return (
    <Formik
      initialValues={{
        ...initialDynamicFields,
        rent: rowData.rent,
        waterCharges: rowData.waterCharges,
        maintenance: rowData.maintenance,
        reason: "",
        customerCode: rowData.customerCode || "",
        dateOfPossession: rowData.dateOfPossession,
      }}
      validationSchema={validationSchema.current}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ errors, touched, setFieldValue, values, handleBlur }) => (
        <Form className="flex max-w-full flex-1">
          <div className="flex flex-1 flex-col justify-between overflow-auto">
            <div>
              <MemoizedPersonnelandPrevResourceInfoSec
                resourceType={resourceType.current}
                rowData={rowData}
              />
              <FormFieldsSec
                rowData={rowData}
                pageType={pageType}
                setFieldValue={setFieldValue}
                generateCode={generateCode}
                initialDynamicFields={initialDynamicFields}
                rentalUnit={rentalUnit}
                role={userRole}
                setInitialDynamicFields={setInitialDynamicFields}
                values={values}
                suggestion={suggestion}
                setIsStandardCharges={setIsStandardCharges}
              />
              <DocSec
                pageType={pageType}
                rowData={rowData}
                uploadFileFunction={uploadFile}
                userRole={userRole}
                pathname={location.pathname}
              />

              <ChargesSec
                rowData={rowData}
                settingdata={settingdata}
                handleBlur={handleBlur}
                isStandardCharges={isStandardCharges}
                setFieldValue={setFieldValue}
                pageType={pageType}
                setIsStandardCharges={setIsStandardCharges}
                role={userRole}
              />

              <RemarksSec userRole={userRole} pageType={pageType} rowData={rowData} />
              {/* //* Chat Section */}
              {isChatVisible && (
                <Chat user={user} allotementRequestId={rowData._id} userRole={userRole} />
              )}
            </div>

            <ButtonsSec
              userRole={userRole}
              pageType={pageType}
              isChatVisible={isChatVisible}
              setIsChatVisible={setIsChatVisible}
              backFuction={backFuction}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default RequestDetails;
