import Checkbox from "components/checkbox";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { createUserApi } from "services/appServices";
import { errorToast, infoToast, successToast } from "views/toastConfig";
import * as Yup from "yup";
import Logo from "../../assets/img/logo.png";
import { HttpStatusCode } from "axios";

export default function SignIn() {
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    cnfpassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    mobNo: Yup.string()
      .matches(/^[0-9]{10}$/, "Invalid mobile number")
      .required("Mobile number is required"),
  });

  const initialValues = {
    email: "",
    password: "",
    cnfpassword: "",
    mobNo: "",
  };

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      const res = await createUserApi(values);
      const { payload, message, error, statusCode } = res?.data || {};
      if (statusCode == HttpStatusCode.Created) {
        if (payload.otpResponse?.statusCode == HttpStatusCode.Ok) {
          successToast(message);
          navigate("/auth/otp_screen", {
            state: {
              email: payload.user.email,
              uid: payload.user._id,
            },
          });
        } else {
          console.log("Error in otpResponse  ==>", error);
          infoToast(message);
          errorToast(payload.otpResponse.message);
        }
      } else {
        console.log({ error });
        errorToast(message);
      }
      setSubmitting(false);
    } catch (error: any) {
      console.log("Error CreateUser ==>", { error });
      errorToast(
        error.response?.data?.message ||
          error.message ||
          "Something went wrong!"
      );
    }
  };

  return (
    <div className="mx-auto w-[100%] rounded-md bg-white px-3 py-2 shadow-inner sm:max-w-[88%] sm:px-8 sm:py-6  md:max-w-[54%] xl:max-w-[30%] xl:px-10 xl:py-6 2xl:max-w-[30%] 2xl:py-8 3xl:max-w-[25%] 3xl:py-20">
      <div className="flex justify-center">
        <img
          className=" -mt-2 mb-2 max-w-[15rem] rounded-lg px-4"
          src={Logo}
          alt="Logo"
        />
      </div>
      <h4 className="text-center text-[1.5em] font-bold text-navy-700 dark:text-white md:text-4xl">
        Welcome to JNPA
      </h4>
      <p className="mb-4 text-center text-base text-primaryColor-dark">
        Sign up to continue
      </p>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="space-y-4">
            <div>
              <label
                htmlFor="email"
                className="block font-medium text-gray-800"
              >
                Email *
              </label>
              <Field
                type="text"
                name="email"
                id="email"
                placeholder="Enter your Email"
                className="min-h-[2.5em] w-full rounded-lg border-2 border-gray-200 px-4 hover:border-gray-500  focus:border-gray-500 md:min-h-[3.2em] xl:min-h-[3.5em]"
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-red-600"
              />
            </div>

            <div>
              <label
                htmlFor="mobNo"
                className="block font-medium text-gray-800"
              >
                Phone No. *
              </label>
              <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                  +91
                </span>
                <Field
                  type="text"
                  name="mobNo"
                  id="mobNo"
                  maxLength="10"
                  placeholder="Enter your Phone No."
                  className="min-h-[2.5em] w-full rounded-lg border-2 border-gray-200 px-4 pl-8   hover:border-gray-500 focus:border-gray-500  md:min-h-[3.2em] xl:min-h-[3.5em]"
                />
              </div>
              <ErrorMessage
                name="mobNo"
                component="div"
                className="text-red-600"
              />
            </div>

            <div>
              <label
                htmlFor="password"
                className="block font-medium text-gray-800"
              >
                Password *
              </label>
              <Field
                type="password"
                name="password"
                id="password"
                placeholder="Min. 6 characters"
                className="min-h-[2.5em] w-full rounded-lg border-2 border-gray-200 px-4 hover:border-gray-500 focus:border-gray-500  md:min-h-[3.2em] xl:min-h-[3.5em]"
              />
              <ErrorMessage
                name="password"
                component="div"
                className="text-red-600"
              />
            </div>

            <div>
              <label
                htmlFor="cnfpassword"
                className="block font-medium text-gray-800"
              >
                Confirm Password *
              </label>
              <Field
                type="password"
                name="cnfpassword"
                id="cnfpassword"
                placeholder="Min. 6 characters"
                className="min-h-[2.5em] w-full rounded-lg border-2 border-gray-200 px-4 hover:border-gray-500  focus:border-gray-500 md:min-h-[3.2em] xl:min-h-[3.5em]"
              />
              <ErrorMessage
                name="cnfpassword"
                component="div"
                className="text-red-600"
              />
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="min-h-[2.5em] w-full rounded-xl bg-primaryColor-dark text-base font-medium  text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:min-h-[3.2em] xl:min-h-[3.5em]"
            >
              Sign Up
            </button>

            <div className="text-center">
              <span className="text-sm font-medium text-navy-700 dark:text-gray-600">
                Already have an Account?
              </span>
              <Link
                to="/auth/sign_in"
                className="ml-1 text-sm font-medium text-brand-300 hover:text-brand-600 dark:text-white" replace={true}
              >
                Log in
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
