import { DocWithDownloadButton } from "components/custom/DocWithDownloadButton";
import { UploadDocWithUploadButton } from "components/custom/UploadDocWithUploadButton";
import { downloadFilePdfFromHtml } from "helper/helperFunctions";
import { Role } from "views/customTypes/types";

export const DocSec = ({
  rowData,
  userRole,
  pageType,
  uploadFileFunction,
  pathname,
}: {
  rowData: any;
userRole : string;
  pageType: any;
  uploadFileFunction: any;
  pathname: any;
}) => {
  // console.log("doc sec rerenderd!");
  return (
    <div className="mt-6 md:grid md:grid-cols-4 md:gap-y-6">
      {!pathname?.includes("all_resources") && (
        <div className="my-4 flex flex-col flex-wrap gap-4 space-y-3 md:col-span-4 md:flex-row md:space-x-4 md:space-y-0">
          {rowData?.aadharCardImg?.path && (
            //@AadharCard Doc
            <DocWithDownloadButton
              path={rowData?.aadharCardImg.path}
              label="Aadhar Card"
              name={rowData?.aadharCardImg?.fileName}
              type={rowData?.aadharCardImg?.type}
            />
          )}
          {rowData?.panCardImg?.path && (
            //@PanCard Doc
            <DocWithDownloadButton
              path={rowData?.panCardImg.path}
              label="Pan Card"
              name={rowData?.panCardImg?.fileName}
              type={rowData?.panCardImg?.type}
            />
          )}
          {rowData?.idCardImg?.path && (
            //@Payment Doc
            <DocWithDownloadButton
              path={rowData?.idCardImg?.path}
              label="Id Card"
              name={rowData?.idCardImg?.fileName}
              type={rowData?.idCardImg?.type}
            />
          )}
          {rowData?.userData?.otherDoc?.path && (
            //@Payment Doc
            <DocWithDownloadButton
              path={rowData?.userData?.otherDoc?.path}
              label="Other User's Documents"
              name={rowData?.userData?.otherDoc?.fileName}
              type={rowData?.userData?.otherDoc?.type}
            />
          )}
          {/* //@Proforma letter  */}
          <DocWithDownloadButton
            path={rowData?.requestLetter?.[0]?.path}
            label="Proforma document"
            name={"Document"}
            // type={userAllotmentRequest?.requestLetter?.[0]?.type}
            generate={true}
            generateFunction={() => {
              downloadFilePdfFromHtml(
                {
                  //!check this vvviiimmm
                  // ...user,
                  ...rowData,
                  docType: "proforma",
                },
                "Proforma.pdf"
              );
            }}
          />
          {/* //@organization Request letter  */}
          {rowData.requestLetter?.length > 0 && (
            <DocWithDownloadButton
              path={rowData?.requestLetter?.[0]?.path}
              label="Organization's Request Letter"
              name={rowData?.requestLetter?.[0]?.fileName}
              type={rowData?.requestLetter?.[0]?.type}
            />
          )}
          {rowData?.otherDoc?.[0]?.path && (
            //@Other Allotment Request Documents
            <DocWithDownloadButton
              path={rowData?.otherDoc?.[0]?.path}
              label="Other Allotment Request Documents"
              name={rowData?.otherDoc?.[0]?.fileName}
              type={rowData?.otherDoc?.[0]?.type}
            />
          )}
          {/* //@allotemnetDoc1 */}
          {userRole == Role.MANAGER &&
            pageType == "request_for_resources" &&
            !rowData.allotmentLetter.length && (
              <UploadDocWithUploadButton
                uploadFileFunction={uploadFileFunction}
                inputId="allotemnetDoc1"
                label="Allotment Letter"
                field="allotmentLetter"
                generate={true}
                generateLabel="Genertate Allotment Letter"
                generateFunction={() => {
                  downloadFilePdfFromHtml(
                    {
                      ...rowData,
                      docType: "allotementLetter",
                    },
                    "allotementLetter"
                  );
                }}
              />
            )}
          {rowData.allotmentLetter?.length > 0 && (
            <DocWithDownloadButton
              path={rowData.allotmentLetter?.[0]?.path}
              label="Allotment Letter"
              name={rowData.allotmentLetter?.[0]?.fileName}
              type={rowData.allotmentLetter?.[0]?.type}
            />
          )}
          {/* //@Payment Doc */}
          {rowData.paymentReferenceDetails?.length > 0 && (
            <DocWithDownloadButton
              path={
                rowData.paymentReferenceDetails[
                  rowData.paymentReferenceDetails.length - 1 || 0
                ]?.path
              }
              label="Payment Reference Details"
              name={
                rowData?.paymentReferenceDetails[
                  rowData.paymentReferenceDetails.length - 1 || 0
                ]?.fileName
              }
              type={
                rowData?.paymentReferenceDetails[
                  rowData?.paymentReferenceDetails.length - 1 || 0
                ]?.type
              }
            />
          )}
          {/* //@payment_verification_pending */}
          {userRole == Role.FINANCE &&
            pageType == "payment_verification_pending" &&
            !rowData.bankReceipt?.length && (
              <UploadDocWithUploadButton
                uploadFileFunction={uploadFileFunction}
                inputId="bankReceipt"
                label="Bank Receipt"
                field="bankReceipt"
              />
            )}
          {rowData.bankReceipt?.length > 0 && (
            //@payment_verification_pending
            <DocWithDownloadButton
              path={rowData.bankReceipt?.[0]?.path}
              label="Bank Receipt"
              name={rowData.bankReceipt?.[0]?.fileName}
              type={rowData.bankReceipt?.[0]?.type}
            />
          )}
          {/* //@Final Payment Receipt */}
          {userRole == Role.FINANCE &&
            pageType == "finance_approval_pending" &&
            !rowData.finalPaymentReceipt.length && (
              <UploadDocWithUploadButton
                uploadFileFunction={uploadFileFunction}
                inputId="finalPaymentReceipt"
                label="Final Payment Receipt"
                field="finalPaymentReceipt"
              />
            )}
          {rowData.finalPaymentReceipt?.length > 0 && (
            <DocWithDownloadButton
              path={rowData.finalPaymentReceipt?.[0]?.path}
              label="Final Payment Receipt"
              name={rowData.finalPaymentReceipt?.[0]?.fileName}
              type={rowData.finalPaymentReceipt?.[0]?.type}
            />
          )}
          {/* //@vacationReport */}
          {userRole == Role.FINANCE &&
            pageType == "vacant_resource_request" &&
            !rowData.vacationReport.length && (
              <UploadDocWithUploadButton
                uploadFileFunction={uploadFileFunction}
                inputId="vacationReport"
                label="Vacation Report"
                field="vacationReport"
              />
            )}
          {rowData.vacationReport?.length > 0 && (
            <DocWithDownloadButton
              path={rowData.vacationReport?.[0]?.path}
              label="Vacation Report"
              name={rowData.vacationReport?.[0]?.fileName}
              type={rowData.vacationReport?.[0]?.type}
            />
          )}
        </div>
      )}
    </div>
  );
};

