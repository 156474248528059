import { OtpInput } from "components/custom/OtpInput";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setUser } from "redux/reducers/authReducer";
import { sendOtp, validateOtp } from "services/appServices";
import { errorToast, infoToast, successToast } from "views/toastConfig";
import Logo from "../../assets/img/logo.png";

export const OtpScreen: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [emailOtp, setEmailOtp] = useState("");
  const { user, token } = useSelector((store: any) => store.auth);
  const [resendTimer, setResendTimer] = useState(60);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (state?.type == "forgotPassword") {
      infoToast("Enter Otp to Change Password!");
    } else {
      infoToast("Enter Otp to verfy Email!");
    }
    console.log("Otp Screen UseEffect ==>", { state });
    if (state?.type != "forgotPassword" && token && user?.emailverifeid) {
      navigate(state?.from || "/", { replace: true });
    }
  }, [token]);



  // console.log("otp", emailOtp);
  // console.log("user", user);
  
  useEffect(() => {
    let timerInterval: NodeJS.Timeout;
    if (isResendDisabled && resendTimer > 0) {
      timerInterval = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    } else if (resendTimer === 0) {
      setIsResendDisabled(false);
      setResendTimer(60); // Reset timer for next use
    }
    return () => clearInterval(timerInterval);
  }, [isResendDisabled, resendTimer]);
  //
  const getEmailOtp = async () => {
    try {
      console.log("getEmailOtp called ==>");
      const otpRes = await sendOtp({
        uid: state?.uid || user?._id,
        email: state?.email || user?.email,
        type: state?.type,
      });
      console.log({ otpRes });
      if (otpRes?.data && !otpRes?.data?.error) {
        console.log("got it ==>", otpRes.data.otp);

        successToast(otpRes.data.message);
        setIsResendDisabled(true);
        setResendTimer(60);
      } else throw new Error(otpRes.data.message);
    } catch (error) {
      console.log({ error });
      errorToast("Failed to send OTP, please try again.");
    }
  };

  const handleOtpEmail = async (otp: string) => {
    try {
      console.log("handleOpEmail called ==>", { user, state });
      if (user?._id || state?.uid || state?.email) {
        const validateOtpRes = await validateOtp({
          uid: state?.uid || user?._id,
          otp,
          email: state?.email || user?.email,
          type: state?.type,
          data: { newPassword: state?.newPassword },
        });
        if (validateOtpRes?.data && !validateOtpRes.data.error) {
          dispatch(setUser(validateOtpRes.data.user));
          infoToast(validateOtpRes.data.message);

          if (state?.type == "forgotPassword") {
            navigate("/auth/sign_in", { replace: true });
          } else {
            navigate("/admin", { replace: true });
            // setTimeout(() => {
            //   successToast("SignUp Succesfully!");
            // }, 500);
          }
        } else {
          throw new Error(validateOtpRes.data?.message);
        }
        console.log("handleOtpEmail", { otp, validateOtpRes, user });
      }
    } catch (error: any) {
      errorToast(
        error.message ||
          error.response?.data?.message ||
          "Something went wrong, please try again!"
      );
    }
  };

  const handleOtpMob = (otp: string) => {
    successToast("Otp Verified Successfully!");
    navigate("/auth/sign_in");
    console.log("Completed OTP:", otp);
  };

  return (
    <div className="w-auto items-center rounded-md  bg-white  px-2 py-10 xsm:w-[25rem] xsm:px-2 sm:w-[30rem] md:px-10 xl:max-w-[28rem] xl:p-10">
      <div className={` mt-2 flex items-center justify-center self-center`}>
        <img className="w-[35%] bg-primaryColor-dark" src={Logo} alt="Logo" />
      </div>
      <div className="flex flex-col items-center">
        <label className="text-2xl font-bold">Verification</label>
        <div className="mt-10 flex flex-col items-center gap-y-2">
          <label>
          Enter the verification OTP sent to your Email
          </label>
            <label className="font-semibold">{ state?.email || user?.email}</label>
          <div className="mt-2">
            {isResendDisabled ? (
              <span>Resend in {resendTimer} seconds</span>
            ) : (
              <button onClick={getEmailOtp} className="font-bold text-blue-500">
                Resend OTP
              </button>
            )}
          </div>
        </div>
        {/* <div className="flex flex-col items-center gap-2">
            <label className="mt-10">
              Enter the verification OTP sent to your{" "}
              <label className="font-bold">Phone</label>.
            </label>
            <OtpInput
              length={6}
              inputStyle="w-12 h-12 text-xl"
              containerStyle="space-x-4"
              onComplete={handleOtpMob}
              _id="mobOtp"
            />
          </div> */}
      </div>
      <OtpInput
        length={6}
        inputStyle="w-10 md:w-12 h-12 text-xl"
        containerStyle=" mt-2"
        onComplete={handleOtpEmail}
        _id="emailOtp"
      />
    </div>
  );
};
