import React, { useState } from "react";
import { downloadFile } from "helper/helperFunctions";

export const DownloadButton = ({
  data,
  getAllData,
  excludeFields,
  fileName,
}: any) => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleChange = (event: any) => {
    const value = event.target.value;
    if (value === "all") {
      getAllData(true);
    } else if (value === "current") {
      downloadFile(data, fileName, excludeFields);
    }
    setSelectedOption("Download");
  };

  return (
    <select
      value={selectedOption}
      onChange={(event) => {
        setSelectedOption(event.target.value);
        handleChange(event);
      }}
      className="md:text-md  cursor-pointer rounded bg-primaryColor-dark py-2 text-sm text-white outline-none"
    >
      <option value="Download" className=" hidden">
        Download
      </option>
      <option value="all" className="bg-gray-200 text-[#1E2D45]">
        Download All
      </option>
      <option value="current" className="bg-gray-200 text-[#1E2D45]">
        Current Page
      </option>
    </select>
  );
};
