import { useDispatch, useSelector } from "react-redux";
import { setRequestCountForPages } from "redux/reducers/appReducer";
import { setToken, setUser } from "redux/reducers/authReducer";
import { DeleteApi } from "services/appServices";
import { errorToast, infoToast } from "views/toastConfig"

export const DeleteModal = ({
  setShowDeleteModal,
}: {
  setShowDeleteModal: (show: boolean) => void;
}) => {
  const { user } = useSelector((store: any) => store.auth);
  const { userRole } = useSelector((store: any) => store.appSlice);
  
  const dispatch = useDispatch();

  const handleDelete = async () => {

    try {
        const deletUserRes = await DeleteApi(user._id,userRole);
        const { payload, message, error, statusCode } = deletUserRes?.data || {};
  
      if (statusCode == 200) {
        dispatch(setToken(null));
        dispatch(setUser({}));
        dispatch(setRequestCountForPages({}));
        infoToast(message)
      }
      else{
          console.log({error})
          errorToast(message)
      }
    } catch (error:any) {
        console.log("Error handleDelete",{error})
        errorToast(error.message)
    }

  };
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-primaryColor-dark bg-opacity-50">
      <div className="w-[90%] max-w-md rounded-lg bg-white p-6 shadow-2xl">
        <h2 className="text-lg font-semibold text-gray-800">Delete Account</h2>
        <p className="mt-4 text-gray-600">
          Are you sure you want to delete your account? This action cannot be
          undone.
        </p>
        <div className="mt-6 flex justify-end space-x-4">
          <button
            onClick={() => setShowDeleteModal(false)}
            className="rounded-md bg-gray-200 px-4 py-2 text-gray-800"
          >
            Cancel
          </button>
          <button
            onClick={handleDelete}
            className="rounded-md bg-red-600 px-4 py-2 text-white"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};
