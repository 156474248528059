import { downloadFromS3, handleDownloadFile } from "helper/helperFunctions";
import React from "react";
import PdfLogo from "../../assets/img/pdfLogo.png";

export const DocWithDownloadButton = ({
  label,
  path,
  name,
  type,
  generateFunction,
  generate = false,
}: any) => {
  return (
    <div className="overflow- flex max-w-full flex-row">
      <div className="flex max-w-full flex-1 flex-col">
        <label className="sm-max:text-md block text-wrap">{label}</label>
        <div className="rounded-md border-2 px-2 py-2">
          <div className="flex items-center">
            <div className="flex flex-col items-center">
              <img src={PdfLogo} className="" alt="" />
              <button
                type="button"
                className="text-xs text-blue-500"
                onClick={(e: any) => {
                  if (!generate) {
                    // handleDownloadFile(path, type, false, name);
                    downloadFromS3(path,name,type);
                  } else {
                    generateFunction();
                  }
                  e.stopPropagation();
                }}
              >
                Download
              </button>
            </div>
            <label className="block overflow-auto text-wrap pl-1 sm-max:text-sm">
              {name}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
