import React, { useEffect } from "react";
import PieChart from "components/charts/PieChart";
import Card from "components/card";
import { pieChartOptions } from "helper/helperFunctions";

const PieChartCard = ({ label, pieChartData }: any) => {
  const total = pieChartData.reduce(
    (acc: number, value: number) => acc + value,
    0
  );

  // Calculate percentages
  const vacantPercentage =
    total !== 0 && !isNaN(total) && !isNaN(pieChartData[1])
      ? ((pieChartData[1] / total) * 100).toFixed(2)
      : 0;
  const allotedPercentage =
    total !== 0 && !isNaN(total) && !isNaN(pieChartData[0])
      ? ((pieChartData[0] / total) * 100).toFixed(2)
      : 0;
  const appliedPercentage =
    total !== 0 && !isNaN(total) && !isNaN(pieChartData[2])
      ? ((pieChartData[2] / total) * 100).toFixed(2)
      : 0;
  // useEffect(() => {
  //   console.log({ pieChartData });
  // }, []);

  return (
    <Card extra="rounded-[20px] p-3">
      <div className="flex items-center justify-between gap-3 ">
        <h4 className="text-lg font-bold text-navy-700 dark:text-white">
          {label}
        </h4>
        <div>
          <label className="font-bold">Total: </label>
          <label className="font-bold text-[#4a7468]">
            {pieChartData.reduce(
              (accumulator: number, currentValue: number) =>
                accumulator + currentValue
            )}
          </label>
        </div>
      </div>

      <div className="mb-auto flex h-[220px] w-full items-center justify-center">
        <PieChart chartOptions={pieChartOptions} chartData={pieChartData} />
      </div>

      <div className="flex flex-row !justify-between rounded-2xl px-6 py-3 shadow-2xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <div className="h-2 w-2 rounded-full bg-[#33E064]" />
            <p className="ml-1 text-sm font-bold  text-green-600">Vacant</p>
          </div>
          <p className="mt-px text-xl font-bold text-navy-700  dark:text-white">
            {pieChartData[1] || 0}
            <span className="text-sm text-gray-400">
              {" "}
              ({vacantPercentage || 0}%)
            </span>
          </p>
        </div>

        <div className="h-11 w-px bg-gray-300 dark:bg-white/10" />

        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <div className="h-2 w-2 rounded-full bg-[#2CA8E2]" />
            <p className="ml-1 text-sm font-bold text-[#1498D5]">Alloted</p>
          </div>
          <p className="mt-px text-xl font-bold text-navy-700 dark:text-white">
            {pieChartData[0] || 0}
            <span className="text-sm text-gray-400">
              {" "}
              ( {allotedPercentage || 0}%)
            </span>
          </p>
        </div>

        <div className="h-11 w-px bg-gray-300 dark:bg-white/10" />

        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <div className="h-2 w-2 rounded-full bg-[#9C12CA]" />
            <p className="ml-1 text-sm font-normal text-[#9C12CA]">Applied</p>
          </div>
          <p className="mt-px text-xl font-bold text-navy-700 dark:text-white">
            {pieChartData[2] || 0}
            <span className="text-sm text-gray-400">
              {" "}
              ( {appliedPercentage || 0}%)
            </span>
          </p>
        </div>
      </div>
    </Card>
  );
};

export default PieChartCard;
